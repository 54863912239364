import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import moment from "moment";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import useStateWithCallback, { useStateWithCallbackLazy } from "use-state-with-callback";

const schema = yup.object().shape({
  // reason: yup.string().required("Reason Required"),
  // Appointmentfor: yup.string().required("Appointment For required"),
  AppointmentDate: yup.string().required("Appointment Date Required."),
  AppointmentTime: yup.object().required("Appointment Time Required."),

  //   IsLocationSelector: yup.boolean(),
  // additionalInformation: yup.string().required("Additional Information Required"),
  // phoneNumber: yup
  //   .string()
  //   .required("Phone required")
  //   .matches("^[0-9]+$", "Phone should be number")
  //   .min(10, "Invalid Phone Number")
  //   .max(10, "Invalid Phone Number"),
  //   //   }),
  IsLocationSelector: yup.string(),

  OfficeSite: yup.object().when("IsLocationSelector", {
    is: "value care office site",
    then: yup.object().required("Office Site Required."),
  }),

  PostalCode: yup.string().when("IsLocationSelector", {
    is: "Australin Address",
    then: yup
      .string()
      .required("Postal Code Required.")
      .matches("^[0-9]+$", "Postal Code should be number.")
      .min(4, "Postal Code must be at least 4 digit.")
      .max(4, "Invalid Postal Code."),
  }),

  Suburb: yup.string().when("IsLocationSelector", {
    is: "Australin Address",
    then: yup.string().required("Suburb Required."),
  }),

  AddrLine1: yup.string().when("IsLocationSelector", {
    is: "Australin Address",
    then: yup.string().required("Street Address Required."),
  }),
  state: yup.object().when("IsLocationSelector", {
    is: "Australin Address",
    then: yup.object().required("State Required."),
  }),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FaceToFace = () => {
  const { state } = useLocation();
  let navigate = useNavigate();
  let clientRef = useRef(false);

  let PatientData = state ? state.PatientData : null;
  console.log("PatientData", PatientData);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [termsCondition, setTermsCondition] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [isEditEnabled, setIsEditEnabled] = useState(state ? true : false);
  const [managedData, setManagedData] = useState(null);
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const [chManagedBy, setChManagedBy] = useState(null);
  const [chStatus, setChStatus] = useState(null);
  const [appoinmentStatusList, setAppoinmentStatusList] = useState(null);
  const [defaultStatus, setDefaultStatus] = useState("Pending");
  const [appointmentFor, setAppointmentFor] = useState(null);
  const [personData, setPersonData] = useState(null);
  const [appoinmentLabel, setAppoinmentLabel] = useState(null);
  const [isSelfAppoinmentChecked, setIsSelfAppoinmentChecked] = useState("false");
  const [appoinmentfullName, setAppoinmentfullName] = useState(null);
  const [TimeSlot, setTimeSlot] = useStateWithCallbackLazy(null);
  const [selectedTimeLabel, setSelectedTimeLabel] = useState(null);
  const [isTimeValid, setisTimeValid] = useState(false);
  const [selectedCallBackTimeSlot, setSelectedCallBackTimeSlot] = useState(null);
  const [TimeList, setTimeList] = useStateWithCallbackLazy(null);
  const [selectedTime, setSelectedTime] = useStateWithCallbackLazy(null);
  const [serviceList, setServiceList] = useStateWithCallbackLazy(null);
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);
  const [userSelectedServices, setUserSelectedServices] = useState(null);
  const [officeSiteData, setOfficeSiteData] = useState(null);
  const [selectedOfficeSite, setSelectedOfficeSite] = useState(null);
  const [AddrLine1, setAddrLine1] = useState(null);
  const [AddrLine2, setAddrLine2] = useState(null);
  const [chNotes, setChNotes] = useState(null);
  const [Suburb, setSuburb] = useState(null);
  const [PostalCode, setPostalCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState(null);
  const [stateData, setStateData] = useStateWithCallbackLazy(null);
  const [selectedState, setSelectedState] = useState(null);
  const [managedBy, setManagedBy] = useState(null);
  const [AppointmentType, setAppointmentType] = useState(null);
  const [selectedMediumType, setSelectedMediumType] = useState(null);
  const [loader, setLoader] = React.useState(false);

  let PersonUserName = state ? state.Name : null;
  let PersonUserId = state ? state.PersonId : null;
  let AppointmentTimeSet = "";
  let StateChange = "";

  const onManagedByChangeStatusChanged = (e, value) => {
    console.log("VVV", value);
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
  };

  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          // cogoToast.success("Appointment Booked Successfully");
          console.log("Managed Data", res.data.data);

          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
          // navigate("/pages/book-appointment/list");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const onStatusChanged = (e, value) => {
    setChStatus(value.id);
  };

  const onManagedByChanged = (e, value) => {
    setManagedBy(value);
  };

  const getState = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let statelistdata = res.data.data;
          setStateData(
            statelistdata.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOfficeSiteData = () => {
    const formattedData = {
      StringMapType: "VALUE CARE SITE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let officeSiteDataa = res.data.data;
          setOfficeSiteData(
            officeSiteDataa.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetAddress = (e, value) => {
    var setoffice = e.target.value;

    var dataSET = {
      officeSite: setoffice,
    };
    axios({
      url: CommonConfig.ApiUrl + "Utils/getOfficeAddress",
      method: "POST",
      headers: {},
      data: dataSET,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          console.log("time data", res.data.data);

          let officeArredss = res.data.data[0].Address;

          document.getElementById("AddressDiv").style.display = "block";
          document.getElementById("setSiteData").innerHTML = res.data.data[0].SiteName;

          // document.getElementById("setAddressData").style.display = "block";
          document.getElementById("setAddressData").innerHTML = res.data.data[0].Address;

          // document.getElementById("setStateData").style.display = "block";
          document.getElementById("setStateData").innerHTML = res.data.data[0].City;

          // document.getElementById("setCityData").style.display = "block";
          document.getElementById("setCityData").innerHTML = res.data.data[0].State;

          // setAppoinmentStatusList(
          //   appoinmentStatus.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onOfficeChange = (e, value) => {
    setSelectedOfficeSite(value);
  };

  const getAppoinmentStatus = () => {
    const formattedData = { StringMapType: "APPOINTMENTSTATUS", SortType: "Alpha" };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let appoinmentStatus = res.data.data;
          setAppoinmentStatusList(
            appoinmentStatus.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserName = () => {
    console.log("appoinmentLabel", appoinmentLabel);
    // debugger;
    // setDefaultStatus("Pending")
    let appointment = "";
    if (PersonUserName) {
      setAppointmentFor(PersonUserName);
    } else if (isSelfAppoinmentChecked === "true") {
      let userData = localStorage.getItem("userData");
      if (userData) {
        userData = JSON.parse(userData);
      }
      appointment = userData.FirstName + " " + userData.LastName;
      setAppointmentFor(appointment);
    } else if (isSelfAppoinmentChecked != "true") {
      setAppointmentFor(localStorage.getItem("PersonUserName"));
    } else {
      setAppointmentFor("");
    }
  };
  const getPersonById = () => {
    let Requestdata = {
      PersonId: PersonUserId ? PersonUserId : CommonConfig.getCurrentUserId(),
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    console.log("getPersonById 2 ", Requestdata);
    axios({
      url: CommonConfig.ApiUrl + "contact/getPersonById",
      method: "POST",
      headers: {},
      data: Requestdata,
    })
      .then((res) => {
        if (res.data.success) {
          if (localStorage.getItem("PersonUserName")) {
            setPersonData(
              res.data.data.map((item) => ({
                id: item.PersonId,
                personFullName: localStorage.getItem("PersonUserName"),
              }))
            );
          } else {
            console.log("res.data.data[0]", res.data.data);

            if (
              res.data.data[0].DOB == "invalid Date" ||
              res.data.data[0].DOB == "" ||
              res.data.data[0].DOB == "0000-00-00"
            ) {
              setPersonData(
                res.data.data.map((item) => ({
                  id: item.PersonId,
                  personFullName: item.FirstName + " " + item.LastName + ", " + item.Gender + " ",
                }))
              );
            } else {
              setPersonData(
                res.data.data.map((item) => ({
                  id: item.PersonId,
                  personFullName: item.FirstName + " " + item.LastName,
                }))
              );
            }
          }
        }
      })
      .catch((error) => {});
  };

  const handleStateChange = (value) => {
    if (value) {
      StateChange = value;
      localStorage.setItem("StateChange", StateChange);

      // setSelectedTime(value);

      // setisTimeValid(true);
      console.log("Time", StateChange);
    } else setisTimeValid(false);
  };

  const handleTimeChange = (value) => {
    if (value) {
      AppointmentTimeSet = value;
      localStorage.setItem("AppointmentTimeSet", AppointmentTimeSet);
      // setValues({ ...setSelectedTime, [event.target.name]: event.target.value });

      setSelectedTime(value);

      setisTimeValid(true);
      console.log("Time", AppointmentTimeSet);
    } else setisTimeValid(false);
  };

  const handleCallBackTimeChange = (value) => {
    if (value) {
      setSelectedCallBackTimeSlot(value);
      setisTimeValid(true);
    } else setisTimeValid(false);

    if (isEditEnabled) {
      setSelectedTimeLabel(PatientData?.TimeSlot);
    }
  };

  const display = (e) => {
    console.log("datefortime", CommonConfig.formatDate(e));
    var Selecteddate = CommonConfig.formatDate(e);
    var currentDate = new Date().toJSON().slice(0, 10);
    console.log("currentdate", currentDate);
    if (Selecteddate === currentDate) {
      getTimeSlot(0);
    } else {
      getTimeSlot(1);
    }

    var dates = document.getElementById("DateValue");
    var dates2 = document.getElementById("DateValue").value;
    console.log("Dates = ", dates, " || ", dates2);

    console.log("Appi=", dates2);

    // alert("Hello = " , dates)
  };

  const getTimeSlot = (n) => {
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getTimeSlotValue",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data?.success) {
          var list = [];
          var list2 = [];
          console.log("TimeSlotListRES===>");
          console.log("11111", res.data.data);
          var dt = Date();

          var newdate = dt.slice(15, -37);
          console.log("data.AppointmentDate", n);
          debugger;
          if (n === 1) {
            res.data.data.map((item1) => {
              console.log("iiii", item1.TimeValue.slice(0, -6));
              var date = item1.TimeValue.slice(0, -6);
              console.log("date", date);
              console.log("newdate", newdate);
              if (parseInt(date) <= parseInt(newdate)) {
                list.push(item1);
                console.log("//////1111");
                setTimeList(
                  list.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else if (parseInt(date) >= parseInt(newdate)) {
                list2.push(item1);
                console.log("//////2222");
                setTimeList(
                  list2.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else {
                setTimeList();
                // list2.push();
                // setTimeList(
                //   list2.map((item) => ({
                //     id: item.TimeValue,
                //     label: item.TimeLabel,
                //   }))
                // );
              }
            });
            console.log("list", list);
            console.log("list2", list2);
          } else {
            setTimeList(
              res.data.data.map((item) => ({
                id: item.TimeValue,
                label: item.TimeLabel,
              }))
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCallBackTimeSlot = () => {
    const formattedData = {
      StringMapType: "DAYTIMING",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("AppoinmentTimeSlot===>", res.data.data);
          setTimeSlot(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          // console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setAutocompleteValues = (value) => {
    let dummyArray = [];
    if (value) {
      setUserSelectedServices(value);
      for (let i = 0; i < value.length; i++) {
        if (value[i]) {
          dummyArray.push({
            id: value[i],
            label: value[i],
          });
        }
      }
      // console.log("DUMMy", dummyArray);
      // setValues(dummyArray);
      setServiceAutocompleteValues(dummyArray);
    }
  };

  const onServiceChange = (event, value) => {
    console.log("ValueService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }

    setUserSelectedServices(formattedData);
    setServiceAutocompleteValues(value);
  };

  const onStateChange = (event, value) => {
    console.log("VVV", value.id);
    setValues(value.id);
    setStateLabel(value.label);

    console.log("-------bhanu--------", value);
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserName();
    getState();
    getOfficeSiteData();
    getTimeSlot();
    getInternalStaff();
    getStateData();
    getAppoinmentStatus();
    getPersonById();
    getServiceData();
    getCallBackTimeSlot();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      //   UserType: "EXTERNAL",
      //   UserName: userData?.UserName ? userData?.UserName : UserName,
      //   Password: userData?.Password ? userData?.Password : Password,
      //   Appointment: userData?.Appointment ? userData?.Appointment : "",
      reason: userData?.reason ? userData?.reason : "",

      IsLocationSelector: userData?.IsLocationSelector
        ? userData?.IsLocationSelector
        : "value care office site",
      AddrLine1: userData?.AddrLine1 ? userData?.AddrLine1 : "",
      AddrLine2: userData?.AddrLine2 ? userData?.AddrLine2 : "",
      Suburb: userData?.Suburb ? userData?.Suburb : "",

      IsSubscription: userData?.IsSubscription ? userData?.IsSubscription : 0,
    },
  });

  const IsLocationSelector = watch("IsLocationSelector");

  const onSubmit = (data) => {
    let formattedDate = moment(data.AppointmentDate).format("YYYY-MM-DD");
    let managedBYInternalStaff = "";
    if (localStorage.getItem("userData")) {
      var localUserData = JSON.parse(localStorage.getItem("userData"));
      var PersonType = localUserData.PersonType;
      if (PersonType == "INTERNAL STAFF") {
        managedBYInternalStaff = localUserData.PersonId;
      }
    }

    console.log("DATA:100:", data);
    let isValueCareSite = "0";
    if (IsLocationSelector == "value care office site") {
      // debugger;
      isValueCareSite = "1";
      console.log("VAlue", isValueCareSite);
    }
    let mediumType = "";

    let managedByID = managedBy ? managedBy.id : "";
    if (isEditEnabled) {
      managedByID = managedBy?.id ? managedBy?.id : managedBy;
    }

    // let officeSite = selectedOfficeSite ? selectedOfficeSite.id : "";
    // if (isEditEnabled && isValueCareSite == "1") {
    //   officeSite = selectedOfficeSite.id ? selectedOfficeSite.id : selectedOfficeSite;
    // }
    console.log("AppointmentTimeSet = ", AppointmentTimeSet);
    setLoader(!loader);
    let formattedData = {
      ...data,
      AppointmentID: PatientData?.AppointmentID ? PatientData?.AppointmentID : "",
      PersonID: localStorage.getItem("PersonUserIdApp")
        ? localStorage.getItem("PersonUserIdApp")
        : CommonConfig.getCurrentUserId(),
      AppointmentMode: localStorage.getItem("AppointmentType"),
      AppointmentDate: formattedDate,
      MediumType: "",
      TimeSlot: "",
      AppointmentTime: data.AppointmentTime.id,
      IsValueCareSite: isValueCareSite,
      ValueCareSite: data.OfficeSite ? data.OfficeSite.id : "",
      AddressType: "",
      AppointmentWith: data.AppointmentWith,

      PhoneForConfirmation: data.phoneNumber,
      AdditionalDetail: data.additionalInformation,
      AddressType: "",
      AddrLine1: data.AddrLine1,
      AddrLine2: data.AddrLine2,
      Suburb: data.Suburb,
      State: data.state ? data.state.id : "",
      PostalCode: data.PostalCode,
      ManagedBy: managedBYInternalStaff,
      Reason: "",
      Status: "Pending",
      AssignToStaffID: "",
      Note: chNotes,
      // AssignToStaffID: chManagedBy,
      // Note: chNotes,
    };

    localStorage.removeItem("AppointmentTimeSet");
    localStorage.removeItem("StateChange");
    localStorage.removeItem("PersonUserIdApp");
    localStorage.removeItem("PersonUserName");

    console.log("Final formated data", formattedData);

    let formattedDataT = {
      formattedData: formattedData,
      Services: localStorage.getItem("setSelectedServices"),
      CurrentUserId: CommonConfig.getCurrentUserId(),
    };
    console.log(" addUpdateAppointment API req data", formattedDataT);
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/addUpdateAppointment",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },

      // Attaching the form data
      data: formattedDataT,
    })
      // Handle the response from backend here
      .then((res) => {
        setLoader(!loader);
        if (res.data.success) {
          cogoToast.success("Appointment Booked Successfully.");
          console.log("RESSS", res);
          navigate("/pages/book-appointment/list");
        }
        console.log("......ggggggggg", res.data.success);
        console.log("test", res.data);
        // cogoToast.error('Something went wrong');
      })

      // //   // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });

    console.log("formattedDataT", formattedDataT);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        <MDBox>
          <Card className="mt-15 dashboard-appointment">
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reg_step" id="regStep6">
                  <Grid item md="12">
                    <h2 className="h2-heading">Face to Face</h2>
                  </Grid>
                  <div className="reg_form">
                    <Grid container spacing={3}>
                      <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="Status"
                            control={control}
                            defaultValue={
                              isEditEnabled
                                ? PatientData?.FirstName + " " + PatientData?.LastName
                                : appointmentFor
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-read-only-input"
                                label="Status"
                                value={
                                  isEditEnabled
                                    ? !chStatus
                                      ? PatientData?.Status
                                      : chStatus
                                    : defaultStatus
                                }
                                onChange={onChange}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Appointmentfor"
                            control={control}
                            defaultValue={
                              isEditEnabled
                                ? PatientData?.FirstName + " " + PatientData?.LastName
                                : appointmentFor
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-read-only-input"
                                label="Appointment For"
                                // value={isSelfAppoinmentChecked == "true" ?(personData ? personData[0].personFullName : ''):(!appoinmentLabel ? (personData ? personData[0].personFullName : '') :
                                // (relatedPersonList ? relatedPersonList[0].personFullName : ''))}
                                value={
                                  PersonUserName
                                    ? PersonUserName
                                    : appoinmentfullName
                                    ? appoinmentfullName
                                    : personData
                                    ? personData[0].personFullName
                                    : ""
                                }
                                // value={isEditEnabled ? PatientData?.FirstName + " " + PatientData?.LastName : appointmentFor}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AppointmentDate"
                            defaultValue=""
                            id="AppointmentDate"
                            // autocomplete="new-password"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Date*"
                                  // autocomplete="new-password"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    onChange(date);
                                    display(date);
                                  }}
                                  // onClose = {display}
                                  onClose={(e, v) => display(value)}
                                  minDate={new Date()}
                                  // maxDate={new Date("09-05-2022")}
                                  renderInput={(params) => (
                                    <TextField
                                      id="DateValue"
                                      autoComplete="off"
                                      // helperText={error?.message}
                                      // error={error}
                                      {...params}
                                      error={errors.AppointmentDate}
                                      helperText={
                                        errors.AppointmentDate ? "Appointment Date Required." : ""
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <div className="reg_input auto-complete">
                            <Controller
                              control={control}
                              name="AppointmentTime"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="AppointmentTime"
                                  name="AppointmentTime"
                                  value={value}
                                  options={TimeList ? TimeList : []}
                                  onChange={(e, v) => onChange(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Appointment Time*"
                                      error={errors.AppointmentTime}
                                      helperText={
                                        errors.AppointmentTime ? "Appointment Time Required." : ""
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      rowSpacing={0}
                      columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                    >
                      <Grid item md="12">
                        <div className="reg_input check_value">
                          <Controller
                            name="IsLocationSelector"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <>
                                <InputLabel className="reg_lable mt-15">Location</InputLabel>
                                <RadioGroup
                                  // aria-label="gender"
                                  name="IsLocationSelector"
                                  row="true"
                                  // defaultValue="value care office site"
                                >
                                  <FormControlLabel
                                    value="value care office site"
                                    label="Value Care Office Site"
                                    onChange={onChange}
                                    control={
                                      <Radio
                                        checked={IsLocationSelector === "value care office site"}
                                      />
                                    }
                                    // label="Value care's office/site"
                                    name="location"
                                  />
                                  <FormControlLabel
                                    value="Australin Address"
                                    onChange={onChange}
                                    control={
                                      <Radio checked={IsLocationSelector === "Australin Address"} />
                                    }
                                    label="Provide an Australian address"
                                    name="location"
                                    // rules={{ required: "Please Select Location" }}
                                  />
                                </RadioGroup>
                              </>
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    {IsLocationSelector == "value care office site" && (
                      <>
                        <Grid
                          container
                          justify="center"
                          rowSpacing={0}
                          columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                        >
                          <Grid item md="4">
                            <div className="reg_input auto-complete">
                              <Controller
                                control={control}
                                name="OfficeSite"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    disablePortal
                                    id="OfficeSite"
                                    name="OfficeSite"
                                    value={value}
                                    options={officeSiteData ? officeSiteData : []}
                                    onChange={(e, v) => onChange(v)}
                                    // onBlur = {(e, v) => GetAddress(v)}
                                    onBlur={GetAddress}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Office Site*"
                                        error={errors.OfficeSite}
                                        helperText={
                                          errors.OfficeSite ? "Office Site Required." : ""
                                        }
                                      />
                                    )}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="6" className="hideDiv" id="AddressDiv">
                            <Alert severity="info" className="address-alert">
                              <AlertTitle className="alt-title" id="setSiteData">
                                <strong></strong>
                              </AlertTitle>
                              <p>
                                <span id="setAddressData"></span>, <span id="setStateData"></span>,{" "}
                                <span id="setCityData"></span>
                              </p>
                            </Alert>
                            {/* <div className="reg_input">
                              <span class="Addressset" id="setSiteData"></span>
                              <span class="Addressset" id="setAddressData"></span>
                              <span class="Addressset" id="setStateData"></span>
                              <span class="Addressset" id="setCityData"></span>
                            </div> */}
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justify="center"
                          rowSpacing={0}
                          columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                        >
                          <Grid item md="4" sx={{ mt: 2 }}>
                            <div className="reg_input">
                              <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  // <TextField
                                  //   type = "number"
                                  //   label="Phone Number"
                                  //   variant="outlined"
                                  //   value={value}
                                  //   onChange={onChange}
                                  //   error={error}
                                  //   helperText={error?.message}
                                  // />

                                  <TextField
                                    type="number"
                                    // validationState={isValid ? 'valid' : 'invalid'}
                                    value={value}
                                    onChange={onChange}
                                    label="Phone Number"
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4" sx={{ mt: 2 }}>
                            <div className="reg_input">
                              <Controller
                                name="AppointmentWith"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    // type = "number"
                                    label="Appointment With"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="6" sx={{ mt: 2 }}>
                            <div className="reg_input">
                              <Controller
                                name="additionalInformation"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Additional Information"
                                    color="primary"
                                    variant="outlined"
                                    rows={3}
                                    multiline
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {IsLocationSelector == "Australin Address" && (
                      <div className="str-slam">
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <InputLabel className="reg_lable mb-20 mt-15">Address</InputLabel>
                          </Grid>
                        </Grid>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="AddrLine1"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Unit/Street Address*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="AddrLine2"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Street Name"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="Suburb"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Suburb*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="4">
                            <div className="reg_input auto-complete">
                              <Controller
                                control={control}
                                name="state"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <Autocomplete
                                    disablePortal
                                    id="state"
                                    name="state"
                                    value={value}
                                    options={stateData ? stateData : []}
                                    onChange={(e, v) => onChange(v)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="State*"
                                        error={errors.state}
                                        helperText={errors.state ? "State Required." : ""}
                                      />
                                    )}
                                  />
                                )}
                              />

                              {/* <Autocomplete
                                  options={stateData ? stateData : []}
                                  // value={selectedState ? selectedState : ""}
                                  defaultValue={selectedState ? selectedState : ""}
                                  onChange={(e, value) => {
                                    handleStateChange(value.label);
                                    //   setValues((prev) => ({ ...prev, state: value.id }));
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="State*"
                                      name="state"
                                      error={values.errorstate}
                                      helperText={values.errorstate}
                                    />
                                  )}
                                /> */}
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="PostalCode"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Postal Code*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    type="number"
                                    label="Phone Number"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4" sx={{ mt: 2 }}>
                            <div className="reg_input">
                              <Controller
                                name="AppointmentWith"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    // type = "number"
                                    label="Appointment With"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="6">
                            <div className="reg_input">
                              <Controller
                                name="additionalInformation"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Additional Information"
                                    color="primary"
                                    variant="outlined"
                                    rows={3}
                                    multiline
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {/* </Grid> */}
                  </div>
                </div>
                {userData ? (
                  <div className="reg_submit">
                    <MDButton type="submit" variant="contained" color="primary">
                      Update
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/dashboards/userprofile/Index")}
                    >
                      Cancel
                    </MDButton>
                  </div>
                ) : (
                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/pages/book-appointment/UserBookAppointmentToken")}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      Submit
                    </MDButton>
                  </div>
                )}
              </form>
            </CardContent>
          </Card>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

FaceToFace.defaultProps = {};

export default FaceToFace;
