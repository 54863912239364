import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import moment from "moment";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

const schema = yup.object().shape({
  //   reason: yup.string().required("Reason Required"),

  AppointmentDate: yup.string().required("Appointment Date Required."),
  AppointmentTime: yup.object().required("Appointment Time Required."),

  //   additionalInformation: yup.string().required("Additional Information Required"),
  //   phoneNumber: yup
  //     .string()
  //     .required("Phone required")
  //     .matches("^[0-9]+$", "Phone should be number")
  //     .min(10, "Invalid Phone Number")
  //     .max(10, "Invalid Phone Number"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AudioVideoConference = () => {
  const { state } = useLocation();
  let navigate = useNavigate();
  let clientRef = useRef(false);

  let PatientData = state ? state.PatientData : null;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [termsCondition, setTermsCondition] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [isEditEnabled, setIsEditEnabled] = useState(state ? true : false);
  const [managedData, setManagedData] = useState(null);
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const [chManagedBy, setChManagedBy] = useState(null);
  const [chStatus, setChStatus] = useState(null);
  const [appoinmentStatusList, setAppoinmentStatusList] = useState(null);
  const [defaultStatus, setDefaultStatus] = useState("Pending");
  const [appointmentFor, setAppointmentFor] = useState(null);
  const [personData, setPersonData] = useState(null);
  const [appoinmentLabel, setAppoinmentLabel] = useState(null);
  const [isSelfAppoinmentChecked, setIsSelfAppoinmentChecked] = useState("false");
  const [appoinmentfullName, setAppoinmentfullName] = useState(null);
  const [TimeSlot, setTimeSlot] = useState(null);
  const [selectedTimeLabel, setSelectedTimeLabel] = useState(null);
  const [isTimeValid, setisTimeValid] = useState(false);
  const [selectedCallBackTimeSlot, setSelectedCallBackTimeSlot] = useState(null);
  const [TimeList, setTimeList] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);
  const [userSelectedServices, setUserSelectedServices] = useState(null);
  const [officeSiteData, setOfficeSiteData] = useState(null);
  const [selectedOfficeSite, setSelectedOfficeSite] = useState(null);
  const [AddrLine1, setAddrLine1] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [managedBy, setManagedBy] = useState(null);
  const [AppointmentType, setAppointmentType] = useState(null);
  const [selectedMediumType, setSelectedMediumType] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [cureentTimeList, setCureentTimeList] = useState(null);
  const [appoinmenTimeList, setAppoinmenTimeList] = useState(null);
  const [zoommeetingURL, setZoommeetingURL] = useState(null);
  const [zoommeetingID, setZoommeetingID] = useState(null);
  const [zoommeetingPassword, setZoommeetingPassword] = useState(null);

  const [zoommeetingData, setZoommeetingData] = useState(null);

  // const [selectedDateForZoom, setSelectedDateForZoom] = useStateWithCallbackLazy(null);

  let PersonUserName = state ? state.Name : null;
  let PersonUserId = state ? state.PersonId : null;
  let AppointmentTimeSet = "";

  // console.log("zoomiduper",selectedDateForZoom);

  // GetNewTime------------------------------
  console.log("appoinmenTimeLista", appoinmenTimeList);

  const getCurrentTime = [new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })];

  console.log("checkCureentTime", moment(getCurrentTime).format("hh:mm A"));

  let getCurentTimeValue = [];
  for (var i = 0; i < getCurrentTime.length; i++) {
    let getCurentTimeObject = {};
    getCurentTimeObject.id = moment(getCurrentTime[i]).format("hh:mm:ss A");
    getCurentTimeObject.label = moment(getCurrentTime[i]).format("hh:mm A");
    getCurentTimeValue.push(getCurentTimeObject);
  }

  console.log("FindTimedata a", getCurentTimeValue?.[0]?.label);

  useEffect(() => {
    if (TimeList) {
      var newFirstArray = TimeList.filter(function (el) {
        return el.id >= getCurentTimeValue?.[0]?.id;
      });
      // console.log("FindTimedata t", TimeList);
      // console.log("FindTimedata b", newFirstArray);
      setAppoinmenTimeList(newFirstArray);
    }
  }, [TimeList]);

  // GetNewTime-----------------------------------------

  const onManagedByChangeStatusChanged = (e, value) => {
    console.log("VVV", value);
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
  };

  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          // cogoToast.success("Appointment Booked Successfully");
          console.log("Managed Data", res.data.data);

          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
          // navigate("/pages/book-appointment/list");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const onStatusChanged = (e, value) => {
    setChStatus(value.id);
  };

  const onManagedByChanged = (e, value) => {
    setManagedBy(value);
  };

  const getState = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let statelistdata = res.data.data;
          setStateData(
            statelistdata.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOfficeSiteData = () => {
    const formattedData = {
      StringMapType: "VALUE CARE SITE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let officeSiteDataa = res.data.data;
          setOfficeSiteData(
            officeSiteDataa.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onOfficeChange = (e, value) => {
    setSelectedOfficeSite(value);
  };

  const getAppoinmentStatus = () => {
    const formattedData = { StringMapType: "APPOINTMENTSTATUS", SortType: "Alpha" };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let appoinmentStatus = res.data.data;
          setAppoinmentStatusList(
            appoinmentStatus.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserName = () => {
    console.log("appoinmentLabel", appoinmentLabel);
    // debugger;
    // setDefaultStatus("Pending")
    let appointment = "";
    if (PersonUserName) {
      setAppointmentFor(PersonUserName);
    } else if (isSelfAppoinmentChecked === "true") {
      let userData = localStorage.getItem("userData");
      if (userData) {
        userData = JSON.parse(userData);
      }
      appointment = userData.FirstName + " " + userData.LastName;
      setAppointmentFor(appointment);
    } else if (isSelfAppoinmentChecked != "true") {
      setAppointmentFor(localStorage.getItem("PersonUserName"));
    } else {
      setAppointmentFor("");
    }
  };
  const getPersonById = () => {
    let Requestdata = {
      PersonId: PersonUserId ? PersonUserId : CommonConfig.getCurrentUserId(),
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "contact/getPersonById",
      method: "POST",
      headers: {},
      data: Requestdata,
    })
      .then((res) => {
        if (res.data.success) {
          if (localStorage.getItem("PersonUserName")) {
            setPersonData(
              res.data.data.map((item) => ({
                id: item.PersonId,
                personFullName: localStorage.getItem("PersonUserName"),
              }))
            );
          } else {
            setPersonData(
              res.data.data.map((item) => ({
                id: item.PersonId,
                personFullName: item.FirstName + " " + item.LastName,
              }))
            );
          }
        }
      })
      .catch((error) => { });
  };

  const handleTimeChange = (value) => {
    if (value) {
      AppointmentTimeSet = value;
      localStorage.setItem("AppointmentTimeSet", AppointmentTimeSet);
      // setValues({ ...setSelectedTime, [event.target.name]: event.target.value });

      setSelectedTime(value);

      setisTimeValid(true);
      console.log("Time", AppointmentTimeSet);
    } else setisTimeValid(false);
  };

  const handleCallBackTimeChange = (value) => {
    if (value) {
      setSelectedCallBackTimeSlot(value);
      setisTimeValid(true);
    } else setisTimeValid(false);

    if (isEditEnabled) {
      setSelectedTimeLabel(PatientData?.TimeSlot);
    }
  };

  const display = (e) => {
    console.log("datefortime", CommonConfig.formatDate(e));
    var Selecteddate = CommonConfig.formatDate(e);
    // setSelectedDateForZoom(CommonConfig.formatDate(e))
    var currentDate = new Date().toJSON().slice(0, 10);
    console.log("currentdate", currentDate);
    if (Selecteddate === currentDate) {
      getTimeSlot(0);
    } else {
      getTimeSlot(1);
    }

    var dates = document.getElementById("DateValue");
    var dates2 = document.getElementById("DateValue").value;
    console.log("Dates = ", dates, " || ", dates2);

    console.log("Appi=", dates2);

    // alert("Hello = " , dates)
  };

  const getTimeSlot = (n) => {
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getTimeSlotValue",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data?.success) {
          var list = [];
          var list2 = [];
          console.log("TimeSlotListRES===>");
          console.log("11111", res.data.data);
          var dt = Date();

          var newdate = dt.slice(15, -37);
          console.log("data.AppointmentDate", n);
          debugger;
          if (n === 1) {
            res.data.data.map((item1) => {
              console.log("iiii", item1.TimeValue.slice(0, -6));
              var date = item1.TimeValue.slice(0, -6);
              console.log("date", date);
              console.log("newdate", newdate);
              if (parseInt(date) <= parseInt(newdate)) {
                list.push(item1);
                console.log("//////1111");
                setTimeList(
                  list.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else if (parseInt(date) >= parseInt(newdate)) {
                list2.push(item1);
                console.log("//////2222");
                setTimeList(
                  list2.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else {
                setTimeList();
                // list2.push();
                // setTimeList(
                //   list2.map((item) => ({
                //     id: item.TimeValue,
                //     label: item.TimeLabel,
                //   }))
                // );
              }
            });
            console.log("list", list);
            console.log("list2", list2);
          } else {
            setTimeList(
              res.data.data.map((item) => ({
                id: item.TimeValue,
                label: item.TimeLabel,
              }))
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getTimeSlot = () => {
  //   axios({
  //     url: CommonConfig.ApiUrl + "Appointment/getTimeSlotValue",
  //     method: "GET",
  //     headers: {},
  //   })
  //     .then((res) => {
  //       if (res.data?.success) {
  //         // console.log("TimeSlotListRES===>", res);
  //         console.log("TimeSlotList===>", res.data.data);
  //         setTimeList(
  //           res.data.data.map((item) => ({
  //             id: item.TimeValue,
  //             label: item.TimeLabel,
  //           }))
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getCallBackTimeSlot = () => {
    const formattedData = {
      StringMapType: "DAYTIMING",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          // console.log("AppoinmentTimeSlot===>", res.data.data);
          setTimeSlot(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          // console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setAutocompleteValues = (value) => {
    let dummyArray = [];
    if (value) {
      setUserSelectedServices(value);
      for (let i = 0; i < value.length; i++) {
        if (value[i]) {
          dummyArray.push({
            id: value[i],
            label: value[i],
          });
        }
      }
      // console.log("DUMMy", dummyArray);
      // setValues(dummyArray);
      setServiceAutocompleteValues(dummyArray);
    }
  };

  const onServiceChange = (event, value) => {
    console.log("ValueService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }

    setUserSelectedServices(formattedData);
    setServiceAutocompleteValues(value);
  };

  const onStateChange = (event, value) => {
    console.log("VVV", value.id);
    setValues(value.id);
    setStateLabel(value.label);

    console.log("-------bhanu--------", value);
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getState();
    getOfficeSiteData();
    getTimeSlot();
    getInternalStaff();
    getStateData();
    getAppoinmentStatus();
    getPersonById();
    getServiceData();
    getCallBackTimeSlot();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      //   UserType: "EXTERNAL",
      //   UserName: userData?.UserName ? userData?.UserName : UserName,
      //   Password: userData?.Password ? userData?.Password : Password,
      //   Appointment: userData?.Appointment ? userData?.Appointment : "",
      //   reason: userData?.reason ? userData?.reason : "",
      //   IsLocationSelector: userData?.IsLocationSelector
      //     ? userData?.IsLocationSelector
      //     : "value care office site",
      //   AddrLine1: userData?.AddrLine1 ? userData?.AddrLine1 : "",
      //   AddrLine2: userData?.AddrLine2 ? userData?.AddrLine2 : "",
      //   Suburb: userData?.Suburb ? userData?.Suburb : "",
      //   IsSubscription: userData?.IsSubscription ? userData?.IsSubscription : 0,
    },
  });

  const IsLocationSelector = watch("IsLocationSelector");

  const onSubmit = (data) => {
    let formattedDate = moment(data.AppointmentDate).format("YYYY-MM-DD");
    let zoomData = {
      email: "contact@valuecare.org.au",
      AppointmentMeetingDate: formattedDate,
      AppointmentMeetingTime: data.AppointmentTime.id,
    };

    console.log("zoomData", zoomData);

    axios({
      url: CommonConfig.ZoomUrl + "create/zoomMeeting",
      method: "POST",
      headers: {
      },
      data: zoomData,
    })
      .then((res) => {
        console.log("aaaaaaaaaaaa", res);
        var zoommeetingURL = res.data.join_url.join_url
        var zoommeetinghostURL = res.data.join_url.start_url
        var zoommeetingID = res.data.join_url.id
        var zoommeetingPassword = res.data.join_url.password

        // let formattedDate = moment(data.AppointmentDate).format("YYYY-MM-DD");
        let managedBYInternalStaff = "";
        if (localStorage.getItem("userData")) {
          var localUserData = JSON.parse(localStorage.getItem("userData"));
          var PersonType = localUserData.PersonType;
          if (PersonType == "INTERNAL STAFF") {
            managedBYInternalStaff = localUserData.PersonId;
          }
        }
        debugger;
        console.log("DATA:100:", data);
        let isValueCareSite = "0";
        let mediumType = "";
        if (AppointmentType == "Audio/Video Conference") {
          mediumType = selectedMediumType;
        }

        let officeSite = selectedOfficeSite ? selectedOfficeSite.id : "";
        if (isEditEnabled && isValueCareSite == "1") {
          officeSite = selectedOfficeSite.id ? selectedOfficeSite.id : selectedOfficeSite;
        }

        let managedByID = managedBy ? managedBy.id : "";
        if (isEditEnabled) {
          managedByID = managedBy?.id ? managedBy?.id : managedBy;
        }

        setLoader(!loader);
        let formattedData = {
          ...data,
          AppointmentID: PatientData?.AppointmentID ? PatientData?.AppointmentID : "",
          PersonID: localStorage.getItem("PersonUserIdApp")
            ? localStorage.getItem("PersonUserIdApp")
            : CommonConfig.getCurrentUserId(),
          // AppointmentMode: localStorage.getItem("AppointmentType"),
          AppointmentTime: data.AppointmentTime.id,
          AppointmentMode: localStorage.getItem("AppointmentType"),
          AppointmentDate: formattedDate,
          MediumType: data.MediumType ? data.MediumType : "Video Call",
          TimeSlot: selectedCallBackTimeSlot,
          IsValueCareSite: isValueCareSite,
          ValueCareSite: officeSite,
          PhoneForConfirmation: data.phoneNumber,
          AdditionalDetail: data.additionalInformation,
          AddressType: "",
          //   State: selectedState,
          ManagedBy: managedBYInternalStaff,

          Status: chStatus ? chStatus : PatientData?.Status,
          // AssignToStaffID: chManagedBy,
          // Note: chNotes,
          ZoommeetingID: zoommeetingID,
          ZoommeetingURL: zoommeetingURL,
          ZoommeetinghostURL: zoommeetinghostURL,
          ZoommeetingPassword: zoommeetingPassword,
        };
        localStorage.removeItem("AppointmentTimeSet");
        localStorage.removeItem("PersonUserIdApp");
        localStorage.removeItem("PersonUserName");
        console.log("Final formated data", formattedData);

        let formattedDataT = {
          formattedData: formattedData,
          Services: localStorage.getItem("setSelectedServices"),
          CurrentUserId: CommonConfig.getCurrentUserId(),
        };
        console.log(" addUpdateAppointment API req data", formattedDataT);


        axios({
          // Endpoint to send files
          url: CommonConfig.ApiUrl + "Appointment/addUpdateAppointment",
          method: "POST",
          headers: {
            // Add any auth token here
            //authorization: "your token comes here",
          },

          // Attaching the form data
          data: formattedDataT,
        })
          // Handle the response from backend here
          .then((res) => {
            setLoader(!loader);
            if (res.data.success) {
              cogoToast.success("Appointment Booked Successfully.");
              console.log("RESSS", res);
              navigate("/pages/book-appointment/list");
            }
            console.log("......ggggggggg", res.data.success);
            console.log("test", res.data);
            // cogoToast.error('Something went wrong');
          })

          // //   // Catch errors if any
          .catch((error) => {
            console.log(error);
            // cogoToast.error('Something went wrong');
          });
      })
      .catch((err) => {
        console.log(err.message);
      });

  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        <MDBox>
          <Card className="mt-15 dashboard-appointment">
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reg_step" id="regStep6">
                  <Grid item md="12">
                    <h2 className="h2-heading">Audio/Video Conference</h2>
                  </Grid>
                  <div className="reg_form">
                    <Grid item md="12">
                      <InputLabel className="reg_lable mt-15">Mode of Communication</InputLabel>
                      <div className="reg_input check_value">
                        <Controller
                          name="MediumType"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RadioGroup
                              aria-label="MediumType"
                              defaultValue={"Video Call"}
                              onChange={onChange}
                              row="true"
                            >
                              <FormControlLabel
                                aria-errormessage="DShsfkjh"
                                value="Video Call"
                                onChange={onChange}
                                control={<Radio />}
                                label="Video Call"
                              />
                              <FormControlLabel
                                value="Audio Call"
                                onChange={onChange}
                                control={<Radio />}
                                label="Audio Call"
                              />
                            </RadioGroup>
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid container spacing={3}>
                      {/* <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="Appointment"
                            control={control}
                            defaultValue=""
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <TextField
                                label="Appointment #"
                                variant="filled"
                                disabled={true}
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid> */}
                      {/* <Grid item md="3">
                        <div className="reg_input">
                          <Autocomplete
                            disablePortal
                            id="chManagedBy"
                            name="chManagedBy"
                            style={{
                              marginBottom: "10px",
                            }}
                            value={
                              isEditEnabled
                                ? !chManagedByLabel
                                  ? PatientData?.ManagedByName
                                  : chManagedByLabel
                                : chManagedByLabel
                            }
                            options={managedData ? managedData : []}
                            onChange={onManagedByChangeStatusChanged}
                            sx={{ width: 200 }}
                            renderInput={(params) => (
                              <TextField
                                // {...register("ManagedBy")}
                                {...params}
                                label="Managed By"
                              />
                            )}
                          />
                        </div>
                      </Grid> */}

                      {/* <Grid item md="3">
                        <div className="reg_input">
                          <Autocomplete
                            disablePortal
                            id="Status"
                            name="Status"
                            style={{
                              marginBottom: "10px",
                            }}
                            value={
                              isEditEnabled
                                ? !chStatus
                                  ? PatientData?.Status
                                  : chStatus
                                : defaultStatus
                            }
                            options={appoinmentStatusList ? appoinmentStatusList : []}
                            onChange={onStatusChanged}
                            sx={{ width: 200 }}
                            renderInput={(params) => (
                              <TextField
                                // {...register("ManagedBy")}
                                {...params}
                                label="Status"
                              />
                            )}
                          />
                        </div>
                      </Grid> */}

                      <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="Status"
                            control={control}
                            defaultValue={
                              isEditEnabled
                                ? PatientData?.FirstName + " " + PatientData?.LastName
                                : appointmentFor
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-read-only-input"
                                label="Status"
                                value={
                                  isEditEnabled
                                    ? !chStatus
                                      ? PatientData?.Status
                                      : chStatus
                                    : defaultStatus
                                }
                                onChange={onChange}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      {/* <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="reason"
                            control={control}
                            defaultValue=""
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <TextField
                                label="Reason"
                                variant="filled"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid> */}
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Appointmentfor"
                            control={control}
                            defaultValue={
                              isEditEnabled
                                ? PatientData?.FirstName + " " + PatientData?.LastName
                                : appointmentFor
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-read-only-input"
                                label="Appointment For"
                                // value={isSelfAppoinmentChecked == "true" ?(personData ? personData[0].personFullName : ''):(!appoinmentLabel ? (personData ? personData[0].personFullName : '') :
                                // (relatedPersonList ? relatedPersonList[0].personFullName : ''))}
                                value={
                                  PersonUserName
                                    ? PersonUserName
                                    : appoinmentfullName
                                      ? appoinmentfullName
                                      : personData
                                        ? personData[0].personFullName
                                        : ""
                                }
                                // value={isEditEnabled ? PatientData?.FirstName + " " + PatientData?.LastName : appointmentFor}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        {/* <div className="reg_input">
                          <Controller
                            name="AppointmentDate"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Date*"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={onChange}
                                  minDate={new Date()}
                                  // minDate={new Date("02-01-2022")}
                                  // maxDate={new Date("09-05-2022")}
                                  renderInput={(params) => (
                                    <TextField
                                      // helperText={error?.message}
                                      // error={error}
                                      {...params}
                                      error={errors.AppointmentDate}
                                      helperText={
                                        errors.AppointmentDate ? "Appointment Date Required." : ""
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div> */}

                        <div className="reg_input">
                          <Controller
                            name="AppointmentDate"
                            defaultValue=""
                            id="AppointmentDate"
                            // autocomplete="new-password"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Date*"
                                  // autocomplete="new-password"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    onChange(date);
                                    display(date);
                                  }}
                                  // onClose = {display}
                                  onClose={(e, v) => display(value)}
                                  minDate={new Date()}
                                  // maxDate={new Date("09-05-2022")}
                                  renderInput={(params) => (
                                    <TextField
                                      id="DateValue"
                                      autoComplete="off"
                                      // helperText={error?.message}
                                      // error={error}
                                      {...params}
                                      error={errors.AppointmentDate}
                                      helperText={
                                        errors.AppointmentDate ? "Appointment Date Required." : ""
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <div className="reg_input auto-complete">
                            <Controller
                              control={control}
                              name="AppointmentTime"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="AppointmentTime"
                                  name="AppointmentTime"
                                  value={value}
                                  options={TimeList ? TimeList : []}
                                  onChange={(e, v) => onChange(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Appointment Time*"
                                      error={errors.AppointmentTime}
                                      helperText={
                                        errors.AppointmentTime ? "Appointment Time Required." : ""
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </div>
                        {/* <div className="reg_input">
                          <div className="reg_input auto-complete">
                            <Controller
                              control={control}
                              name="AppointmentTime"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="AppointmentTime"
                                  name="AppointmentTime"
                                  value={value}
                                  // options={TimeList ? TimeList : []}
                                  options={appoinmenTimeList ? appoinmenTimeList : ""}
                                  onChange={(e, v) => onChange(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Appointment Time*"
                                      error={errors.AppointmentTime}
                                      helperText={
                                        errors.AppointmentTime ? "Appointment Time Required." : ""
                                      }
                                    />
                                  )}
                                />
                              )}
                            /> */}

                        {/* <Autocomplete
                              disablePortal
                                id="AppointmentTime"
                              name="AppointmentTime"
                              defaultValue={isEditEnabled ? PatientData?.AppointmentTime : ""}
                              value={
                                !selectedTimeLabel
                                  ? isEditEnabled
                                    ? PatientData?.AppointmentTime
                                    : ""
                                  : selectedTimeLabel
                              }
                              // options={TimeList ? TimeList : []}
                              options={TimeList ? TimeList : []}
                              onChange={(e, value) => {
                                // console.log("EE", value);
                                // console.log("EEEE", value);
                                handleTimeChange(value.id);
                                setSelectedTimeLabel(value.label);
                                // setValues((prev) => ({ ...prev, time: value.id }));
                              }}
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField
                                  {...register("AppointmentTime")}
                                  {...params}
                                  label="Time"
                                />
                              )}
                            /> */}
                        {/* </div>
                        </div> */}
                      </Grid>

                      {/* <Grid item md="8">
                        <div className="reg_input sec_level">
                          <Autocomplete
                            multiple
                            id="Services"
                            name="Services"
                            options={serviceList ? serviceList : []}
                            value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                            defaultValue={
                              serviceAutocompleteValues ? serviceAutocompleteValues : []
                            }
                            onChange={onServiceChange}
                            filterSelectedOptions
                            // formControlProps={{
                            //   fullWidth: true,
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Services you have registered"
                                placeholder="Favorites"
                                // error={errorservices}
                                // helperText={errorservices}
                                id="Services"
                                name="Services"
                                // defaultValue={Params}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid> */}
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="phoneNumber"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              type="number"
                              label="Phone Number"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="6">
                        <div className="reg_input">
                          <Controller
                            name="additionalInformation"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Additional Information"
                                color="primary"
                                variant="outlined"
                                rows={3}
                                multiline
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div className="reg_submit">
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/pages/book-appointment/UserBookAppointmentToken")}
                  >
                    Cancel
                  </MDButton>
                  <MDButton type="submit" variant="contained" color="primary">
                    Submit
                  </MDButton>
                </div>
              </form>
            </CardContent>
          </Card>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

AudioVideoConference.defaultProps = {};

export default AudioVideoConference;
