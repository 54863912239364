import "assets/css/style.css";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CardContent from "@mui/material/CardContent";
import { CommonConfig } from "constant";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";

function ContactusList(props) {
  let navigate = useNavigate();
  let userData = localStorage.getItem("userData");
  if (localStorage.getItem("userData")) {
    // alert("Hello");
  } else {
    // alert("Hello2")
    navigate("/");
  }

  const getData = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "authentication/contactList",
      method: "GET",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("liiiii", res);
          debugger;
          setData(res.data?.Data?.[0] || {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    axios({
      url: CommonConfig.ApiUrl + "authentication/contactList",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("uuuu.....", res);
          setData(res.data.data);
        }
        console.log("testttttt", res.data.data[0]);
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const EditData = (selectedRow = {}) => {
    // console.log("hello",typeof selectedRow)
    localStorage.setItem("ContactData", JSON.stringify(selectedRow));
    // localStorage.setItem("ContactData",selectedRow.ContactId)
    navigate("/pages/contact-us/edit");
  };

  const deleteData = (selectedRow = {}) => {
    // const {AppointmentId, EntityID}=inputData;
    console.log("........,,,", selectedRow);
    let inputData = {
      contactId: selectedRow.ContactId,
      // SecurityUserId:selectedRow.EntityID,
      // AddressId:selectedRow.AddressId
    };
    console.log(inputData);

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Contact/contactDelete",
      method: "PUT",
      headers: {},
      // Attaching the form data
      data: inputData,
    })
      //   Handle the response from backend here
      .then((res) => {
        if (res.data?.success) {
          console.log("test22", res.data);
          getData();
        }
      })
      // Catch errors if any
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1,
      width: "60px",
    },

    {
      name: "Name",
      selector: "Name",
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: "Email",
      sortable: true,
      width: "250px",
    },
    {
      name: "Phone",
      selector: "Phone",
      sortable: true,
      width: "200px",
    },
    {
      name: "Message",
      selector: "Message",
      sortable: true,
      width: "200px",
    },

    {
      name: "Action",
      cell: (row) => (
        <button className="btn btn-primary" onClick={() => deleteData(row)}>
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "60px",
    },
    {
      cell: (row) => (
        <button className="btn btn-danger" onClick={() => EditData(row)}>
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "60px",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              contact_page
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Contact Us
          </MDTypography>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <div className="table-custom">
            <DataTable
              data={data}
              minRows={0}
              filterable
              // defaultFilterMethod={}
              resizable={false}
              columns={columns}
              defaultPageSize={10}
              showPaginationBottom={true}
              className="-striped -highlight chatMgtList"
            />
          </div>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default ContactusList;
