import { MenuItem, TextField, FormControl, InputLabel, Select, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import cogoToast from "cogo-toast";
import "assets/css/style.css";
import { CommonConfig } from "constant";

function EditContact() {
  let ContactData = localStorage.getItem("ContactData");

  ContactData = JSON.parse(ContactData);

  let userData = localStorage.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
  }

  const [values, setValues] = React.useState({
    ContactId: ContactData.ContactId,
    Name: ContactData.Name,
    Email: ContactData.Email,
    Phone: ContactData.Phone,
    Message: ContactData.Message,
    Status: ContactData.Status,
    ManagedBy: ContactData.ManagedBy,
  });

  const [form1, setForm1] = useState(1);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));
  useEffect(() => {
    console.log("-------------", success);
  });

  const handleChange = (event) => {
    console.log(event.target, "targetsselect");

    setValues({ ...values, [event.target.name]: event.target.value });
    // console.log("======", values);
  };

  const handleSubmit = () => {
    console.log(values);
    const inputdata = {
      ContactId: values.ContactId,
      Name: values.Name,
      Email: values.Email,
      Phone: values.Phone,
      Message: values.Message,
      Status: values.Status,
      ManagedBy: values.ManagedBy,
    };
    console.log("hellll", inputdata);
    axios({
      url: CommonConfig.ApiUrl + "Contact/updateContact",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Contact Updated Successfully");
          localStorage.removeItem("ContactData");
          navigate("/pages/contact-us/list");
        }
        console.log("test", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card className="mt-15 dashboard-appointment">
          <CardContent>
            <div className="reg_step edit_contact" id="editContact">
              <div className="edit-contact-inner">
                <h3>Edit Contact Details</h3>
                <Grid container spacing={3}>
                  <Grid item sm={6} md={6}>
                    <div className="cs-select">
                      <FormControl fullWidth className="input-select-outer">
                        <InputLabel htmlFor="selectshipmenttype" className="">
                          Status
                        </InputLabel>
                        <Select
                          value={values.Status}
                          name="Status"
                          onChange={(e) => handleChange(e)}
                        >
                          <MenuItem value={"New"}>New</MenuItem>
                          <MenuItem value={"Open"}>Open</MenuItem>
                          <MenuItem value={"Closed"}>Closed</MenuItem>
                          <MenuItem value={"Cancel"}>Cancel</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <div className="cs-select">
                      <FormControl fullWidth className="input-select-outer">
                        <InputLabel htmlFor="selectshipmenttype" className="">
                          Managed By
                        </InputLabel>
                        <Select value="0">
                          <MenuItem value={10}>Option</MenuItem>
                          <MenuItem value={20}>Option 1</MenuItem>
                          <MenuItem value={30}>Option 2</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6} md={6}>
                    <div className="reg_input">
                      <TextField
                        id="outlined-basic"
                        color="primary"
                        label="Name"
                        variant="outlined"
                        name="Name"
                        value={values.Name}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <div className="reg_input">
                      <TextField
                        id="outlined-basic"
                        color="primary"
                        label="Email"
                        variant="outlined"
                        name="Email"
                        value={values.Email}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6} md={6}>
                    <div className="reg_input">
                      <TextField
                        id="outlined-basic"
                        color="primary"
                        label="Pnone Number"
                        variant="outlined"
                        name="Phone"
                        value={values.Phone}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <div className="reg_input">
                      <TextField
                        id="outlined-basic"
                        color="primary"
                        label="Message"
                        variant="outlined"
                        name="Message"
                        value={values.Message}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Grid container spacing={3}>
              <Grid item>
                <div className="reg_submit">
                  <MDButton
                    variant="contained"
                    color="secondary"
                    // onClick={() => handleSubmit()}
                  >
                    Delete
                  </MDButton>

                  <MDButton variant="contained" color="primary" onClick={() => handleSubmit()}>
                    Update
                  </MDButton>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditContact;
