/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, TextField, InputLabel, Radio, RadioGroup } from "@mui/material";
import { CommonConfig } from "constant";

const RefEdit = (props) => {
  let navigate = useNavigate();
  const { state } = useLocation();

  const [values, setValues] = useState({
    isvalid: false,
    clientFName: "",
    clientLName: "",
    clientDob: "",
    clientPhone: "",
    clientEmail: "",
    clientStreetAddress: "",
    clientCity: "",
    clientState: "",
    clientPostCode: "",
    representativeFName: "",
    representativeLName: "",
    representativePhone: "",
    representativeEmail: "",
    representativeStreetAddress: "",
    representativeCity: "",
    representativeState: "",
    representativePostCode: "",
    ndisPlan: "",
    ndisPlanManagerName: "",
    ndisPlanManagerAgency: "",
    ndisNumber: "",
    ndisFunding: "",
    ndisPlanStartDate: "",
    ndisPlanReviewDate: "",
    ndisClientGoals: "",
    referalFName: "",
    referalLName: "",
    referalAgency: "",
    referalRole: "",
    referalEmail: "",
    referalPhone: "",
    isParticipant: 0,
    referalReason: "",
    reasonRelevantMedicalInfo: "",
    ndisFile: "",

    errorclientFName: "",
    errorclientLName: "",
    errorclientDob: "",
    errorclientPhone: "",
    errorclientEmail: "",
    errorclientStreetAddress: "",
    errorclientCity: "",
    errorclientState: "",
    errorclientPostCode: "",
    errorrepresentativeFName: "",
    errorrepresentativeLName: "",
    errorrepresentativePhone: "",
    errorrepresentativeEmail: "",
    errorrepresentativeStreetAddress: "",
    errorrepresentativeCity: "",
    errorrepresentativeState: "",
    errorrepresentativePostCode: "",
    errorndisPlan: "",
    errorndisPlanManagerName: "",
    errorndisPlanManagerAgency: "",
    errorndisNumber: "",
    errorndisFunding: "",
    errorndisPlanStartDate: "",
    errorndisPlanReviewDate: "",
    errorndisClientGoals: "",
    errorreferalFName: "",
    errorreferalLName: "",
    errorreferalAgency: "",
    errorreferalRole: "",
    errorreferalEmail: "",
    errorreferalPhone: "",
    errorreferalReason: "",
    errorreasonRelevantMedicalInfo: "",
    errorndisFile: "",
    validate: "",
  });

  useEffect(() => {
    if (state) {
      console.log("helllloooo", state);

      setValues({
        ...values,
        clientFName: state?.ClientFName,
        clientLName: state?.ClientLName,
        clientDob: state?.ClientDOB,
        clientPhone: state?.ClientPhone,
        clientEmail: state?.ClientEmail,
        clientStreetAddress: state?.ClientStreetAddress,
        clientCity: state?.ClientCity,
        clientState: state?.ClientState,
        clientPostCode: state?.ClientPostCode,
        representativeFName: state?.RepresentativeFName,
        representativeLName: state?.RepresentativeLName,
        representativePhone: state?.RepresentativePhone,
        representativeEmail: state?.RepresentativeEmail,
        representativeStreetAddress: state?.RepresentativeStreetAddress,
        representativeCity: state?.RepresentativeCity,
        representativeState: state?.RepresentativeState,
        representativePostCode: state?.RepresentativePostalCode,
        ndisPlan: state?.NDISPlan,
        ndisPlanManagerName: state?.NDISPlanManagerName,
        ndisPlanManagerAgency: state?.NDISPlanManagerAgency,
        ndisNumber: state?.NDISNumber,
        ndisFunding: state?.NDISFunding,
        ndisPlanStartDate: state?.NDISPlanStartDate,
        ndisPlanReviewDate: state?.NDISPlanReviewDate,
        ndisClientGoals: state?.NDISClientGoals,
        referalFName: state?.ReferalFName,
        referalLName: state?.ReferalLName,
        referalAgency: state?.ReferalAgency,
        referalRole: state?.ReferalRole,
        referalEmail: state?.ReferalEmail,
        referalPhone: state?.ReferalPhone,
        isParticipant: state?.IsParticipant,
        referalReason: state?.ReferalReason,
        reasonRelevantMedicalInfo: state?.ReasonRelevantMedicalInfo,
        ndisFile: state?.NDISFile,
        ReferralId: state?.ReferralId
      });
    }
  }, [state]);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const validateReferalData = () => {
    if (values.clientFName === "") {
      values.errorclientFName = "Please Enter Client First Name";
      values.isvalid = true;
    } else {
      values.errorclientFName = "";
      values.isvalid = false;
    }
    if (values.clientLName === "") {
      values.errorclientLName = "Please Enter Client Last Name";
      values.isvalid = true;
    } else {
      values.errorclientLName = "";
      values.isvalid = false;
    }

    if (values.clientDob === "") {
      values.errorclientDob = "Please Enter Date Of Birth";
      values.isvalid = true;
    } else {
      values.errorclientDob = "";
      values.isvalid = false;
    }

    if (values.clientPhone === "") {
      values.errorclientPhone = "Please Enter Phone Number";
      values.isvalid = true;
    } else {
      values.errorclientPhone = "";
      values.isvalid = false;
    }

    if (values.clientEmail === "") {
      values.errorclientEmail = "Please Enter Email ID";
      values.isvalid = true;
    } else {
      values.errorclientEmail = "";
      values.isvalid = false;
    }

    if (values.clientStreetAddress === "") {
      values.errorclientStreetAddress = "Please Enter Street Address";
      values.isvalid = true;
    } else {
      values.errorclientStreetAddress = "";
      values.isvalid = false;
    }

    if (values.clientCity === "") {
      values.errorclientCity = "Please Enter City";
      values.isvalid = true;
    } else {
      values.errorclientCity = "";
      values.isvalid = false;
    }

    if (values.clientState === "") {
      values.errorclientState = "Please Enter State";
      values.isvalid = true;
    } else {
      values.errorclientState = "";
      values.isvalid = false;
    }

    if (values.clientPostCode === "") {
      values.errorclientPostCode = "Please Enter Post Code";
      values.isvalid = true;
    } else {
      values.errorclientPostCode = "";
      values.isvalid = false;
    }

    if (values.representativeFName === "") {
      values.errorrepresentativeFName = "Please Enter First Name";
      values.isvalid = true;
    } else {
      values.errorrepresentativeFName = "";
      values.isvalid = false;
    }

    if (values.representativeLName === "") {
      values.errorrepresentativeLName = "Please Enter Last Name";
      values.isvalid = true;
    } else {
      values.errorrepresentativeLName = "";
      values.isvalid = false;
    }

    if (values.representativePhone === "") {
      values.errorrepresentativePhone = "Please Enter Phone Number";
      values.isvalid = true;
    } else {
      values.errorrepresentativePhone = "";
      values.isvalid = false;
    }

    if (values.representativeEmail === "") {
      values.errorrepresentativeEmail = "Please Enter Email Id";
      values.isvalid = true;
    } else {
      values.errorrepresentativeEmail = "";
      values.isvalid = false;
    }

    if (values.representativeStreetAddress === "") {
      values.errorrepresentativeStreetAddress = "Please Enter Street Address";
      values.isvalid = true;
    } else {
      values.errorrepresentativeStreetAddress = "";
      values.isvalid = false;
    }

    if (values.representativeCity === "") {
      values.errorrepresentativeCity = "Please Enter City";
      values.isvalid = true;
    } else {
      values.errorrepresentativeCity = "";
      values.isvalid = false;
    }

    if (values.representativeState === "") {
      values.errorrepresentativeState = "Please Enter State";
      values.isvalid = true;
    } else {
      values.errorrepresentativeState = "";
      values.isvalid = false;
    }

    if (values.representativePostCode === "") {
      values.errorrepresentativePostCode = "Please Enter Post Code";
      values.isvalid = true;
    } else {
      values.errorrepresentativePostCode = "";
      values.isvalid = false;
    }

    if (values.ndisPlan === "") {
      values.errorndisPlan = "Please Select NDIS Plan";
      values.isvalid = true;
    } else {
      values.errorndisPlan = "";
      values.isvalid = false;
    }

    if (values.ndisPlanManagerName === "") {
      values.errorndisPlanManagerName = "Please Enter Your Name";
      values.isvalid = true;
    } else {
      values.errorndisPlanManagerName = "";
      values.isvalid = false;
    }

    if (values.ndisPlanManagerAgency === "") {
      values.errorndisPlanManagerAgency = "Please Enter Your Agency";
      values.isvalid = true;
    } else {
      values.errorndisPlanManagerAgency = "";
      values.isvalid = false;
    }

    if (values.ndisNumber === "") {
      values.errorndisNumber = "Please Enter NDIS Number";
      values.isvalid = true;
    } else {
      values.errorndisNumber = "";
      values.isvalid = false;
    }

    if (values.ndisFunding === "") {
      values.errorndisFunding = "Please Enter Available Funding";
      values.isvalid = true;
    } else {
      values.errorndisFunding = "";
      values.isvalid = false;
    }

    if (values.ndisPlanStartDate === "") {
      values.errorndisPlanStartDate = "Please Enter Plan Start Date";
      values.isvalid = true;
    } else {
      values.errorndisPlanStartDate = "";
      values.isvalid = false;
    }

    if (values.ndisPlanReviewDate === "") {
      values.errorndisPlanReviewDate = "Please Enter Plan Review Date";
      values.isvalid = true;
    } else {
      values.errorndisPlanReviewDate = "";
      values.isvalid = false;
    }

    if (values.ndisClientGoals === "") {
      values.errorndisClientGoals = "Please Enter Client Goals";
      values.isvalid = true;
    } else {
      values.errorndisClientGoals = "";
      values.isvalid = false;
    }

    if (values.referalFName === "") {
      values.errorreferalFName = "Please Enter First Name";
      values.isvalid = true;
    } else {
      values.errorreferalFName = "";
      values.isvalid = false;
    }

    if (values.referalLName === "") {
      values.errorreferalLName = "Please Enter Last Name";
      values.isvalid = true;
    } else {
      values.errorreferalLName = "";
      values.isvalid = false;
    }

    if (values.referalAgency === "") {
      values.errorreferalAgency = "Please Enter Agency";
      values.isvalid = true;
    } else {
      values.errorreferalAgency = "";
      values.isvalid = false;
    }

    if (values.referalRole === "") {
      values.errorreferalRole = "Please Enter Role";
      values.isvalid = true;
    } else {
      values.errorreferalRole = "";
      values.isvalid = false;
    }

    if (values.referalEmail === "") {
      values.errorreferalEmail = "Please Enter Email Id";
      values.isvalid = true;
    } else {
      values.errorreferalEmail = "";
      values.isvalid = false;
    }

    if (values.referalPhone === "") {
      values.errorreferalPhone = "Please Enter Phone Number";
      values.isvalid = true;
    } else {
      values.errorreferalPhone = "";
      values.isvalid = false;
    }

    if (values.referalReason === "") {
      values.errorreferalReason = "Please Select Any One Reason of Referal";
      values.isvalid = true;
    } else {
      values.errorreferalReason = "";
      values.isvalid = false;
    }

    if (values.reasonRelevantMedicalInfo === "") {
      values.errorreasonRelevantMedicalInfo =
        "Please Enter Reason For Referral/Relevant Medical Information";
      values.isvalid = true;
    } else {
      values.errorreasonRelevantMedicalInfo = "";
      values.isvalid = false;
    }

    if (values.ndisFile == "") {
      setValues({ ...values, ["errorndisFile"]: "Please Select NDIS File" });
      values.isvalid = true;
    } else {
      setValues({ ...values, ["errorndisFile"]: "" });
      values.isvalid = false;
    }
  };

  const referalSubmit = () => {
    validateReferalData();

    if (
      values.errorclientFName == "" &&
      values.errorclientLName == "" &&
      values.errorndisPlanManagerName == "" &&
      values.errorndisPlanManagerAgency == "" &&
      values.errorreasonRelevantMedicalInfo == "" &&
      values.isvalid === false
    ) {
      const inputdata = {
        clientFName: values.clientFName,
        clientLName: values.clientLName,
        clientDob: values.clientDob,
        clientPhone: values.clientPhone,
        clientEmail: values.clientEmail,
        clientStreetAddress: values.clientStreetAddress,
        clientCity: values.clientCity,
        clientState: values.clientState,
        clientPostCode: values.clientPostCode,
        representativeFName: values.representativeFName,
        representativeLName: values.representativeLName,
        representativePhone: values.representativePhone,
        representativeEmail: values.representativeEmail,
        representativeStreetAddress: values.representativeStreetAddress,
        representativeCity: values.representativeCity,
        representativeState: values.representativeState,
        representativePostCode: values.representativePostCode,
        ndisPlan: values.ndisPlan,
        ndisPlanManagerName: values.ndisPlanManagerName,
        ndisPlanManagerAgency: values.ndisPlanManagerAgency,
        ndisNumber: values.ndisNumber,
        ndisFunding: values.ndisFunding,
        ndisPlanStartDate: values.ndisPlanStartDate,
        ndisPlanReviewDate: values.ndisPlanReviewDate,
        ndisClientGoals: values.ndisClientGoals,
        referalFName: values.referalFName,
        referalLName: values.referalLName,
        referalAgency: values.referalAgency,
        referalRole: values.referalRole,
        referalEmail: values.referalEmail,
        referalPhone: values.referalPhone,
        isParticipant: values.isParticipant,
        referalReason: values.referalReason,
        reasonRelevantMedicalInfo: values.reasonRelevantMedicalInfo,
        ndisFile: values.ndisFile,
        ReferralId: state?.ReferralId
      };
      axios({
        url: CommonConfig.ApiUrl + "Referral/AddUpdateReferral",
        method: "POST",
        headers: {},
        data: inputdata,
      })
        .then((res) => {
          if (res.data.success === true) {
            cogoToast.success("Updated Successfully");
            navigate("/layouts/referal/Referallist");
          } else {
            cogoToast.error("Something Went Wrong");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card className="mt-15">
          <CardContent>
            <div className="reg_step referral_form" id="referralForm">
              <div className="referral_form_inner">
                <h2>Referral Form</h2>
                <div className="ref_form_box">
                  <h3>Client Details</h3>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.clientFName}
                          color="primary"
                          label="First Name *"
                          variant="outlined"
                          error={values["errorclientFName"]}
                          id="clientFName"
                          name="clientFName"
                          helperText={values["errorclientFName"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          color="primary"
                          value={values.clientLName}
                          label="Last Name *"
                          variant="outlined"
                          error={values["errorclientLName"]}
                          id="clientLName"
                          name="clientLName"
                          helperText={values["errorclientLName"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.clientDob}
                          color="primary"
                          label="Date of Birth *"
                          variant="outlined"
                          error={values["errorclientDob"]}
                          id="clientDob"
                          name="clientDob"
                          helperText={values["errorclientDob"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          color="primary"
                          label="Phone Number *"
                          value={values.clientPhone}
                          variant="outlined"
                          error={values["errorclientPhone"]}
                          id="clientPhone"
                          name="clientPhone"
                          helperText={values["errorclientPhone"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          color="primary"
                          label="Email Address"
                          value={values.clientEmail}
                          variant="outlined"
                          error={values["errorclientEmail"]}
                          id="clientEmail"
                          name="clientEmail"
                          helperText={values["errorclientEmail"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input">
                        <TextField
                          color="primary"
                          value={values.clientStreetAddress}
                          label="Street Address *"
                          variant="outlined"
                          error={values["errorclientStreetAddress"]}
                          id="clientStreetAddress"
                          name="clientStreetAddress"
                          helperText={values["errorclientStreetAddress"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.clientCity}
                          color="primary"
                          label="City *"
                          variant="outlined"
                          error={values["errorclientCity"]}
                          id="clientStreetCity"
                          name="clientCity"
                          helperText={values["errorclientCity"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.clientState}
                          color="primary"
                          label="State *"
                          variant="outlined"
                          error={values["errorclientState"]}
                          id="clientStreetState"
                          name="clientState"
                          helperText={values["errorclientState"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.clientPostCode}
                          color="primary"
                          label="Postcode *"
                          variant="outlined"
                          error={values["errorclientPostCode"]}
                          id="clientPostCode"
                          name="clientPostCode"
                          helperText={values["errorclientPostCode"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="ref_form_box">
                  <h3>Client Representative Details (If Applicable)</h3>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.representativeFName}
                          color="primary"
                          label="First Name"
                          variant="outlined"
                          error={values["errorrepresentativeFName"]}
                          id="representativeFName"
                          name="representativeFName"
                          helperText={values["errorrepresentativeFName"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.representativeLName}
                          color="primary"
                          label="Last Name"
                          variant="outlined"
                          error={values["errorrepresentativeLName"]}
                          id="representativeLName"
                          name="representativeLName"
                          helperText={values["errorrepresentativeLName"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.representativePhone}
                          color="primary"
                          label="Phone Number"
                          variant="outlined"
                          error={values["errorrepresentativePhone"]}
                          id="representativePhone"
                          name="representativePhone"
                          helperText={values["errorrepresentativePhone"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.representativeEmail}
                          color="primary"
                          label="Email Address"
                          variant="outlined"
                          error={values["errorrepresentativeEmail"]}
                          id="representativeEmail"
                          name="representativeEmail"
                          helperText={values["errorrepresentativeEmail"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input">
                        <TextField
                          value={values.representativeState}
                          color="primary"
                          label="Street Address"
                          variant="outlined"
                          error={values["errorrepresentativeStreetAddress"]}
                          id="representativeStreetAddress"
                          name="representativeStreetAddress"
                          helperText={values["errorrepresentativeStreetAddress"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.representativeCity}
                          color="primary"
                          label="City"
                          variant="outlined"
                          error={values["errorrepresentativeCity"]}
                          id="representativeCity"
                          name="representativeCity"
                          helperText={values["errorrepresentativeCity"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.representativeState}
                          color="primary"
                          label="State"
                          variant="outlined"
                          error={values["errorrepresentativeState"]}
                          id="representativeState"
                          name="representativeState"
                          helperText={values["errorrepresentativeState"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.representativePostCode}
                          color="primary"
                          label="Postcode"
                          variant="outlined"
                          error={values["errorrepresentativePostCode"]}
                          id="representativePostCode"
                          name="representativePostCode"
                          helperText={values["errorrepresentativePostCode"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="ref_form_box">
                  <h3>NDIS Details</h3>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input check_value mb-0">
                        <InputLabel>Plan *</InputLabel>
                        <RadioGroup
                          aria-label="ndisplan"
                          name="ndisPlan"
                          id="ndisPlan"
                          value={values.ndisPlan}
                        >
                          <FormControlLabel
                            value="Plan Managed"
                            onChange={(e) => handleChange(e)}
                            control={<Radio />}
                            label="Plan Managed"
                          />
                          <FormControlLabel
                            value="Self Managed"
                            onChange={(e) => handleChange(e)}
                            control={<Radio />}
                            label="Self Managed"
                          />
                          <FormControlLabel
                            value="Agency Managed"
                            onChange={(e) => handleChange(e)}
                            control={<Radio />}
                            label="Agency Managed"
                          />
                        </RadioGroup>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.ndisPlanManagerName}
                          color="primary"
                          label="Plan Manager Name (If Applicable)"
                          variant="outlined"
                          error={values["errorndisPlanManagerName"]}
                          id="ndisPlanManagerName"
                          name="ndisPlanManagerName"
                          helperText={values["errorndisPlanManagerName"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          color="primary"
                          label="Plan Manager Agency (If Applicable)"
                          value={values.ndisPlanManagerAgency}
                          variant="outlined"
                          error={values["errorndisPlanManagerAgency"]}
                          id="ndisPlanManagerAgency"
                          name="ndisPlanManagerAgency"
                          helperText={values["errorndisPlanManagerAgency"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          color="primary"
                          value={values.ndisNumber}
                          label="NDIS Number *"
                          variant="outlined"
                          error={values["errorndisNumber"]}
                          id="ndisNumber"
                          name="ndisNumber"
                          helperText={values["errorndisNumber"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          color="primary"
                          value={values.ndisFunding}
                          label="Available/Remaing Funding for Capacity Building Supports"
                          variant="outlined"
                          error={values["errorndisFunding"]}
                          id="ndisFunding"
                          name="ndisFunding"
                          helperText={values["errorndisFunding"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.ndisPlanStartDate}
                          color="primary"
                          label="Plan Start Date *"
                          variant="outlined"
                          error={values["errorndisPlanStartDate"]}
                          id="ndisPlanStartDate"
                          name="ndisPlanStartDate"
                          helperText={values["errorndisPlanStartDate"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.ndisPlanReviewDate}
                          color="primary"
                          label="Plan Review Date *"
                          variant="outlined"
                          error={values["errorndisPlanReviewDate"]}
                          id="ndisPlanReviewDate"
                          name="ndisPlanReviewDate"
                          helperText={values["errorndisPlanReviewDate"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <TextField
                          value={values.ndisClientGoals}
                          color="primary"
                          label="Client Goals (As stated in the NDIS plan) *"
                          variant="outlined"
                          error={values["errorndisClientGoals"]}
                          id="ndisClientGoals"
                          name="ndisClientGoals"
                          helperText={values["errorndisClientGoals"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="ref_form_box">
                  <h3>Referrer Details (Person Making the Referral)</h3>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.referalFName}
                          color="primary"
                          label="First Name *"
                          variant="outlined"
                          error={values["errorreferalFName"]}
                          id="referalFName"
                          name="referalFName"
                          helperText={values["errorreferalFName"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.referalLName}
                          color="primary"
                          label="Last Name *"
                          variant="outlined"
                          error={values["errorreferalLName"]}
                          id="referalLName"
                          name="referalLName"
                          helperText={values["errorreferalFName"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.referalAgency}
                          color="primary"
                          label="Agency"
                          variant="outlined"
                          error={values["errorreferalAgency"]}
                          id="referalAgency"
                          name="referalAgency"
                          helperText={values["errorreferalAgency"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.referalRole}
                          color="primary"
                          label="Role"
                          variant="outlined"
                          error={values["errorreferalRole"]}
                          id="referalRole"
                          name="referalRole"
                          helperText={values["errorreferalRole"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.referalEmail}
                          color="primary"
                          label="Email Address *"
                          variant="outlined"
                          error={values["errorreferalEmail"]}
                          id="referalEmail"
                          name="referalEmail"
                          helperText={values["errorreferalEmail"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <TextField
                          value={values.referalPhone}
                          color="primary"
                          label="Phone Number *"
                          variant="outlined"
                          error={values["errorreferalPhone"]}
                          id="referalPhone"
                          name="referalPhone"
                          helperText={values["errorreferalPhone"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="1"
                              id="isParticipant"
                              name="isParticipant"
                              color="primary"
                              onChange={(e) => handleChange(e)}
                              value={values.isParticipant}
                            />
                          }
                          label="I have obtained consent from the participant to make this referral and provide Compass Physiotherapy with the participant's personal and medical details. *"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="ref_form_box">
                  <h3>Reason For Referral</h3>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input check_value mb-0">
                        <InputLabel>Referred For *</InputLabel>
                        <RadioGroup
                          aria-label="referredfor"
                          name="referalReason"
                          id="referalReason"
                          value={values.referalReason}
                        >
                          <FormControlLabel
                            value="Physiotherapy"
                            onChange={(e) => handleChange(e)}
                            control={<Radio />}
                            label="Physiotherapy"
                          />
                          <FormControlLabel
                            value="Chiro"
                            onChange={(e) => handleChange(e)}
                            control={<Radio />}
                            label="Chiro"
                          />
                          <FormControlLabel
                            value="Psychologist"
                            onChange={(e) => handleChange(e)}
                            control={<Radio />}
                            label="Psychologist"
                          />
                          <FormControlLabel
                            value="Other"
                            onChange={(e) => handleChange(e)}
                            control={<Radio />}
                            label="Other"
                          />
                        </RadioGroup>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input">
                        <TextField
                          color="primary"
                          value={values.reasonRelevantMedicalInfo}
                          label="Reason For Referral/Relevant Medical Information *"
                          variant="outlined"
                          error={values["errorreasonRelevantMedicalInfo"]}
                          id="reasonRelevantMedicalInfo"
                          name="reasonRelevantMedicalInfo"
                          helperText={values["errorreasonRelevantMedicalInfo"]}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input">
                        <p>
                          File Upload (Please attach a copy of the current NDIS plan if possible)
                        </p>
                        <input
                          // value={values.ndisFile}
                          type="file"
                          id="ndisFile"
                          name="ndisFile"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item>
                      <div className="reg_submit">
                        <MDButton
                          variant="contained"
                          color="primary"
                          onClick={() => referalSubmit()}
                        >
                          Submit
                        </MDButton>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default RefEdit;
