import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  Autocomplete,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Button,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PrimeDatatable from "layouts/component/PrimeDatatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import { NotesComponent } from "layouts/component/Notes";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { ArrowDownward } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";

import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Logo from "assets/img/valuecare-logo.png";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";

const schema = yup.object().shape({
  RiskLocation: yup.object().required("Location Required."),
  AssessedBy: yup.object().required("Assessed By Required."),
  AssessmentDate: yup.string().required("Assessment Date Required."),
  // HazardIdentified: yup
  //   .string()
  //   .required("Risk to Health & Safety / Hazard Identified required"),
  PersonAtRisk: yup.string().required("Person At Risk Required."),
  // MeasuresToPrevent: yup
  //   .string()
  //   .required("Measures to prevent or control the risks to health and safety required"),
  // FurtherAction: yup.string().required("Further Action At Risk required"),
  // ActionBy: yup.object().required("Action By required"),
  // ActionDate: yup.string().required("Action Date required"),
  // ActionTaken: yup.string().required("Action Taken required"),
  // RiskReviewDate: yup.string().required("Risk Review Date required"),
  // AssignedTo: yup.object().required("By Whom required"),
});

const OfficeRiskAssessment = () => {
  const [todayDate, settodayDate] = useState();
  const [internalStaff, setInternalStaff] = useState(null);
  const [complaintList, setComplaintList] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [officeRiskAssessmentId, setOfficeRiskAssessmentId] = useState(null);
  const [officeRiskAssessmentNumber, setOfficeRiskAssessmentNumber] = useState(null);
  const [locationDropdownList, setLocationDropdownList] = useState(null);
  const [notesEnable, setNotesEnable] = useState(false);
  const [notesDropdownList, setNotesDropdownList] = useState(null);
  const [selectedNotesType, setSselectedNotesType] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [chNotes, setChNotes] = useState(null);
  const [values, setValues] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [loader, setLoader] = React.useState(false);

  let navigate = useNavigate();
  let loginUser;
  if (localStorage.getItem("userData")) {
    // alert("Hello");
    loginUser = JSON.parse(localStorage.getItem("userData"));
  } else {
    // alert("Hello2")
    navigate("/");
  }
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      AssessmentDate: "",
      OfficeRiskAssessmentId: "",
      CurrentUser: "",
      // EntityID: "",
      // EntityType: "",
      RiskLocation: "",
      AssessedBy: "",
      MeasuresToPrevent: "",
      PersonAtRisk: "",
      HazardIdentified: "",
      FurtherAction: "",
      ActionBy: "",
      ActionDate: "",
      ActionTaken: "",
      RiskReviewDate: "",
      AssignedTo: "",
    },
  });

  useEffect(() => {
    getInternalStaff();
    getComplaintList();
    getLocationDropdownList();
    getNotesDropdownList();
    // getNoteList();
  }, []);

  let column = [
    {
      name: "Assessed By",
      selector: "AssessedByName",
      // body: (data) => {
      //   if (data.AssessedBy) {
      //     // console.log("data", data);
      //     // console.log("internalStaff", internalStaff);
      //     let receivedName = internalStaff?.filter((item) => item.id == data.AssessedBy);
      //     if (receivedName && receivedName.length > 0) {
      //       return receivedName[0].label;
      //     }
      //     return "";
      //   }
      //   return "";
      // },
      filter: true,
      sortable: true,
    },

    {
      name: "Location",
      selector: "RiskLocation",
      // body: (data) => {
      //   if (data.RiskLocation) {
      //     // console.log("data", data);
      //     // console.log("internalStaff", internalStaff);
      //     let receivedName = locationDropdownList?.filter((item) => item.id == data.RiskLocation);
      //     if (receivedName && receivedName.length > 0) {
      //       return receivedName[0].label;
      //     }
      //     return "";
      //   }
      //   return "";
      // },
      filter: true,
      sortable: true,
    },

    {
      name: "Assessment Date",
      // body: (data) => {
      //   if (data.AssessmentDate) {
      //     return CommonConfig.formatDate(data.AssessmentDate);
      //   }
      //   return "";
      // },
      selector: "AssessmentDate",
      filter: true,
      sortable: true,
    },
    {
      name: "Hazard Identified",
      selector: "HazardIdentified",
      filter: true,
      sortable: true,
    },
    {
      name: "Person At Risk",
      selector: "PersonAtRisk",
      filter: true,
      sortable: true,
    },

    {
      name: "Status",
      selector: "Status",
      filter: true,
      sortable: true,
    },
    {
      name: "Action",
      body: (data) => actionTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const actionTemplate = (row) => {
    return (
      <PButton
        type="button"
        icon="pi pi-pencil"
        className="p-button-warning"
        onClick={() => {
          // setIsEditEnabled(true);
          setShowAdd(true);
          setNotesEnable(true);
          // console.log("ROW", row);
          if (row.OfficeRiskAssessmentId) {
            setOfficeRiskAssessmentId(row.OfficeRiskAssessmentId);
            setOfficeRiskAssessmentNumber(row.OfficeRiskAssessmentNumber);
            getNoteList(row.OfficeRiskAssessmentId);
            // getNoteList()
          }

          let receivedByObj;
          if (row.AssessedBy) {
            receivedByObj = internalStaff.filter((item) => item.id == row.AssessedBy);
          }
          let receivedByObj1;
          if (row.RiskLocation) {
            receivedByObj1 = locationDropdownList.filter((item) => item.id == row.RiskLocation);
          }
          let receivedByObj2;
          if (row.ActionBy) {
            receivedByObj2 = internalStaff.filter((item) => item.id == row.ActionBy);
          }
          let receivedByObj3;
          if (row.AssignedTo) {
            receivedByObj3 = internalStaff.filter((item) => item.id == row.AssignedTo);
          }
          // // console.log("DATA", {
          // //   ...row,
          // //   ReceivedBy: receivedByObj ? receivedByObj[0] : "",
          // //   AssignedTo: assignToObj ? assignToObj[0] : "",
          // // });
          reset({
            ...row,
            AssessedBy: receivedByObj ? receivedByObj[0] : "",
            RiskLocation: receivedByObj1 ? receivedByObj1[0] : "",
            ActionBy: receivedByObj2 ? receivedByObj2[0] : "",
            AssignedTo: receivedByObj3 ? receivedByObj3[0] : "",
            // AssignTo: assignToObj ? assignToObj[0] : "",
          });
        }}
        style={{ marginRight: ".5em" }}
        tooltip="Edit Complaint"
        tooltipOptions={{ position: "bottom" }}
      />
    );
  };

  const getColumn = () =>
    column.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const getComplaintList = () => {
    debugger;
    setLoader(!loader);
    let formattedData = {
      OfficeRiskAssessmentId: "",
      CurrentUser: "",
    };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "qualityManagment/getOfficeRiskAssessmentData",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: formattedData,
    })
      // Handle the response from backend here
      .then((res) => {
        // console.log("RRRRR", res);
        if (res.data.success) {
          setLoader(false);
          console.log("StateList1===>", res.data.data);

          var PersonType = loginUser.PersonType;
          var loginUserName = loginUser.FirstName + " " + loginUser.LastName;
          if (PersonType == "INTERNAL STAFF") {
            // setLoader(false);
            var tempstore = [];
            for (var i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].AssessedByName === loginUserName) {
                tempstore.push(res.data.data[i]);
              }
            }
            setComplaintList(tempstore);
          } else {
            setComplaintList(res.data.data);
          }
          // setComplaintList(res.data.data);
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const getLocationDropdownList = () => {
    const formattedData = {
      StringMapType: "OFFICELOCATION",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setLocationDropdownList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      .then((res) => {
        if (res.data.success) {
          setInternalStaff(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNotesDropdownList = () => {
    const formattedData = {
      StringMapType: "NOTETYPE.OfficeRiskAssessmentRegister",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setSselectedNotesType(res.data.data[0].StringMapName);
          // setNotesDropdownList(
          //   res.data.data.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const saveNotes = () => {
    // if (selectedNotesType === null) {
    //   document.getElementById("ServicesError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("ServicesError").style.display = "none";
    // }

    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }
    debugger;
    // ComplaintRegister
    const formattedData = {
      EntityType: selectedNotesType,
      EntityId: officeRiskAssessmentId ? officeRiskAssessmentId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: selectedNotesType,
      NoteTitle: "",
      NoteText: chNotes,
    };

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(officeRiskAssessmentId);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        cogoToast.error("Something went wrong.");
      });
  };

  const getNoteList = (values) => {
    // debugger;
    let noteReqData = {
      EntityType: selectedNotesType,
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: noteReqData,
    })
      .then((res) => {
        // console.log("Hello147 ", res.data);
        if (res.data?.success) {
          // console.log("-------------", data);
          setNotesData(res.data.data || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    console.log("data", data);
    let formattedDate = CommonConfig.formatDate(data.AssessmentDate);
    let formattedDate1 = CommonConfig.formatDate(data.ActionDate);
    let formattedDate2 = CommonConfig.formatDate(data.RiskReviewDate);

    let riskLocation = data.RiskLocation?.id;
    let assessedBy = data.AssessedBy?.id;
    let actionBy = data.ActionBy?.id;
    let assignedTo = data.AssignedTo?.id;

    let formattedData = {
      ...data,
      RiskLocation: riskLocation,
      AssessedBy: assessedBy,
      ActionBy: actionBy,
      AssignedTo: assignedTo,
    };
    setLoader(!loader);

    formattedData = {
      ...formattedData,
      AssessmentDate: formattedDate,
      ActionDate: formattedDate1,
      RiskReviewDate: formattedDate2,
      // ComplaintRegisterId: "",
      // EntityID: "",
      // EntityType: "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };

    debugger;

    console.log("formattedDataT", formattedData);

    axios({
      url: CommonConfig.ApiUrl + "qualityManagment/addUpdateOfficeRiskAssessment",

      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data.success === true) {
          getComplaintList();
          setShowAdd(false);
          setNotesEnable(false);
          if (officeRiskAssessmentNumber) {
            cogoToast.success("Updated Successfully.");
            setOfficeRiskAssessmentNumber(null);
          } else {
            cogoToast.success("Registered Successfully.");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              report
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Office Risk Assessment
          </MDTypography>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 0, ml: "auto", mr: 2 }}>
            {officeRiskAssessmentNumber ? "Reference Number:" + officeRiskAssessmentNumber : ""}
          </MDTypography>
          <MDButton
            variant="contained"
            color="primary"
            sx={{ mt: 1, mb: 0, ml: "auto", mr: 2 }}
            onClick={() => {
              setShowAdd((prev) => !prev);
              setNotesEnable(false);
              reset({});
              setOfficeRiskAssessmentNumber(null);
            }}
          >
            {showAdd ? "Show List" : "Add New"}
          </MDButton>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          {showAdd ? (
            <div className="reg_step quality-form">
              <div className="referral_form_inner">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="RiskLocation"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="RiskLocation"
                              name="RiskLocation"
                              value={value}
                              options={locationDropdownList ? locationDropdownList : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Location*"
                                  error={errors.RiskLocation}
                                  helperText={errors.RiskLocation ? "Location Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="AssessedBy"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="AssessedBy"
                              name="AssessedBy"
                              value={value}
                              options={internalStaff ? internalStaff : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Assessed By*"
                                  error={errors.AssessedBy}
                                  helperText={errors.AssessedBy ? "Assessed By Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="AssessmentDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Assessment Date*"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                minDate={new Date()}
                                // minDate={new Date("02-01-2022")}
                                // maxDate={new Date("09-05-2022")}
                                renderInput={(params) => (
                                  <TextField
                                    // helperText={error?.message}
                                    // error={error}
                                    {...params}
                                    error={errors.AssessmentDate}
                                    helperText={
                                      errors.AssessmentDate ? "Assessment Date Required." : ""
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>

                      {/* <div className="reg_input">
                        <Controller
                          name="AssessmentDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Assessment Date*"
                                // value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                // minDate={new Date()}
                                // minDate={new Date("02-01-2022")}
                                // maxDate={new Date("09-05-2022")}
                                renderInput={(params) => (
                                  <TextField
                                    helperText={error?.message}
                                    error={error}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div> */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="HazardIdentified"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Risk to Health & Safety / Hazard Identified"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="PersonAtRisk"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="People At Risk*"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="MeasuresToPrevent"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Measures to prevent or control the risks to health and safety"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="FurtherAction"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Any further action or information"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="ActionBy"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="ActionBy"
                              name="ActionBy"
                              value={value}
                              options={internalStaff ? internalStaff : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Action By"
                                  error={errors.ActionBy}
                                  helperText={errors.ActionBy ? "Action By required" : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="ActionDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Action Date"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                // minDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    // helperText={error?.message}
                                    // error={error}
                                    {...params}
                                    error={errors.ActionDate}
                                    helperText={errors.ActionDate ? "" : ""}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="ActionTaken"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Action Taken"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  {/* <h3 className="mb-20">Risk Assessment Review</h3> */}
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="RiskReviewDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Date to be carried out by"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                // minDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={errors.RiskReviewDate}
                                    helperText={errors.RiskReviewDate ? "" : ""}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="AssignedTo"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="AssignedTo"
                              name="AssignedTo"
                              value={value}
                              options={internalStaff ? internalStaff : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="By Whom"
                                  error={errors.AssignedTo}
                                  helperText={errors.AssignedTo ? "By Whom required" : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item>
                      <div className="reg_submit">
                        {/* <MDButton variant="contained" color="secondary">
                          Cancel
                        </MDButton> */}
                        <MDButton
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setShowAdd(false);
                            setNotesEnable(false);
                            setOfficeRiskAssessmentNumber(null);
                          }}
                        >
                          Cancel
                        </MDButton>

                        <MDButton type="submit" variant="contained" color="primary">
                          {/* Submit */}
                          {officeRiskAssessmentNumber ? "Update" : "Submit"}
                        </MDButton>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          ) : (
            <div className="table-custom">
              <PrimeDatatable data={complaintList} getColumnListing={getColumn} />
            </div>
          )}
        </MDBox>
      </Card>

      {notesEnable && CommonConfig.isInternalStaff() === 1 ? (
        <Card sx={{ width: "100%", mt: 6 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                warning_amber
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Notes
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            {/* <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className="reg_input">
              <Autocomplete
                options={notesDropdownList ? notesDropdownList : []}
                defaultValue={selectedNotesType ? selectedNotesType : ""}
                onChange={(e, value) => {
                  setSselectedNotesType(value.label);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Notes Type*"
                    name="NotesType"
                    // error={values.errorstate}
                    // helperText={values.errorstate}
                  />
                )}
              />
              <span class="errorTC" id="ServicesError">
                Please select Notes Type.
              </span>
            </div>
          </Grid>
          <Grid item xs={4}>
            <ReportGmailerrorredIcon
              color={inNoteImportant ? "primary" : "secondary"}
              fontSize="large"
              onClick={() => setinNoteImportant((prev) => !prev)}
            />
          </Grid>
        </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* <div className="reg_input">
              <TextField
                onChange={(e) => setChNotes(e.target.value)}
                value={chNotes ? chNotes : ""}
                name="notes"
                multiline
                rows={3}
                // maxRows={4}
                label="Notes Text*"
                id="regFirstName"
                fullWidth
              />
              <span class="errorTC" id="NotesError">
                Please Write Notes.
              </span>
            </div> */}
              </Grid>
            </Grid>

            <div className="notes-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Notes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td>
                    <td className="nt-comment">
                      <div className="reg_input mb-0">
                        <TextField
                          onChange={(e) => setChNotes(e.target.value)}
                          value={chNotes ? chNotes : ""}
                          name="notes"
                          multiline
                          rows={2}
                          // maxRows={4}
                          label="Notes Text*"
                          id="regFirstName"
                          fullWidth
                        />
                        <span class="errorTC" id="NotesError">
                          Please Write Notes.
                        </span>
                      </div>
                    </td>
                    <td className="nt-action">
                      <PButton
                        type="button"
                        icon="pi pi-plus"
                        className="p-button-primary AddNotes"
                        onClick={() => saveNotes()}
                        // style={{ marginRight: ".5em" }}
                        tooltip="Add Notes"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Grid item md="4">
              {/* <MDButton onClick={() => saveNotes()} variant="contained" color="primary">
            Save Notes
          </MDButton> */}
            </Grid>
            <NotesComponent
              entityType="OfficeRiskAssessmentId"
              entityId={officeRiskAssessmentId}
              NotesData={notesData}
            />
          </MDBox>
        </Card>
      ) : (
        <></>
      )}

      {/* {notesEnable ? (
        <Accordion>
          <AccordionSummary expandIcon={<ArrowDownward />}>Notes</AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} sx={{ mb: 2 }}>
              <Grid item xs={3}>
                <h5>Notes Type: </h5>
              </Grid>
              <Grid item xs={4}>
                <div className="reg_input">
                  <Autocomplete
                    options={notesDropdownList ? notesDropdownList : []}
                    defaultValue={selectedNotesType ? selectedNotesType : ""}
                    onChange={(e, value) => {
                      setSselectedNotesType(value.label);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Notes Type*"
                        name="NotesType"
                        error={values.errorstate}
                        helperText={values.errorstate}
                      />
                    )}
                  />
                  <span class="errorTC" id="ServicesError">
                    Please select Notes Type.
                  </span>
                  <ReportGmailerrorredIcon
                    color={inNoteImportant ? "primary" : "secondary"}
                    fontSize="large"
                    onClick={() => setinNoteImportant((prev) => !prev)}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mb: 2 }}>
              <Grid item xs={3}>
                <h5>Notes Text: </h5>
              </Grid>
              <Grid item xs={4}>
                <div className="reg_input">
                  <TextField
                    onChange={(e) => setChNotes(e.target.value)}
                    value={chNotes ? chNotes : ""}
                    name="notes"
                    multiline
                    rows={3}
                    style={{
                      marginBottom: "10px",
                    }}
                    // maxRows={4}
                    label="Notes*"
                    id="regFirstName"
                    fullWidth
                  />
                  <span class="errorTC" id="NotesError">
                    Please Write Notes.
                  </span>
                </div>
              </Grid>
            </Grid>
            <Grid item md="4">
              <MDButton onClick={() => saveNotes()} variant="outlined" color="dark">
                Save Notes
              </MDButton>
            </Grid>
          </AccordionDetails>

          <NotesComponent
            entityType="OfficeRiskAssessmentId"
            entityId={officeRiskAssessmentId}
            NotesData={notesData}
          />
        </Accordion>
      ) : (
        <></>
      )} */}
    </DashboardLayout>
  );
};
export default OfficeRiskAssessment;
