import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import moment from "moment";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

const schema = yup.object().shape({
  //   reason: yup.string().required("Reason Required"),

  AppointmentDate: yup.string().required("Appointment Date Required."),
  Time: yup.object().required("Appointment Time Required."),

  //   additionalInformation: yup.string().required("Additional Information Required"),
  //   phoneNumber: yup
  //     .string()
  //     .required("Phone required")
  //     .matches("^[0-9]+$", "Phone should be number")
  //     .min(10, "Invalid Phone Number")
  //     .max(10, "Invalid Phone Number"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RequestCallback = () => {
  const { state } = useLocation();
  let navigate = useNavigate();
  let clientRef = useRef(false);

  let PatientData = state ? state.PatientData : null;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [termsCondition, setTermsCondition] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [isEditEnabled, setIsEditEnabled] = useState(state ? true : false);
  const [managedData, setManagedData] = useState(null);
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const [chManagedBy, setChManagedBy] = useState(null);
  const [chStatus, setChStatus] = useState(null);
  const [appoinmentStatusList, setAppoinmentStatusList] = useState(null);
  const [defaultStatus, setDefaultStatus] = useState("Pending");
  const [appointmentFor, setAppointmentFor] = useState(null);
  const [personData, setPersonData] = useState(null);
  const [appoinmentLabel, setAppoinmentLabel] = useState(null);
  const [isSelfAppoinmentChecked, setIsSelfAppoinmentChecked] = useState("false");
  const [appoinmentfullName, setAppoinmentfullName] = useState(null);
  const [TimeSlot, setTimeSlot] = useState(null);
  const [selectedTimeLabel, setSelectedTimeLabel] = useState(null);
  const [isTimeValid, setisTimeValid] = useState(false);
  const [selectedCallBackTimeSlot, setSelectedCallBackTimeSlot] = useState(null);
  const [TimeList, setTimeList] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);
  const [userSelectedServices, setUserSelectedServices] = useState(null);
  const [officeSiteData, setOfficeSiteData] = useState(null);
  const [selectedOfficeSite, setSelectedOfficeSite] = useState(null);
  const [AddrLine1, setAddrLine1] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [managedBy, setManagedBy] = useState(null);
  const [AppointmentType, setAppointmentType] = useState(null);
  const [selectedMediumType, setSelectedMediumType] = useState(null);
  const [loader, setLoader] = React.useState(false);

  let PersonUserName = state ? state.Name : null;
  let PersonUserId = state ? state.PersonId : null;
  let AppointmentTimeSet = "";

  const onManagedByChangeStatusChanged = (e, value) => {
    console.log("VVV", value);
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
  };

  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          // cogoToast.success("Appointment Booked Successfully");
          console.log("Managed Data", res.data.data);

          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
          // navigate("/pages/book-appointment/list");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const onStatusChanged = (e, value) => {
    setChStatus(value.id);
  };

  const onManagedByChanged = (e, value) => {
    setManagedBy(value);
  };

  const getState = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let statelistdata = res.data.data;
          setStateData(
            statelistdata.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOfficeSiteData = () => {
    const formattedData = {
      StringMapType: "VALUE CARE SITE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let officeSiteDataa = res.data.data;
          setOfficeSiteData(
            officeSiteDataa.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onOfficeChange = (e, value) => {
    setSelectedOfficeSite(value);
  };

  const getAppoinmentStatus = () => {
    const formattedData = { StringMapType: "APPOINTMENTSTATUS", SortType: "Alpha" };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let appoinmentStatus = res.data.data;
          setAppoinmentStatusList(
            appoinmentStatus.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserName = () => {
    console.log("appoinmentLabel", appoinmentLabel);
    // debugger;
    // setDefaultStatus("Pending")
    let appointment = "";
    if (PersonUserName) {
      setAppointmentFor(PersonUserName);
    } else if (isSelfAppoinmentChecked === "true") {
      let userData = localStorage.getItem("userData");
      if (userData) {
        userData = JSON.parse(userData);
      }
      appointment = userData.FirstName + " " + userData.LastName;
      setAppointmentFor(appointment);
    } else if (isSelfAppoinmentChecked != "true") {
      setAppointmentFor(localStorage.getItem("PersonUserName"));
    } else {
      setAppointmentFor("");
    }
  };
  const getPersonById = () => {
    let Requestdata = {
      PersonId: PersonUserId ? PersonUserId : CommonConfig.getCurrentUserId(),
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "contact/getPersonById",
      method: "POST",
      headers: {},
      data: Requestdata,
    })
      .then((res) => {
        if (res.data.success) {
          if (localStorage.getItem("PersonUserName")) {
            setPersonData(
              res.data.data.map((item) => ({
                id: item.PersonId,
                personFullName: localStorage.getItem("PersonUserName"),
              }))
            );
          } else {
            setPersonData(
              res.data.data.map((item) => ({
                id: item.PersonId,
                personFullName: item.FirstName + " " + item.LastName,
              }))
            );
          }
        }
      })
      .catch((error) => {});
  };

  const handleTimeChange = (value) => {
    if (value) {
      AppointmentTimeSet = value;
      localStorage.setItem("AppointmentTimeSet", AppointmentTimeSet);
      // setValues({ ...setSelectedTime, [event.target.name]: event.target.value });

      setSelectedTime(value);

      setisTimeValid(true);
      console.log("Time", AppointmentTimeSet);
    } else setisTimeValid(false);
  };

  const handleCallBackTimeChange = (value) => {
    if (value) {
      setSelectedCallBackTimeSlot(value);
      setisTimeValid(true);
    } else setisTimeValid(false);

    if (isEditEnabled) {
      setSelectedTimeLabel(PatientData?.TimeSlot);
    }
  };

  const getTimeSlot = () => {
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getTimeSlotValue",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data?.success) {
          // console.log("TimeSlotListRES===>", res);
          // console.log("TimeSlotList===>", res.data.data);
          setTimeList(
            res.data.data.map((item) => ({
              id: item.TimeValue,
              label: item.TimeLabel,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCallBackTimeSlot = () => {
    const formattedData = {
      StringMapType: "DAYTIMING",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          debugger;
          // console.log("AppoinmentTimeSlot===>", res.data.data);
          var sort = [];
          res.data.data.map((item2) => {
            if (item2.StringMapKey === "MORNING") {
              sort.push(item2);
            }
          });

          res.data.data.map((item3) => {
            if (item3.StringMapKey === "AFTERNOON") {
              sort.push(item3);
            }
          });
          res.data.data.map((item4) => {
            if (item4.StringMapKey === "EVENING") {
              sort.push(item4);
            }
          });
          res.data.data.map((item5) => {
            if (item5.StringMapKey === "LATE HOURS") {
              sort.push(item5);
            }
          });
          console.log("sort...", sort);
          setTimeSlot(
            sort.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          // console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setAutocompleteValues = (value) => {
    let dummyArray = [];
    if (value) {
      setUserSelectedServices(value);
      for (let i = 0; i < value.length; i++) {
        if (value[i]) {
          dummyArray.push({
            id: value[i],
            label: value[i],
          });
        }
      }
      // console.log("DUMMy", dummyArray);
      // setValues(dummyArray);
      setServiceAutocompleteValues(dummyArray);
    }
  };

  const onServiceChange = (event, value) => {
    console.log("ValueService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }

    setUserSelectedServices(formattedData);
    setServiceAutocompleteValues(value);
  };

  const onStateChange = (event, value) => {
    console.log("VVV", value.id);
    setValues(value.id);
    setStateLabel(value.label);

    console.log("-------bhanu--------", value);
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getState();
    getOfficeSiteData();
    getTimeSlot();
    getInternalStaff();
    getStateData();
    getAppoinmentStatus();
    getPersonById();
    getServiceData();
    getCallBackTimeSlot();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      //   UserType: "EXTERNAL",
      //   UserName: userData?.UserName ? userData?.UserName : UserName,
      //   Password: userData?.Password ? userData?.Password : Password,
      //   Appointment: userData?.Appointment ? userData?.Appointment : "",
      //   reason: userData?.reason ? userData?.reason : "",

      IsLocationSelector: userData?.IsLocationSelector
        ? userData?.IsLocationSelector
        : "value care office site",
      AddrLine1: userData?.AddrLine1 ? userData?.AddrLine1 : "",
      AddrLine2: userData?.AddrLine2 ? userData?.AddrLine2 : "",
      Suburb: userData?.Suburb ? userData?.Suburb : "",

      IsSubscription: userData?.IsSubscription ? userData?.IsSubscription : 0,
    },
  });

  const IsLocationSelector = watch("IsLocationSelector");

  const onSubmit = (data) => {
    let formattedDate = moment(data.AppointmentDate).format("YYYY-MM-DD");
    debugger;
    console.log("DATA:100:", data);
    let managedBYInternalStaff = "";
    if (localStorage.getItem("userData")) {
      var localUserData = JSON.parse(localStorage.getItem("userData"));
      var PersonType = localUserData.PersonType;
      if (PersonType == "INTERNAL STAFF") {
        managedBYInternalStaff = localUserData.PersonId;
      }
    }
    let isValueCareSite = "0";
    let mediumType = "";
    if (AppointmentType == "Audio/Video Conference") {
      mediumType = selectedMediumType;
    }

    let officeSite = selectedOfficeSite ? selectedOfficeSite.id : "";
    if (isEditEnabled && isValueCareSite == "1") {
      officeSite = selectedOfficeSite.id ? selectedOfficeSite.id : selectedOfficeSite;
    }

    let managedByID = managedBy ? managedBy.id : "";
    if (isEditEnabled) {
      managedByID = managedBy?.id ? managedBy?.id : managedBy;
    }

    // if (data.State === null || data.State === undefined || data.State === "") {
    //   document.getElementById("Error").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("Error").style.display = "none";
    // }

    // if (!termsCondition) {
    //   document.getElementById("TcError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("TcError").style.display = "none";
    // }
    setLoader(!loader);
    let formattedData = {
      ...data,
      AppointmentID: PatientData?.AppointmentID ? PatientData?.AppointmentID : "",
      PersonID: localStorage.getItem("PersonUserIdApp")
        ? localStorage.getItem("PersonUserIdApp")
        : CommonConfig.getCurrentUserId(),
      // AppointmentMode: localStorage.getItem("AppointmentType"),
      AppointmentTime: "",
      AppointmentMode: localStorage.getItem("AppointmentType"),
      AppointmentDate: formattedDate,
      MediumType: mediumType,
      TimeSlot: data.Time.label,
      IsValueCareSite: isValueCareSite,
      ValueCareSite: officeSite,
      PhoneForConfirmation: data.phoneNumber,
      AdditionalDetail: data.additionalInformation,
      AddressType: "",
      //   State: selectedState,
      ManagedBy: managedBYInternalStaff,

      Status: chStatus ? chStatus : PatientData?.Status,
      // AssignToStaffID: chManagedBy,
      // Note: chNotes,
    };
    localStorage.removeItem("AppointmentTimeSet");
    localStorage.removeItem("PersonUserIdApp");
    localStorage.removeItem("PersonUserName");
    console.log("Final formated data", formattedData);

    let formattedDataT = {
      formattedData: formattedData,
      Services: localStorage.getItem("setSelectedServices"),
      CurrentUserId: CommonConfig.getCurrentUserId(),
    };
    console.log(" addUpdateAppointment API req data", formattedDataT);
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/addUpdateAppointment",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },

      // Attaching the form data
      data: formattedDataT,
    })
      // Handle the response from backend here
      .then((res) => {
        setLoader(!loader);
        if (res.data.success) {
          cogoToast.success("Appointment Booked Successfully.");
          console.log("RESSS", res);
          navigate("/pages/book-appointment/list");
        }
        console.log("......ggggggggg", res.data.success);
        console.log("test", res.data);
        // cogoToast.error('Something went wrong');
      })

      // //   // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });

    console.log("formattedDataT", formattedDataT);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        <MDBox>
          <Card className="mt-15 dashboard-appointment">
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reg_step" id="regStep6">
                  <Grid item md="12">
                    <h2 className="h2-heading">Request Callback</h2>
                  </Grid>
                  <div className="reg_form">
                    <Grid container spacing={3}>
                      <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="Status"
                            control={control}
                            defaultValue={
                              isEditEnabled
                                ? PatientData?.FirstName + " " + PatientData?.LastName
                                : appointmentFor
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-read-only-input"
                                label="Status"
                                style={{
                                  width: "200px",
                                }}
                                value={
                                  isEditEnabled
                                    ? !chStatus
                                      ? PatientData?.Status
                                      : chStatus
                                    : defaultStatus
                                }
                                onChange={onChange}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            name="Appointmentfor"
                            control={control}
                            defaultValue={
                              isEditEnabled
                                ? PatientData?.FirstName + " " + PatientData?.LastName
                                : appointmentFor
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-read-only-input"
                                label="Appointment For"
                                // value={isSelfAppoinmentChecked == "true" ?(personData ? personData[0].personFullName : ''):(!appoinmentLabel ? (personData ? personData[0].personFullName : '') :
                                // (relatedPersonList ? relatedPersonList[0].personFullName : ''))}
                                value={
                                  PersonUserName
                                    ? PersonUserName
                                    : appoinmentfullName
                                    ? appoinmentfullName
                                    : personData
                                    ? personData[0].personFullName
                                    : ""
                                }
                                // value={isEditEnabled ? PatientData?.FirstName + " " + PatientData?.LastName : appointmentFor}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AppointmentDate"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Appointment Date*"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={onChange}
                                  minDate={new Date()}
                                  // minDate={new Date("02-01-2022")}
                                  // maxDate={new Date("09-05-2022")}
                                  renderInput={(params) => (
                                    <TextField
                                      // helperText={error?.message}
                                      // error={error}
                                      {...params}
                                      error={errors.AppointmentDate}
                                      helperText={
                                        errors.AppointmentDate ? "Appointment Date Required." : ""
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>

                        {/* <div className="reg_input">
                          <Controller
                            name="AppointmentDate"
                            defaultValue=""
                            control={control}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Date*"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={onChange}
                                  minDate={new Date()}
                                  // minDate={new Date("02-01-2022")}
                                  // maxDate={new Date("09-05-2022")}
                                  renderInput={(params) => (
                                    <TextField
                                      // helperText={error?.message}
                                      // error={error}
                                      // {...params}
                                        error={errors.AppointmentDate}
                                        helperText={
                                          errors.AppointmentDate ? "Appointment Date required" : ""
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div> */}
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <div className="reg_input auto-complete">
                            {/* <Autocomplete
                            id="combo-box-demo"
                            options={TimeSlot ? TimeSlot : []}
                            value={selectedTimeLabel ? selectedTimeLabel : ""}
                            onChange={(e, value) => {
                              handleCallBackTimeChange(value.label);
                              setSelectedTimeLabel(value.label);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Time"
                                name="time"
                                defaultValue={
                                  isEditEnabled ? PatientData?.AppointmentTime : ""
                                }
                                // value="time"
                                // error={values.errortime}
                                // helperText={values.errortime}
                                // onChange={(e) => handleChange(e, "time")}
                              />
                            )}
                          /> */}

                            <Controller
                              control={control}
                              name="Time"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="Time"
                                  name="Time"
                                  value={value}
                                  options={TimeSlot ? TimeSlot : []}
                                  onChange={(e, v) => onChange(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Time*"
                                      error={errors.Time}
                                      helperText={errors.Time ? "Appointment Time Required." : ""}
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="phoneNumber"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone Number"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="6">
                        <div className="reg_input">
                          <Controller
                            name="additionalInformation"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Additional Information"
                                color="primary"
                                variant="outlined"
                                rows={3}
                                multiline
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div className="reg_submit">
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/pages/book-appointment/UserBookAppointmentToken")}
                  >
                    Cancel
                  </MDButton>
                  <MDButton type="submit" variant="contained" color="primary">
                    Submit
                  </MDButton>
                </div>
              </form>
            </CardContent>
          </Card>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

RequestCallback.defaultProps = {};

export default RequestCallback;
