import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AccessibleIcon from "@mui/icons-material/Accessible";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";
import { autoLogin } from "../../commonAPI/commonAPI";
import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";

const schema = yup.object().shape(
  {
    FirstName: yup
      .string()
      .required("First Name Required.")
      .min(3, "First Name must be at least 3 characters."),
    LastName: yup
      .string()
      .required("Last Name Required.")
      .min(3, "Last Name must be at least 3 characters."),

    Email: yup.string().required("Email Required.").email("Incorrect email format."),
    PhoneNumber: yup
      .string()
      .required("Phone Required.")
      .matches("^[0-9]+$", "Phone should be number.")
      .min(10, "Invalid Phone Number.")
      .max(10, "Invalid Phone Number."),
    // DOB: yup.string().required("DOB required"),

    IsLiving: yup.boolean(),
    GroupHome: yup.string().when("IsLiving", {
      is: true,
      then: yup.string().required("Group Home Required."),
    }),

    // Services: yup.array().of(
    //   yup.object().shape({
    //     value: yup.string(),
    //     label: yup.string()
    //   })
    // )
    // .min(1, "Services are required"),

    AddrLine1: yup.string().required("Street Address Required."),
    // AddrLine2: yup.string().required("Street Name Required"),
    Suburb: yup.string().required("Suburb Required."),

    IsNdisDocument: yup.boolean(),
    Document: yup.string().when("IsNdisDocument", {
      //is: true,
      is: true,
      then: yup.string().required("Please Select Document."),
      //then: yup.string().required("Please Select Document."),
      //   // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png, .pdf and .doc", (value) => {
      //   //     return value && (
      //   //         value[0].type === "image/jpeg" ||
      //   //         value[0].type === "image/png" ||
      //   //         value[0].type === 'application/pdf' ||
      //   //         value[0].type === "application/msword"
      //   //     );
      //   // }),
    }),

    // PostalCode: yup
    //   .string()
    //   .nullable()
    //   .notRequired()
    //   .when("PostalCode", {
    //     is: (value) => value?.length,
    //     then: yup
    //       .string()
    //       .required("Postal Code required")
    //       .matches("^[0-9]+$", "Postal Code should be number")
    //       .min(4, "Invalid Postal Code")
    //       .max(4, "Invalid Postal Code"),
    //   }),
    PostalCode: yup
      .string()
      .required("Postal Code Required.")
      .matches("^[0-9]+$", "Should be number.")
      .min(4, "Postal Code must be at least 4 digit.")
      .max(4, "Invalid Postal Code."),

    IsNdisRegistered: yup.boolean(),
    NDISNumber: yup.string().when("IsNdisRegistered", {
      is: true,
      then: yup
        .string()
        .required("NDIS Number Required.")
        .matches("^[0-9]+$", "Should be number.")
        .min(9, "NDIS Number must be at least 9 digit.")
        .max(9, "Invalid NDIS Number."),
    }),

    CoordinatorName: yup.string().when(["CoordinatorPhone", "CoordinatorEmail"], {
      is: (CoordinatorPhone, CoordinatorEmail) =>
        CoordinatorPhone !== "" || CoordinatorEmail !== "",
      then: yup.string().required("Name Required."),
    }),
    CoordinatorPhone: yup.string().when(["CoordinatorName", "CoordinatorEmail"], {
      is: (CoordinatorName, CoordinatorEmail) => CoordinatorName !== "" || CoordinatorEmail !== "",
      then: yup
        .string()
        .required("Phone Required.")
        .matches("^[0-9]+$", "Phone should be number.")
        .min(10, "Invalid Phone Number.")
        .max(10, "Invalid Phone Number."),
    }),
    CoordinatorEmail: yup.string().when(["CoordinatorName", "CoordinatorPhone"], {
      is: (CoordinatorName, CoordinatorPhone) => CoordinatorName !== "" || CoordinatorPhone !== "",
      then: yup.string().required("Email required.").email("Incorrect email format."),
    }),

    RelativeName: yup.string().when(["RelativePhone", "RelativeEmail"], {
      is: (RelativePhone, RelativeEmail) => RelativePhone !== "" || RelativeEmail !== "",
      then: yup.string().required("Name Required."),
    }),
    RelativePhone: yup.string().when(["RelativeName", "RelativeEmail"], {
      is: (RelativeName, RelativeEmail) => RelativeName !== "" || RelativeEmail !== "",
      then: yup
        .string()
        .required("Phone Required.")
        .matches("^[0-9]+$", "Phone should be number.")
        .min(10, "Invalid Phone Number.")
        .max(10, "Invalid Phone Number."),
    }),
    RelativeEmail: yup.string().when(["RelativeName", "RelativePhone"], {
      is: (RelativeName, RelativePhone) => RelativeName !== "" || RelativePhone !== "",
      then: yup.string().required("Email Required.").email("Incorrect email format."),
    }),

    // RelativeName: yup
    //   .string()
    //   .required("Name required")
    //   .min(3, "Name must be at least 3 characters"),
    // RelativePhone: yup
    //   .string()
    //   .required("Phone required")
    //   .matches("^[0-9]+$", "Phone should be number")
    //   .min(10, "Invalid Phone Number")
    //   .max(10, "Invalid Phone Number"),
    // RelativeEmail: yup.string().required("Email required").email("Incorrect email format"),

    //   RelativeAddrLine2: yup.string().required("Street Address required"),
    //   RelativeAddrLine1: yup.string().required("Street Name required"),
    //   RelativeSuburb: yup.string().required("Suburb required"),
    //   RelativePostalCode: yup
    //     .string()
    //     .required("Postal Code required")
    //     .matches("^[0-9]+$", "Postal Code should be number")
    //     .min(4, "Postal Code must be at least 4 digit")
    //       .max(4, "Invalid Postal Code"),
    RelativePostalCode: yup
      .string()
      .nullable()
      .notRequired()
      .when("RelativePostalCode", {
        is: (value) => value?.length,
        then: yup
          .string()
          .required("Postal Code Required.")
          .matches("^[0-9]+$", "Postal Code should be number.")
          .min(4, "Invalid Postal Code.")
          .max(4, "Invalid Postal Code."),
      }),

    State: yup.object().required("State Required."),
    // RelativeState: yup.object().required("State required"),
    // Disabilities: yup.string().required("Disabilities Required"),
  },
  [
    ["CoordinatorName", "CoordinatorPhone"],
    ["CoordinatorName", "CoordinatorEmail"],
    ["CoordinatorPhone", "CoordinatorEmail"],
    ["RelativeName", "RelativePhone"],
    ["RelativeName", "RelativeEmail"],
    ["RelativePhone", "RelativeEmail"],
    ["RelativePostalCode", "RelativePostalCode"],
    ["PostalCode", "PostalCode"],
  ]
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SignUpDifferentlyAbled = () => {
  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const MAX_COUNT = 5;
  const [values, setValues] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [relativeStateId, setRelativeStateId] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [termsCondition, setTermsCondition] = useState(null);

  const [stateList, setStateList] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const [serviceList, setServiceList] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loader, setLoader] = React.useState(false);

  // console.log("APIURL===========", CommonConfig.ApiUrl);

  // console.log("Service list State-----------", serviceList);

  const onStateChange = (event, value) => {
    console.log("State::", value);
    setStateId(value.id);
    setStateLabel(value.label);
  };

  const onRelativeStateChange = (event, value) => {
    console.log("RelativeState::", value);
    setRelativeStateId(value.id);
    setRelativeStateLabel(value.label);
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];

    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
    Document = uploaded;
  };
  const setAutocompleteValues = () => {
    let dummyArray = [];
    let formattedData = [];
    let array = JSON.parse(state.SecurityUserData.Services);
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        dummyArray.push({
          id: array[i],
          label: array[i],
        });
      }
    }
    for (let i = 0; i < array.length; i++) {
      formattedData.push(array[i]);
    }
    debugger;
    setValues(formattedData);
    setServiceAutocompleteValues(dummyArray);
  };
  console.log("values", values);

  const onServiceChange = (event, value) => {
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }

    setValues(formattedData);
    setServiceAutocompleteValues(value);
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
          setAutocompleteValues();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getStateData();
    getServiceData();
  }, []);

  const UserName = localStorage.getItem("UserName");
  const Password = localStorage.getItem("Password");
  const FirstName = localStorage.getItem("FirstName");
  const LastName = localStorage.getItem("LastName");
  const EditUserName = userData ? (userData.UserName ? userData.UserName : "") : "";
  const EditPassword = userData ? (userData.passwords ? userData.passwords : "") : "";
  let PersonType = JSON.parse(localStorage.getItem("userData"))
    ? JSON.parse(localStorage.getItem("userData")).PersonType
    : "";

  const statedata = {
    id: userData?.State,
    label: userData?.State,
  };
  const RelativeStatedata = {
    id: userData?.RelativeState,
    label: userData?.RelativeState,
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      PersonType: "DIFFERENTLY ABLED",
      UserType: "EXTERNAL",
      UserName: userData?.UserName ? userData?.UserName : UserName,
      Password: userData?.Password ? userData?.Password : Password,
      FirstName: FirstName ? FirstName : userData?.FirstName ? userData?.FirstName : "",
      MiddleName: userData?.MiddleName ? userData?.MiddleName : "",
      LastName: LastName ? LastName : userData?.LastName ? userData?.LastName : "",
      Gender: userData?.Gender ? userData?.Gender : "Male",
      DOB: userData?.DOB ? userData?.DOB : "",
      PhoneNumber: userData?.PhoneNumber ? userData?.PhoneNumber : "",
      Email: userData?.Email ? userData?.Email : "",
      GroupHome: userData?.GroupHome ? userData?.GroupHome : "",
      AddrLine1: userData?.AddrLine1 ? userData?.AddrLine1 : "",
      AddrLine2: userData?.AddrLine2 ? userData?.AddrLine2 : "",
      Suburb: userData?.Suburb ? userData?.Suburb : "",
      State: userData?.State ? statedata : "",
      PostalCode: userData?.PostalCode ? userData?.PostalCode : "",
      IsLiving: userData?.IsLiving ? userData?.IsLiving : 0,
      IsNdisRegistered: userData?.IsNdisRegistered ? userData?.IsNdisRegistered : 0,
      IsNdisDocument: userData?.IsNdisDocument ? userData?.IsNdisDocument : 0,
      NDISNumber: userData?.NDISNumber ? userData?.NDISNumber : "",
      Document: "",
      CoordinatorName: userData?.CoordinatorName ? userData?.CoordinatorName : "",
      CoordinatorPhone: userData?.CoordinatorPhone ? userData?.CoordinatorPhone : "",
      CoordinatorEmail: userData?.CoordinatorEmail ? userData?.CoordinatorEmail : "",
      RelativeName: userData?.RelativeName ? userData?.RelativeName : "",
      RelativePhone: userData?.RelativePhone ? userData?.RelativePhone : "",
      RelativeEmail: userData?.RelativeEmail ? userData?.RelativeEmail : "",
      RelativeAddrLine2: userData?.RelativeAddrLine2 ? userData?.RelativeAddrLine2 : "",
      RelativeAddrLine1: userData?.RelativeAddrLine1 ? userData?.RelativeAddrLine1 : "",
      RelativeSuburb: userData?.RelativeSuburb ? userData?.RelativeSuburb : "",
      RelativeState: userData?.RelativeState ? RelativeStatedata : "",
      RelativePostalCode: userData?.RelativePostalCode ? userData?.RelativePostalCode : "",
      Disabilities: userData?.Disabilities ? userData?.Disabilities : "",
      Appointment: userData?.Appointment ? userData.Appointment : 0,
      Services: userData?.Services ? userData?.Services : "",
    },
  });

  let IsLiving = watch("IsLiving", true);
  let IsNdisRegistered = watch("IsNdisRegistered", true);
  let IsNdisDocument = watch("IsNdisDocument", true);
  let Appointment = watch("Appointment", true);

  console.log("eeeeeee", errors);

  const autoLogin = (email, password) => {
    debugger;
    const inputdata = {
      Username: email,
      Password: password,
    };
    console.log("Input data", inputdata);
    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "authentication/authenticateUser",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        debugger;
        console.log("test.....12", res.data.data[0][0].returnValue);

        // if (res.status === 200) {
        if (res.data.data[0][0].returnValue == "Invalid Username or password") {
          cogoToast.error("Invalid User.");
        } else {
          if (res.data.success === true) {
            // cogoToast.success("Login Successfully");

            var sendData = {
              PersonType: res.data.data[0][0].PersonType,
            };

            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));

            axios({
              url: CommonConfig.ApiUrl + "authentication/getUserMenus",
              method: "POST",
              headers: {},
              data: sendData,
            })
              .then((res) => {
                console.log("test", res.data.data[0]);

                localStorage.setItem("Menu", JSON.stringify(res.data.data[0]));

                if (res.data.success === true) {
                  // setTimeout(() => {
                  //   let navigate = useNavigate();
                  //   navigate("/pages/book-appointment/UserBookAppointmentToken");
                  // }, 1000);
                  navigate("/pages/book-appointment/UserBookAppointmentToken");
                } else {
                  cogoToast.error("Invalid User.");
                }
              })
              .catch((error) => {
                console.log(error);
              });

            // // debugger
            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));
            //    setTimeout(() => {
            //   let navigate = useNavigate();
            //   navigate("/pages/book-appointment/UserBookAppointmentToken");
            // }, 1000);
          } else {
            cogoToast.error("Invalid User.");
          }
        }
      })
      .catch((error) => {
        return false;
        console.log(error);
      });
  };

  console.log("Serv", values);
  const onSubmit = (data) => {
    console.log("data", data);
    let FState = data.State?.id;
    let SState = data.RelativeState?.id;

    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");

    debugger;
    if (data.Appointment === "1" || data.Appointment === 1) {
      if (values.length === 0) {
        document.getElementById("ServicesError").style.display = "block";
        return;
      } else {
        document.getElementById("ServicesError").style.display = "none";
      }
    }

    if (!userData) {
      if (!termsCondition) {
        document.getElementById("TcError").style.display = "block";
        return;
      } else {
        document.getElementById("TcError").style.display = "none";
      }
    }
    setLoader(!loader);
    let DobFormat = CommonConfig.formatDate(data.DOB);
    let formattedData = "";
    let sevicelist = [];
    sevicelist = values;
    formattedData = {
      ...data,
      DOB: DobFormat,
      IsLiving: IsLiving == true ? 1 : 0,
      IsNdisRegistered: IsNdisRegistered == true ? 1 : 0,
      IsNdisDocument: IsNdisDocument == true ? 1 : 0,
      Services: sevicelist,
      State: FState,
      RelativeState: SState,
    };
    console.log("formattedData", formattedData);

    if (userData) {
      formattedData = {
        ...formattedData,
        PersonId: state?.PersonId,
      };
    }

    let formattedDataT;

    formattedDataT = {
      formattedData,
      Services: sevicelist,
    };
    console.log("formattedDataT", formattedDataT);

    if (userData) {
      formattedDataT = {
        formattedData,
        CurrentUser: CommonConfig.getCurrentUserId(),
      };
    }

    console.log("formattedDataTTT.....", formattedDataT);

    axios({
      url: CommonConfig.ApiUrl + "authentication/addUpdateUser",

      method: "POST",
      headers: {},
      data: formattedDataT,
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data.success === true) {
          // cogoToast.success("Registered Successfully.");
          if (!userData) {
            cogoToast.success("Registered Successfully.");
          } else {
            cogoToast.success("Updated Successfully.");
          }

          let loginData;

          if (!userData) {
            loginData = autoLogin(UserName, Password);
          } else if (userData && PersonType !== "Admin") {
            loginData = autoLogin(EditUserName, EditPassword);
          } else {
            navigate("/dashboards/analytics");
          }

          // let loginData;
          // if (!userData) {
          //   loginData = autoLogin(UserName, Password);
          // }
          // console.log("loginData", loginData);
          // if (userData) {
          //   navigate("/dashboards/analytics");
          // }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log("AddUpdateErro", error);
      });
  };

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {userData ? (
        <DashboardLayout>
          <DashboardNavbar />
          <Card sx={{ width: "100%", mt: 3 }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="primary"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  accessible
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Edit differently-abled person
              </MDTypography>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: "auto", mr: 2 }}>
                Person# : {userData.PersonIdNumber}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reg_step" id="regStep1">
                  <div className="reg_form">
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="FirstName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="First Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="MiddleName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Middle Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="LastName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Last Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="reg_input check_value">
                      <InputLabel>Gender</InputLabel>
                      <Controller
                        name="Gender"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            {" "}
                            <RadioGroup
                              aria-label="appointment"
                              row="true"
                              defaultValue={userData ? userData?.Gender : "Male"}
                            >
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Male"
                                control={<Radio />}
                                label="Male"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Female"
                                control={<Radio />}
                                label="Female"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Unknown"
                                control={<Radio />}
                                label="Unknown"
                              />
                            </RadioGroup>
                          </>
                        )}
                      />
                    </div>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="DOB"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Select DOB"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={onChange}
                                  // minDate={new Date("15-08-1947")}
                                  maxDate={new Date()}
                                  renderInput={(params) => (
                                    <TextField
                                      autoComplete="off"
                                      // helperText={error?.message}
                                      // error={error}
                                      // {...params}
                                      {...params}
                                      error={errors.DOB}
                                      helperText={errors.DOB ? "" : ""}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="PhoneNumber"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone Number*"
                                variant="outlined"
                                maxLength="10"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                disabled={userData?.Email ? true : false}
                                label="Email*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Are you Living in a Group Home?</InputLabel>
                          <Controller
                            name="IsLiving"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                defaultValue={userData ? userData?.IsLiving : 0}
                                aria-label="Living"
                                name="IsLiving"
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                            rules={{ required: "Email Id required" }}
                          />
                        </div>
                        {IsLiving == 1 ? (
                          <div className="reg_input sec_level">
                            <Controller
                              name="GroupHome"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Group Home*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <InputLabel className="reg_lable">Address</InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine1"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Address*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine2"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Suburb"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Suburb*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="State"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="State"
                                name="State"
                                value={value}
                                options={stateList ? stateList : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    {...params}
                                    label="State*"
                                    error={errors.State}
                                    helperText={errors.State ? "State Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="2">
                        <div className="reg_input">
                          <Controller
                            name="PostalCode"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postal Code*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 4,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Are you registered with NDIS1? </InputLabel>
                          <Controller
                            name="IsNdisRegistered"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                aria-label="NDIS"
                                defaultValue={userData ? userData?.IsNdisRegistered : "0"}
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                          />
                        </div>

                        {IsNdisRegistered == 1 ? (
                          <div className="reg_input sec_level">
                            <Controller
                              name="NDISNumber"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Enter NDIS Number*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 9,
                                  }}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Do you have your NDIS plan document available?</InputLabel>
                          <Controller
                            name="IsNdisDocument"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                defaultValue={userData ? userData?.IsNdisDocument : "0"}
                                aria-label="NDIS Document"
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                          />
                        </div>
                        {IsNdisDocument == 1 ? (
                          <div className="reg_input sec_level">
                            <Controller
                              name="Document"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  type="file"
                                  onChange={onChange}
                                  //label="Select Document*"
                                  // error={error}
                                  // helperText={error?.message}
                                />
                              )}
                            />
                            {userData ? (
                              <div>
                                {userData.Document ? <label>{userData.Document} </label> : null}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <div className="reg_input mb-1">
                          <InputLabel className="reg_lable">
                            Your Support Coordinator details.
                          </InputLabel>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="CoordinatorName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="CoordinatorPhone"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="CoordinatorEmail"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                disabled={userData?.CoordinatorEmail ? true : false}
                                label="Email"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div className="reg_box_bordered">
                      <Grid item md="12">
                        <div className="reg_input mb-1">
                          <InputLabel className="reg_lable">
                            Your primary carer/parent/relative’s details.
                          </InputLabel>
                        </div>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="RelativeName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="RelativePhone"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  type="number"
                                  label="Phone"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="RelativeEmail"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  disabled={userData?.RelativeEmail ? true : false}
                                  label="Email"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item md="12">
                        <div className="reg_input mb-1">
                          <InputLabel className="reg_lable">Address.</InputLabel>
                        </div>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="RelativeAddrLine2"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Street Address"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="RelativeAddrLine1"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Relative Street Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="RelativeSuburb"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Suburb"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input auto-complete">
                            <Controller
                              control={control}
                              name="RelativeState"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="RelativeState"
                                  name="RelativeState"
                                  value={value}
                                  options={stateList ? stateList : ""}
                                  onChange={(e, v) => onChange(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="State"
                                      error={errors.RelativeState}
                                      helperText={errors.RelativeState ? "State Required." : ""}
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="2">
                          <div className="reg_input">
                            <Controller
                              name="RelativePostalCode"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Postal Code"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 4,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>

                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                      <Grid item md="8">
                        <div className="reg_input">
                          <InputLabel className="reg_lable mb-20 mt-15">
                            What impairments or disabilities does the person has?{" "}
                          </InputLabel>
                          <Controller
                            name="Disabilities"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Disability Condition"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <div className="reg_input check_value">
                          <InputLabel>
                            Do you know which services you need or shall we arrange an Appointment
                            with one of our Client Manager?
                          </InputLabel>
                          <Controller
                            name="Appointment"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                aria-label="appointment"
                                defaultValue={userData ? userData?.Appointment : "0"}
                                row="true"
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="1"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="0"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                            rules={{ required: "Select any one" }}
                          />
                        </div>
                        <Grid item sm={6} md={6}>
                          {Appointment == 1 ? (
                            <div className="reg_input sec_level">
                              {/* <Controller
                            name="Services"
                            control={control}
                            defaultValue={[]}
                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                              <Autocomplete
                                {...field}
                                disableClearable
                                disablePortal
                                filterSelectedOptions
                                multiple
                                // value={serviceAutocompleteValues}
                                value={undefined}
                                options={serviceList ? serviceList : ""}
                                id="Services"
                                onChange={(event, value) => {
                                  field.onChange(value);
                                  // onServiceChange(value);
                                }}
                                // options={daysOfWeekSuggestions}
                                renderInput={(params) => (
                                  <TextField
                                    // required
                                    error={!!error}
                                    helperText={error?.message}
                                    id="Services"
                                    label="Services"
                                    name="Services"
                                    type="search"
                                    inputRef={ref}
                                    {...params}
                                  />
                                )}
                              />
                            )}
                          /> */}

                              <Autocomplete
                                multiple
                                id="Services"
                                name="Services"
                                options={serviceList ? serviceList : []}
                                value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                                defaultValue={
                                  serviceAutocompleteValues ? serviceAutocompleteValues : []
                                }
                                onChange={onServiceChange}
                                filterSelectedOptions
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="What services are you registered for?*"
                                    placeholder="Favorites"
                                    // error={errorservices}
                                    // helperText={errorservices}
                                    id="Services"
                                    name="Services"
                                    // defaultValue={Params}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                )}
                              />
                              <span class="errorTC" id="ServicesError">
                                Please select any one service.
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        {!userData ? (
                          <Grid container spacing={3}>
                            <Grid item>
                              <div className="reg_input check_value">
                                <Checkbox
                                  checked={termsCondition}
                                  onChange={() => setTermsCondition((prev) => !prev)}
                                  color="primary"
                                  label="By registering you agree to Value Care’s Terms &amp; Conditions."
                                />
                                By registering you agree to Value Care’s Terms &amp; Conditions.
                                <br></br>
                                <span class="servicesError" id="TcError">
                                  {!termsCondition &&
                                    "Please Select Terms & Conditions to continue."}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>

                {userData ? (
                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/dashboards/analytics")}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      Update
                    </MDButton>
                  </div>
                ) : (
                  <div>
                    <Button
                      type="submit"
                      style={{ color: "white" }}
                      variant="contained"
                      color="primary"
                    >
                      Register
                    </Button>
                  </div>
                )}
              </form>
            </MDBox>
          </Card>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </DashboardLayout>
      ) : (
        <div className="signup-page-outer">
          <CoverLayout className="sign-up-outer">
            <div className="container">
              <div className="logo">
                <a href="www.google.com">
                  <img alt="Test" src={Logo} />
                </a>
              </div>
              <div className="reg-inner">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="reg_step" id="regStep1">
                    <Grid item md="12">
                      <h2 className="h2-heading">
                        I am a differently-abled person or in need of supports seeking Value Care’s
                        services.
                      </h2>
                    </Grid>
                    <div className="reg_form">
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="FirstName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="First Name*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="MiddleName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Middle Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="LastName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Last Name*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input check_value">
                            <InputLabel>Gender</InputLabel>
                            <Controller
                              name="Gender"
                              control={control}
                              defaultValue=""
                              rules={{ required: true }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                  {" "}
                                  <RadioGroup
                                    aria-label="appointment"
                                    row="true"
                                    defaultValue={userData ? userData?.Gender : "Male"}
                                  >
                                    <FormControlLabel
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      value="Male"
                                      control={<Radio />}
                                      label="Male"
                                    />
                                    <FormControlLabel
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      value="Female"
                                      control={<Radio />}
                                      label="Female"
                                    />
                                    <FormControlLabel
                                      onChange={onChange}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      value="Unknown"
                                      control={<Radio />}
                                      label="Unknown"
                                    />
                                  </RadioGroup>
                                </>
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="DOB"
                              defaultValue=""
                              control={control}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                  <DatePicker
                                    label="Select DOB"
                                    value={value || null}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={onChange}
                                    // minDate={new Date("15-08-1947")}
                                    maxDate={new Date()}
                                    renderInput={(params) => (
                                      <TextField
                                        autoComplete="off"
                                        // helperText={error?.message}
                                        // error={error}
                                        // {...params}
                                        {...params}
                                        error={errors.DOB}
                                        helperText={errors.DOB ? "" : ""}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="PhoneNumber"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  type="number"
                                  label="Phone Number*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="Email"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Email*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <InputLabel>Are you Living in a Group Home? </InputLabel>
                            <Controller
                              name="IsLiving"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RadioGroup
                                  defaultValue={userData ? userData?.IsLiving : 0}
                                  aria-label="Living"
                                  name="IsLiving"
                                  onChange={onChange}
                                  row="true"
                                >
                                  <FormControlLabel
                                    value="1"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              )}
                              rules={{ required: "Email Id required" }}
                            />
                          </div>
                          {IsLiving == 1 ? (
                            <div className="reg_input sec_level">
                              <Controller
                                name="GroupHome"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Group Home*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <InputLabel className="reg_lable">Address</InputLabel>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="AddrLine1"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Street Address*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="AddrLine2"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Street Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="Suburb"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Suburb*"
                                  variant="outlined"
                                  // autoComplete="nope"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input auto-complete">
                            <Controller
                              control={control}
                              name="State"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="State"
                                  name="State"
                                  value={value}
                                  options={stateList ? stateList : ""}
                                  onChange={(e, v) => onChange(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      autoComplete="off"
                                      {...params}
                                      label="State*"
                                      error={errors.State}
                                      helperText={errors.State ? "State Required." : ""}
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="2">
                          <div className="reg_input">
                            <Controller
                              name="PostalCode"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Postal Code*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 4,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input check_value">
                            <InputLabel>Are you registered with NDIS? </InputLabel>
                            <Controller
                              name="IsNdisRegistered"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RadioGroup
                                  aria-label="NDIS"
                                  defaultValue={userData ? userData?.IsNdisRegistered : "0"}
                                  onChange={onChange}
                                  row="true"
                                >
                                  <FormControlLabel
                                    value="1"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              )}
                            />
                          </div>

                          {IsNdisRegistered == 1 ? (
                            <div className="reg_input sec_level">
                              <Controller
                                name="NDISNumber"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Enter NDIS Number*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                    inputProps={{
                                      maxLength: 9,
                                    }}
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input check_value">
                            <Controller
                              name="IsNdisDocument"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RadioGroup
                                  defaultValue={userData ? userData?.IsNdisDocument : "0"}
                                  aria-label="NDIS Document"
                                  onChange={onChange}
                                  row="true"
                                >
                                  <InputLabel>
                                    Do you have your NDIS Plan document available?
                                  </InputLabel>
                                  <FormControlLabel
                                    value="1"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              )}
                            />
                          </div>
                          {IsNdisDocument == 1 ? (
                            <div className="reg_input sec_level">
                              <label classname="test-label">Select Document*</label>
                              <Controller
                                name="Document"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    type="file"
                                    onChange={onChange}
                                    // label="Select Document*"

                                    error={error}
                                    helperText={error?.message}
                                  />

                                  // <input
                                  //   id="fileUpload"
                                  //   type="file"
                                  //   multiple
                                  //   accept="application/pdf, image/png"
                                  //   onChange={handleFileEvent}
                                  //   disabled={fileLimit}
                                  // />
                                )}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <InputLabel className="reg_lable mb-1">
                            Your Support Coordinator details.
                          </InputLabel>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="CoordinatorName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="CoordinatorPhone"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  type="number"
                                  label="Phone"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="CoordinatorEmail"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Email"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <div className="reg_box_bordered">
                            <Grid item md="12">
                              <InputLabel className="reg_lable mb-1">
                                Your primary carer/parent/relative’s details.
                              </InputLabel>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="RelativeName"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Name"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>

                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="RelativePhone"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        type="number"
                                        label="Phone"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                        inputProps={{
                                          maxLength: 10,
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>

                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="RelativeEmail"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Email"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                            </Grid>

                            <Grid item md="12">
                              <InputLabel className="reg_lable mb-1">Address.</InputLabel>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="RelativeAddrLine2"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Street Address"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>

                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="RelativeAddrLine1"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Relative Street Name"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>

                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="RelativeSuburb"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Suburb"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md="4">
                                <div className="reg_input auto-complete">
                                  <Controller
                                    control={control}
                                    name="RelativeState"
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                      <Autocomplete
                                        disablePortal
                                        id="RelativeState"
                                        name="RelativeState"
                                        value={value}
                                        options={stateList ? stateList : ""}
                                        onChange={(e, v) => onChange(v)}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="State"
                                            error={errors.RelativeState}
                                            helperText={
                                              errors.RelativeState ? "State Required." : ""
                                            }
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="2">
                                <div className="reg_input">
                                  <Controller
                                    name="RelativePostalCode"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Postal Code"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                        inputProps={{
                                          maxLength: 4,
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="12">
                          <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                            What impairments or disabilities does the person has?{" "}
                          </InputLabel>
                        </Grid>
                        <Grid item md="8">
                          <div className="reg_input">
                            <Controller
                              name="Disabilities"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Disability Condition"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <div className="reg_input check_value">
                            <InputLabel>
                              Do you know which services you need or shall we arrange an Appointment
                              with one of our Client Manager?
                            </InputLabel>
                            <Controller
                              name="Appointment"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RadioGroup
                                  aria-label="appointment"
                                  defaultValue={userData ? userData?.Appointment : "0"}
                                  row="true"
                                >
                                  <FormControlLabel
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    value="1"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    value="0"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              )}
                              rules={{ required: "Select any one" }}
                            />
                          </div>
                          <Grid item sm={6} md={6}>
                            {Appointment == 1 ? (
                              <div className="reg_input sec_level">
                                {/* <Controller
                                name="Services"
                                control={control}
                                defaultValue={[]}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                  <Autocomplete
                                    {...field}
                                    disableClearable
                                    disablePortal
                                    filterSelectedOptions
                                    multiple
                                    // value={serviceAutocompleteValues}
                                    value={undefined}
                                    options={serviceList ? serviceList : ""}
                                    id="Services"
                                    onChange={(event, value) => {
                                      field.onChange(value);
                                      // onServiceChange(value);
                                    }}
                                    // options={daysOfWeekSuggestions}
                                    renderInput={(params) => (
                                      <TextField
                                        // required
                                        error={!!error}
                                        helperText={error?.message}
                                        id="Services"
                                        label="Services"
                                        name="Services"
                                        type="search"
                                        inputRef={ref}
                                        {...params}
                                      />
                                    )}
                                  />
                                )}
                              /> */}

                                <Autocomplete
                                  multiple
                                  id="Services"
                                  name="Services"
                                  options={serviceList ? serviceList : []}
                                  value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                                  defaultValue={
                                    serviceAutocompleteValues ? serviceAutocompleteValues : []
                                  }
                                  onChange={onServiceChange}
                                  filterSelectedOptions
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="What services are you registered for?*"
                                      placeholder="Favorites"
                                      // error={errorservices}
                                      // helperText={errorservices}
                                      id="Services"
                                      name="Services"
                                      // defaultValue={Params}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  )}
                                />
                                <span class="errorTC" id="ServicesError">
                                  Please select any one service.
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          {!userData ? (
                            <Grid container spacing={3}>
                              <Grid item>
                                <div className="reg_input check_value">
                                  <Checkbox
                                    checked={termsCondition}
                                    onChange={() => setTermsCondition((prev) => !prev)}
                                    color="primary"
                                    label="By registering you agree to Value Care’s Terms &amp; Conditions."
                                  />
                                  <small>
                                    By registering you agree to Value Care’s{" "}
                                    <a className="color-red" onClick={handleOpen}>
                                      Terms &amp; Conditions.
                                    </a>
                                  </small>
                                  <span class="servicesError" id="TcError">
                                    {!termsCondition &&
                                      "Please Select Terms & Conditions to continue."}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography id="modal-modal-title" variant="h6" component="h2">
                            Please Read Carefully Terms &amp; Conditions.
                          </Typography>
                          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Health insurance is a way to ensure you have support for costly medical
                            treatment. Value Care has been designed to tailor a health plan to suit
                            your needs and priorities within your budget. Benefits include
                            hospitalisation and specialist care locally and with world class medical
                            facilities in India. Value Care is available to individuals and families
                            as well as group schemes who are looking for medical insurance at
                            reasonable rates. Benefits provided through this plan are regularly
                            reviewed to meet our customers’ needs.
                          </Typography>
                          <Button
                            style={{ color: "white" }}
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                          >
                            OK
                          </Button>
                        </Box>
                      </Modal>
                    </div>
                  </div>

                  {userData ? (
                    <div className="reg_input">
                      <MDButton
                        style={{ color: "white" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate("/dashboards/userprofile/Index")}
                      >
                        Cancel
                      </MDButton>
                      <MDButton type="submit" variant="contained" color="primary">
                        Update
                      </MDButton>
                    </div>
                  ) : (
                    <div className="reg_submit">
                      <MDButton
                        style={{ color: "white" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate("/")}
                      >
                        Cancel
                      </MDButton>
                      <MDButton type="submit" variant="contained" color="primary">
                        Register
                      </MDButton>
                    </div>
                    // <div>
                    //   <MDButton type="submit" variant="contained" color="primary">
                    //     Register
                    //   </MDButton>
                    // </div>
                  )}
                </form>
              </div>
            </div>
          </CoverLayout>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </div>
      )}
    </>
  );
};

SignUpDifferentlyAbled.defaultProps = {};

export default SignUpDifferentlyAbled;
