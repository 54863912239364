import {
  FormControlLabel,
  TextField,
  InputLabel,
  Radio,
  RadioGroup,
  Grid,
  Button,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import axios from "axios";
import { CommonConfig } from "constant";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import { useStateWithCallbackLazy } from "use-state-with-callback";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CardContent from "@mui/material/CardContent";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import { ArrowDownward } from "@mui/icons-material";
import cogoToast from "cogo-toast";
import "assets/css/style.css";
import FormFirst from "./FormFirst";
import SelectAppointmentServices from "./SelectAppointmentServices";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
//  For SideNav import
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import IconButton from "@mui/material/IconButton";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
import Icon from "@mui/material/Icon";
import { NotesComponent } from "layouts/component/Notes";

function UserBookAppointmentToken({ light }) {
  const navigate = useNavigate();
  let userLogin = "";
  if (localStorage.getItem("userData")) {
    // alert("Hello");
    userLogin = JSON.parse(localStorage.getItem("userData"));
    console.log("test....", userLogin.PersonType);
  } else {
    // alert("Hello2")
    navigate("/");
  }

  const { state } = useLocation();
  // console.log("State", state);
  let PatientData = state ? state.PatientData : null;
  console.log("PatentData = ", PatientData);

  localStorage.setItem("AppointmentID", PatientData?.AppointmentID);
  let PersonUserId = state ? state.PersonId : null;
  let PersonUserName = state ? state.Name : null;
  console.log("PersonName = ", PersonUserName);

  if (PatientData?.AppointmentID) {
    PersonUserId = PatientData.PersonID;
  }

  const getDefaultAddress = (values) => {
    var setoffice = values;

    var dataSET = {
      officeSite: setoffice,
    };
    axios({
      url: CommonConfig.ApiUrl + "Utils/getOfficeAddress",
      method: "POST",
      headers: {},
      data: dataSET,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          console.log("time data", res.data.data);
          let officeArredss = res.data.data[0].Address;
          document.getElementById("setAddressData").style.display = "block";
          document.getElementById("setAddressData").innerHTML = officeArredss;

          // setAppoinmentStatusList(
          //   appoinmentStatus.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (PatientData?.ValueCareSite != "") {
    getDefaultAddress(PatientData?.ValueCareSite);
  }

  console.log("PersonUserId", PersonUserId);
  if (PersonUserId == null) {
    if (userLogin.PersonType != "INTERNAL STAFF") {
      PersonUserId = userLogin.PersonId;
    }
  }
  // console.log("PersonUserName", PersonUserName);

  const [isEditEnabled, setIsEditEnabled] = useState(state ? true : false);
  const [values, setValues] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeLabel, setSelectedTimeLabel] = useState(null);
  const [form1, setForm1] = useState(1);
  const [serviceList, setServiceList] = useState(null);
  const [TimeList, setTimeList] = useState(null);
  const [TimeSlot, setTimeSlot] = useState(null);
  const [selectedCallBackTimeSlot, setSelectedCallBackTimeSlot] = useState(null);
  const [Servicesvalues, setValuesServices] = useState([]);
  const [AppointmentType, setAppointmentType] = useState(null);
  const [userSelectedServices, setUserSelectedServices] = useState(null);
  const [isTimeValid, setisTimeValid] = useState(false);
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);
  const [selectedMediumType, setSelectedMediumType] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [officeSiteData, setOfficeSiteData] = useState(null);
  const [notesDropdownList, setNotesDropdownList] = useState(null);
  const [selectedNotesType, setSselectedNotesType] = useState(null);
  const [selectedOfficeSite, setSelectedOfficeSite] = useState(null);
  const [AddrLine1, setAddrLine1] = useState(null);
  const [AddrLine2, setAddrLine2] = useState(null);
  const [Suburb, setSuburb] = useState(null);
  const [PostalCode, setPostalCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState(null);
  const [reason, setReason] = useState(null);
  const [managedData, setManagedData] = useState(null);
  const [managedBy, setManagedBy] = useState(null);
  const [managedByLabel, setManagedByLabel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [appoinmentStatusList, setAppoinmentStatusList] = useState(null);
  const [chStatus, setChStatus] = useState(null);
  // const [chStatusLabel, setChStatusLabel] = useState(null);
  const [chManagedBy, setChManagedBy] = useState(null);
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const [relatedPersonList, setRelatedPersonList] = useState(null);
  const [appointmentent, setAppointmentent] = useState(null);
  const [appointmententLabel, setAppointmententLabel] = useState(null);
  const [chNotes, setChNotes] = useState(null);
  const [status, setStatus] = useState(null);
  // const [chNotes, setChNotes] = useState(null);

  const [name, setName] = useState("");
  const [cityOrState, setCityOrState] = useState("");
  const [userType, setUserType] = useState("");
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");
  const [data, setData] = useState([]);
  const [notesData, setNotesData] = useState([]);

  const PersonType = JSON.parse(localStorage.getItem("userData")).PersonType;
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [isSelfAppoinmentChecked, setIsSelfAppoinmentChecked] = useState("true");
  const [appoinmentForId, setAppoinmentForId] = useStateWithCallbackLazy(null);
  const [appoinmentLabel, setAppoinmentLabel] = useState(null);
  const [appointmentFor, setAppointmentFor] = useState(null);
  const [defaultStatus, setDefaultStatus] = useState("Pending");
  const [websiteDropdownList, setWebsiteDropdownList] = useState(null);
  const [managedByID, setmanagedByID] = useState(null);
  const [personData, setPersonData] = useState(null);
  const [appoinmentfullName, setAppoinmentfullName] = useState(null);
  console.log("appoinmentfullName", appoinmentfullName);

  console.log("notesData", notesData);
  //  For SideNav import

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  // Styles for the navbar icons

  console.log("PersonUserId", PersonUserId);

  debugger;
  if (userLogin.PersonType != "INTERNAL STAFF") {
    if (userLogin.PersonType != "Admin") {
      if (PersonUserName == null && !localStorage.getItem("PersonUserName")) {
        console.log("Demo1234");
        PersonUserName = userLogin.FirstName + " " + userLogin.LastName;
      } else {
        if (isSelfAppoinmentChecked === true) {
          // alert(1)
          console.log("In true");
          PersonUserName = userLogin.FirstName + " " + userLogin.LastName;
        } else if (isSelfAppoinmentChecked === false) {
          // alert(2)
          console.log("In false");
          PersonUserName = PersonUserName;
        } else {
          PersonUserName = userLogin.FirstName + " " + userLogin.LastName;
        }
      }
    }
  }

  const setUserEmpty = (e) => {
    debugger;

    if (e == "false") {
      PersonUserName = null;
    }
  };

  const GetAddress = (e, value) => {
    var setoffice = e.target.value;

    var dataSET = {
      officeSite: setoffice,
    };
    axios({
      url: CommonConfig.ApiUrl + "Utils/getOfficeAddress",
      method: "POST",
      headers: {},
      data: dataSET,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          console.log("time data", res.data.data);
          let officeArredss = res.data.data[0].Address;
          document.getElementById("setAddressData").style.display = "block";
          document.getElementById("setAddressData").innerHTML = officeArredss;

          // setAppoinmentStatusList(
          //   appoinmentStatus.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // console.log("appoinmentForId",appointmentFor.personFullName);

  console.log("isSelfAppoinmentChecked", isSelfAppoinmentChecked);

  const getUserName = () => {
    console.log("appoinmentLabel", appoinmentLabel);
    // debugger;
    // setDefaultStatus("Pending")
    let appointment = "";
    if (PersonUserName) {
      setAppointmentFor(PersonUserName);
    } else if (isSelfAppoinmentChecked === "true") {
      let userData = localStorage.getItem("userData");
      if (userData) {
        userData = JSON.parse(userData);
      }
      appointment = userData.FirstName + " " + userData.LastName;
      setAppointmentFor(appointment);
    } else if (isSelfAppoinmentChecked != "true") {
      setAppointmentFor(appoinmentLabel);
    } else {
      setAppointmentFor("");
    }
  };
  const getPersonById = () => {
    console.log("PersonUserId == ", PersonUserId);
    if (PatientData?.AppointmentID) {
      PersonUserId = PatientData.PersonID;
      console.log("PersonUserId == ", PersonUserId);
    }
    console.log("PersonUserId == ", PersonUserId);
    let Requestdata = {
      PersonId: PersonUserId ? PersonUserId : CommonConfig.getCurrentUserId(),
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    console.log("Requestdata", Requestdata);
    axios({
      url: CommonConfig.ApiUrl + "contact/getPersonById",
      method: "POST",
      headers: {},
      data: Requestdata,
    })
      .then((res) => {
        if (res.data.success) {
          setPersonData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              personFullName:
                item.FirstName +
                " " +
                item.LastName +
                ", " +
                item.Gender +
                " " +
                moment().diff(moment(CommonConfig.formatDate(item.DOB), "YYYY-MM-DD"), "years") +
                " years",
            }))
          );
        }
      })
      .catch((error) => {});
  };
  const getData = () => {
    const formattedData = {
      Name: name,
      CityOrState: cityOrState,
      UserType: userType,
      PhoneOrEmail: phoneOrEmail,
      PersonIdNumber: personIdNumber,
    };

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "authentication/getUserList",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log(res);
          setData(res.data.data[0]);
          console.log("Data1...", data);
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveNotes = () => {
    // if (selectedNotesType === null) {
    //   document.getElementById("ServicesError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("ServicesError").style.display = "none";
    // }

    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }

    const formattedData = {
      EntityType: selectedNotesType,
      EntityId: PatientData?.AppointmentID ? PatientData?.AppointmentID : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: selectedNotesType,
      NoteTitle: "",
      NoteText: chNotes,
    };

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList();
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getNoteList = () => {
    let noteReqData = {
      EntityType: "Appointment",
      EntityId: PatientData?.AppointmentID,
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: noteReqData,
    })
      .then((res) => {
        console.log("Hello147 ", res.data);
        if (res.data?.success) {
          console.log("-------------", data);
          setNotesData(res.data.data || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNotesDropdownList = () => {
    const formattedData = {
      StringMapType: "NOTETYPE.Appointment",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setSselectedNotesType(res.data.data[0].StringMapName);
          // setNotesDropdownList(
          //   res.data.data.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getWebsiteDropdown = () => {
    const formattedData = {
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "contact/getSiteDropdown",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setWebsiteDropdownList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      getPersonById();
    }, 2000);

    getData();
    getNotesDropdownList();
    getRelatedPersonList();
    getNoteList();

    getWebsiteDropdown();
  }, []);

  console.log("Pateient load", PatientData);
  //  schema = []

  const schema = yup.object().shape({
    AppointmentDate: yup.string().required("Appointment Date required"),
    Appointmentfor: yup.string(),
    // IsLocationSelector: yup.string(),

    // OfficeSite: yup.object().when("IsLocationSelector", {
    //   is: "value care office site",
    //   then: yup
    //     .object()
    //     .required("Office Site required")
    // }),

    // PostalCode: yup.string().when("IsLocationSelector", {
    //   is: "Australin Address",
    //   then: yup
    //     .string()
    //     .required("Postal Code required")
    //     .matches("^[0-9]+$", "Postal Code should be number")
    //     .min(4, "Postal Code must be at least 4 digit")
    //     .max(4, "Invalid Postal Code"),
    // }),

    // Suburb: yup.string().when("IsLocationSelector", {
    //   is: "Australin Address",
    //   then: yup.string().required("Suburb Required"),
    // }),

    // AddrLine1: yup.string().when("IsLocationSelector", {
    //   is: "Australin Address",
    //   then: yup.string().required("Street Address Required"),
    // }),
    // state: yup.object().when("IsLocationSelector", {
    //   is: "Australin Address",
    //   then: yup.object().required("state Required"),
    // })
  });

  const backForm = (value) => {
    if (value) {
      setForm1(value);
    } else {
      setForm1(form1 - 1);
    }
  };
  console.log("form1...", form1);
  console.log("persontype...", PersonUserId);
  const selectAppointment = (e) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    var sendto = 0;
    if (userData.PersonType == "DIFFERENTLY ABLED") {
      sendto = 1;
    }

    // setTimeout(() => {

    if (sendto == 1) {
      if (e == CommonConfig.BookingType_FaceToFace) {
        setForm1(2);
        setAppointmentType(e);
      } else if (e == CommonConfig.BookingType_RequestCallBack) {
        setForm1(2);
        setAppointmentType(e);
      } else if (e == CommonConfig.BookingType_AudioVideoConference) {
        setForm1(2);
        setAppointmentType(e);
      } else {
        setForm1(1);
      }
    } else {
      debugger;
      console.log(
        " PersonUserName : appoinmentfullName",
        PersonUserName,
        " || ",
        appoinmentfullName
      );
      if (PersonUserName != null) {
        // document.getElementById("SelectPatient").style.display = "none";
        if (e == CommonConfig.BookingType_FaceToFace) {
          setForm1(2);
          setAppointmentType(e);
        } else if (e == CommonConfig.BookingType_RequestCallBack) {
          setForm1(2);
          setAppointmentType(e);
        } else if (e == CommonConfig.BookingType_AudioVideoConference) {
          setForm1(2);
          setAppointmentType(e);
        } else {
          setForm1(1);
        }
        localStorage.setItem("PersonUserName", PersonUserName);
        localStorage.setItem("PersonUserIdApp", PersonUserId);
      } else {
        document.getElementById("SelectPatient").style.display = "block";
      }
    }

    // }, 2000);
    //
  };

  const getState = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let statelistdata = res.data.data;
          setStateData(
            statelistdata.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOfficeSiteData = () => {
    const formattedData = {
      StringMapType: "VALUE CARE SITE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          console.log("time data", res.data.data);
          let officeSiteDataa = res.data.data;
          setOfficeSiteData(
            officeSiteDataa.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onOfficeChange = (e, value) => {
    setSelectedOfficeSite(value);
  };

  const onManagedByChanged = (e, value) => {
    setManagedBy(value);
  };

  const onManagedByChangeStatusChanged = (e, value) => {
    console.log("VVV", value);
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
  };

  const onManagedByAppoinmentFor = (e, value) => {
    setAppoinmentLabel(value.label);
    setAppoinmentForId(value.id, () => {
      setAppoinmentfullName(relatedPersonList.find((item) => item.id === value.id)?.personFullName);
    });
  };
  const onStatusChanged = (e, value) => {
    setChStatus(value.id);
  };

  const statedata = {
    id: PatientData?.State,
    label: PatientData?.State,
  };
  const ValueCareSite = {
    id: PatientData?.ValueCareSite,
    label: PatientData?.ValueCareSite,
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      AppointmentDate: isEditEnabled
        ? PatientData?.AppointmentDate
          ? moment(PatientData?.AppointmentDate).format("YYYY-MM-DD")
          : ""
        : "",
      AppointmentTime: isEditEnabled ? PatientData?.AppointmentTime : "",
      AppoinmentFor: appointmentFor ? appointmentFor : "",
      IsLocationSelector: isEditEnabled
        ? PatientData?.IsValueCareSite
          ? "value care office site"
          : "Australin Address"
        : "value care office site",
      ManagedBy: isEditEnabled ? PatientData?.ManagedBy : "",
      managedBy: isEditEnabled ? PatientData?.ManagedBy : "",
      managedByLabel: isEditEnabled ? PatientData?.ManagedByName : "",
      Appointmentfor: isEditEnabled ? PatientData?.FirstName + " " + PatientData?.LastName : "",
      state: PatientData?.State ? statedata : "",
      AddrLine1: PatientData?.AddrLine1 ? PatientData?.AddrLine1 : "",
      PostalCode: PatientData?.PostalCode ? PatientData?.PostalCode : "",
      Suburb: PatientData?.City ? PatientData?.City : "",
      OfficeSite: PatientData?.ValueCareSite ? ValueCareSite : "",
    },
  });

  // console.log("ERRR", errors);

  const IsLocationSelector = watch("IsLocationSelector");
  // console.log("Is", IsLocationSelector);

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          // console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTimeSlot = () => {
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getTimeSlotValue",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data?.success) {
          // console.log("TimeSlotListRES===>", res);
          // console.log("TimeSlotList===>", res.data.data);
          setTimeList(
            res.data.data.map((item) => ({
              id: item.TimeValue,
              label: item.TimeLabel,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCallBackTimeSlot = () => {
    const formattedData = {
      StringMapType: "DAYTIMING",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          debugger;
          // console.log("AppoinmentTimeSlot===>", res.data.data);
          var sort = [];
          res.data.data.map((item2) => {
            if (item2.StringMapKey === "MORNING") {
              sort.push(item2);
            }
          });

          res.data.data.map((item3) => {
            if (item3.StringMapKey === "AFTERNOON") {
              sort.push(item3);
            }
          });
          res.data.data.map((item4) => {
            if (item4.StringMapKey === "EVENING") {
              sort.push(item4);
            }
          });
          res.data.data.map((item5) => {
            if (item5.StringMapKey === "LATE HOURS") {
              sort.push(item5);
            }
          });
          console.log("sort...", sort);
          setTimeSlot(
            sort.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formChange = async (formId) => {
    setForm1(formId);
    // getUserName();
  };

  useEffect(() => {
    // getServiceData();
    getState();
    getOfficeSiteData();
    getTimeSlot();
    getCallBackTimeSlot();
    getInternalStaff();
    checkEditConditional();
    getServiceData();
    getAppoinmentStatus();

    console.log("setPersonType,....", PersonType);

    //setManagedBy (state?.PatientData?.ManagedBy);
  }, []);

  const setAutocompleteValues = (value) => {
    let dummyArray = [];
    if (value) {
      setUserSelectedServices(value);
      for (let i = 0; i < value.length; i++) {
        if (value[i]) {
          dummyArray.push({
            id: value[i],
            label: value[i],
          });
        }
      }
      // console.log("DUMMy", dummyArray);
      // setValues(dummyArray);
      setServiceAutocompleteValues(dummyArray);
    }
  };

  const onServiceChange = (event, value) => {
    console.log("ValueService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }

    setUserSelectedServices(formattedData);
    setServiceAutocompleteValues(value);
  };

  const checkEditConditional = () => {
    if (isEditEnabled) {
      if (PatientData) {
        console.log("Edit Mode He");
        if (PatientData?.Services) {
          setAutocompleteValues(PatientData?.Services.split(","));
        }
        setDefaultValues();
        console.log("PateintData", PatientData);
        if (PatientData?.AppointmentMode == CommonConfig.BookingType_FaceToFace) {
          setForm1(3);
          setAppointmentType(PatientData?.AppointmentMode);
        } else if (PatientData?.AppointmentMode == CommonConfig.BookingType_RequestCallBack) {
          setForm1(4);
          setAppointmentType(PatientData?.AppointmentMode);
        } else if (PatientData?.AppointmentMode == CommonConfig.BookingType_AudioVideoConference) {
          setForm1(5);
          setAppointmentType(PatientData?.AppointmentMode);
        } else {
          navigate(-1);
        }
      }
    }
  };
  const getRelatedPersonList = () => {
    const formattedData = {
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    debugger;
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getPatientList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: formattedData,
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          // cogoToast.success("Appointment Booked Successfully");
          console.log("Managed Data", res.data.data);

          setRelatedPersonList(
            res.data.data.map((item) => ({
              id: item.PersonId,
              personFullName:
                item.Name +
                ", " +
                item.Gender +
                " " +
                moment().diff(moment(CommonConfig.formatDate(item.DOB), "YYYY-MM-DD"), "years") +
                " years",
              label: item.Name,
            }))
          );
          // navigate("/pages/book-appointment/list");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };
  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          // cogoToast.success("Appointment Booked Successfully");
          console.log("Managed Data", res.data.data);

          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
          // navigate("/pages/book-appointment/list");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const handleTimeChange = (value) => {
    if (value) {
      setSelectedTime(value);
      console.log("time", selectedTime);
      setisTimeValid(true);
    } else setisTimeValid(false);
  };

  const handleCallBackTimeChange = (value) => {
    if (value) {
      setSelectedCallBackTimeSlot(value);
      setisTimeValid(true);
    } else setisTimeValid(false);

    if (isEditEnabled) {
      setSelectedTimeLabel(PatientData?.TimeSlot);
    }
  };

  const setDefaultValues = () => {
    setAppointmentType(PatientData?.AppointmentMode);
    setSelectedMediumType(PatientData?.MediumType);
    setSelectedCallBackTimeSlot(PatientData?.TimeSlot);
    setSelectedOfficeSite(PatientData?.ValueCareSite);
    setPhoneNumber(PatientData?.PhoneForConfirmation);
    setAdditionalInformation(PatientData?.AdditionalDetail);

    // setAddrLine1(PatientData?.AddrLine1);
    // setAddrLine2(PatientData?.AddrLine2);
    // setSuburb(PatientData?.City);
    // setSelectedState(PatientData?.State);
    // setPostalCode(PatientData?.PostalCode);

    setManagedBy(PatientData?.ManagedBy);
    setReason(PatientData?.Reason);
    handleCallBackTimeChange(PatientData?.TimeSlot);
  };

  const getAppoinmentStatus = () => {
    const formattedData = { StringMapType: "APPOINTMENTSTATUS", SortType: "Alpha" };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Data", res);
          // console.log("time data", res.data.data);
          let appoinmentStatus = res.data.data;
          setAppoinmentStatusList(
            appoinmentStatus.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkValidation = () => {
    if (IsLocationSelector == "value care office site") {
      if (!selectedOfficeSite) {
        document.getElementById();
      }
    }
  };

  const onSubmit = (data) => {
    // debugger;
    let formattedDate = moment(data.AppointmentDate).format("YYYY-MM-DD");

    let isValueCareSite = "0";
    console.log("AppointmentType", AppointmentType);
    console.log("SIsLocationSelector", IsLocationSelector);
    // debugger;
    if (AppointmentType == "Face to Face" && IsLocationSelector == "value care office site") {
      // debugger;
      isValueCareSite = "1";
      console.log("VAlue", isValueCareSite);
    }

    console.log("VAlue2", isValueCareSite);

    let mediumType = "";
    if (AppointmentType == "Audio/Video Conference") {
      mediumType = selectedMediumType;
    }
    let officeSite = selectedOfficeSite ? selectedOfficeSite.id : "";
    if (isEditEnabled && isValueCareSite == "1") {
      officeSite = selectedOfficeSite.id ? selectedOfficeSite.id : selectedOfficeSite;
    }

    let managedByID = managedBy ? managedBy.id : "";
    if (isEditEnabled) {
      managedByID = managedBy?.id ? managedBy?.id : managedBy;
    }

    let AppID = "";
    if (isEditEnabled) {
      AppID = PatientData?.AppointmentID;
    }
    let formattedDatae = {
      ...data,
      AppointmentID: PatientData?.AppointmentID ? PatientData?.AppointmentID : "",
      PersonID: PersonUserId
        ? PersonUserId
        : appoinmentForId
        ? appoinmentForId
        : isSelfAppoinmentChecked
        ? CommonConfig.getCurrentUserId()
        : "",
      AppointmentMode: AppointmentType,
      AppointmentDate: formattedDate,
      MediumType: mediumType,
      TimeSlot: selectedCallBackTimeSlot,
      IsValueCareSite: isValueCareSite,
      ValueCareSite: data.OfficeSite ? data.OfficeSite.id : "",
      PhoneForConfirmation: phoneNumber,
      AdditionalDetail: additionalInformation,
      AddressType: "",
      AddrLine1: data.AddrLine1,
      AddrLine2: data.AddrLine2,
      Suburb: data.Suburb,
      State: data.state ? data.state.id : "",
      PostalCode: data.PostalCode,
      ManagedBy: chManagedBy ? chManagedBy : PatientData?.ManagedBy,
      Reason: reason ? reason : "",
      Status: chStatus ? chStatus : PatientData?.Status,
      AssignToStaffID: chManagedBy,
      Note: chNotes,
    };
    console.log("Final formated data", formattedDatae);
    let formattedDataT = {
      formattedData: formattedDatae,
      Services: userSelectedServices,
      CurrentUserId: CommonConfig.getCurrentUserId(),
    };
    console.log(" addUpdateAppointment API req data", formattedDataT);
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/addUpdateAppointment",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },

      // Attaching the form data
      data: formattedDataT,
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          cogoToast.success("Appointment Updated Successfully.");
          console.log("RESSS", res);
          navigate("/pages/book-appointment/list");
        }
        console.log("......ggggggggg", res.data.success);
        console.log("test", res.data);
        // cogoToast.error('Something went wrong');
      })

      // //   // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              assignment_turned_in
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {PatientData?.AppointmentNumber ? "Edit Appointment" : "Add Appointment"}
          </MDTypography>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, mr: 2, ml: "auto" }}>
            {form1 === 1 ? "" : AppointmentType}
            {isEditEnabled
              ? PatientData?.AppointmentNumber
                ? ` Appointment ID:${PatientData?.AppointmentNumber}`
                : ""
              : ""}
          </MDTypography>
          {CommonConfig.isInternalStaff() === 1 ? (
            <IconButton
              size="large"
              disableRipple
              color="inherit"
              className="bar-icn"
              sx={navbarIconButton}
              onClick={handleConfiguratorOpen}
            >
              <Icon>menu</Icon>
            </IconButton>
          ) : null}
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <Grid container justify="center" spacing={3}>
            <Grid item md="12">
              {form1 === 1 && (
                <>
                  {PersonUserId ? (
                    <>
                      {userLogin.PersonType === "Admin" ||
                      userLogin.PersonType === "SUPPORT COORDINATOR" ||
                      userLogin.PersonType === "CARER" ? (
                        <div className="app-btn reg_submit">
                          <MDButton
                            onClick={() => navigate("/dashboards/userprofile/Index")}
                            variant="outlined"
                            color={"primary"}
                          >
                            Select Participant
                          </MDButton>
                          <MDButton
                            onClick={() => navigate("/authentication/sign-up/AddPatient")}
                            variant="outlined"
                            color={"secondary"}
                          >
                            Add Participant
                          </MDButton>
                          <span id="SelectPatient" className="errorTC" style={{ display: "none" }}>
                            {" "}
                            Please Select Participant.{" "}
                          </span>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {CommonConfig.isInternalStaff() === 1 ? (
                        <div className="app-btn reg_submit">
                          <MDButton
                            onClick={() => navigate("/dashboards/userprofile/Index")}
                            variant="outlined"
                            color={"primary"}
                          >
                            Select Participant
                          </MDButton>
                          <MDButton
                            onClick={() => navigate("/authentication/sign-up/AddPatient")}
                            variant="outlined"
                            color={"secondary"}
                          >
                            Add Participant
                          </MDButton>
                          <span id="SelectPatient" className="errorTC" style={{ display: "none" }}>
                            {" "}
                            Please Select Participant.{" "}
                          </span>
                        </div>
                      ) : null}

                      {CommonConfig.isInternalStaff() === 2 ? (
                        <div className="special-appointment">
                          <RadioGroup
                            defaultValue="true"
                            aria-label="AppointmentFor"
                            name="isSelfAppointment"
                            // //target.value) }}
                            onChange={(e) => setIsSelfAppoinmentChecked(e.target.value)}
                            onBlur={(e) => setUserEmpty(e.target.value)}
                            row="true"
                          >
                            <InputLabel>Appointment For</InputLabel>
                            <FormControlLabel
                              value="true"
                              // onChange={onChange}
                              // checked
                              control={<Radio />}
                              label="Self"
                            />
                            <FormControlLabel
                              value="false"
                              // onChange={onChange1}
                              control={<Radio />}
                              label="Other"
                            />
                          </RadioGroup>
                          <MDButton
                            style={{ marginLeft: "10px" }}
                            onClick={() => navigate("/authentication/sign-up/AddPatient")}
                            variant="outlined"
                            color={"secondary"}
                          >
                            Add Participant
                          </MDButton>

                          {isSelfAppoinmentChecked == "true" ? (
                            <></>
                          ) : (
                            <div>
                              <MDButton
                                onClick={() => navigate("/dashboards/userprofile/Index")}
                                variant="outlined"
                                color={"primary"}
                                sx={{ ml: 1 }}
                              >
                                Select Participant
                              </MDButton>
                              <span
                                id="SelectPatient"
                                className="errorTC"
                                style={{ display: "none" }}
                              >
                                {" "}
                                Please Select Participant.{" "}
                              </span>
                            </div>
                            // <span> </span>

                            // <Autocomplete
                            //   disablePortal
                            //   id="relatedPersonList"
                            //   name="relatedPersonList"
                            //   style={{
                            //     marginBottom: "10px",
                            //   }}
                            //   value={appoinmentLabel ? appoinmentLabel : ""}
                            //   options={relatedPersonList ? relatedPersonList : []}
                            //   onChange={onManagedByAppoinmentFor}
                            //   sx={{ width: 300 }}
                            //   renderInput={(params) => (
                            //     <TextField
                            //       // {...register("ManagedBy")}
                            //       {...params}
                            //       label="Participant List"
                            //     />
                            //   )}
                            // />
                          )}
                        </div>
                      ) : null}
                    </>
                  )}

                  <div className="appointment-for special">
                    <p>
                      Appointment For{" "}
                      <span>{PersonUserName ? PersonUserName : appoinmentfullName}</span>
                    </p>
                  </div>

                  <FormFirst onFormSelected={(e) => selectAppointment(e)} />
                </>
              )}

              {form1 === 2 && (
                <SelectAppointmentServices
                  goBack={() => backForm()}
                  Submit={(v) => formChange(v)}
                  selectedServices={(e) => setUserSelectedServices(e)}
                  selectedAppointment={AppointmentType}
                />
              )}

              {form1 > 2 && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="reg_step" id="bookStep4">
                    {AppointmentType === CommonConfig.BookingType_AudioVideoConference ? (
                      <Grid item md="12">
                        <div className="reg_input check_value mb-0">
                          <InputLabel className="reg_lable mt-15">Mode of Communication</InputLabel>
                          <RadioGroup
                            aria-label="gender"
                            name="CallType"
                            row="true"
                            onChange={(e) => setSelectedMediumType(e.target.value)}
                          >
                            <FormControlLabel
                              value="Video Call"
                              // onChange={(e) => handleChange(e)}
                              control={
                                <Radio
                                  checked={selectedMediumType === "Video Call" ? true : false}
                                />
                              }
                              label="Video Call"
                              name="CallType"
                            />
                            <FormControlLabel
                              value="Audio Call"
                              // onChange={(e) => handleChange(e)}
                              control={
                                <Radio
                                  checked={selectedMediumType === "Audio Call" ? true : false}
                                />
                              }
                              label="Audio Call"
                              name="CallType"
                            />
                          </RadioGroup>
                        </div>
                      </Grid>
                    ) : null}

                    <Grid container spacing={3}>
                      {CommonConfig.isInternalStaff() === 1 && userLogin.PersonType == "Admin" ? (
                        <Grid item md="3">
                          <div className="reg_input auto-complete">
                            <Autocomplete
                              disablePortal
                              id="chManagedBy"
                              name="chManagedBy"
                              style={{
                                marginBottom: "10px",
                              }}
                              value={
                                isEditEnabled
                                  ? !chManagedByLabel
                                    ? PatientData?.ManagedByName
                                    : chManagedByLabel
                                  : chManagedByLabel
                              }
                              options={managedData ? managedData : []}
                              onChange={onManagedByChangeStatusChanged}
                              sx={{ width: 200 }}
                              renderInput={(params) => (
                                <TextField
                                  // {...register("ManagedBy")}
                                  {...params}
                                  label="Managed By"
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      ) : null}
                      {CommonConfig.isInternalStaff() === 1 ? (
                        <Grid item md="3">
                          <div className="reg_input auto-complete">
                            <Autocomplete
                              disablePortal
                              id="Status"
                              name="Status"
                              style={{
                                marginBottom: "10px",
                              }}
                              value={
                                isEditEnabled
                                  ? !chStatus
                                    ? PatientData?.Status
                                    : chStatus
                                  : defaultStatus
                              }
                              options={appoinmentStatusList ? appoinmentStatusList : []}
                              onChange={onStatusChanged}
                              sx={{ width: 200 }}
                              renderInput={(params) => (
                                <TextField
                                  // {...register("ManagedBy")}
                                  {...params}
                                  label="Status"
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      ) : (
                        <Grid item md="3">
                          <div className="reg_input">
                            <Controller
                              name="Status"
                              control={control}
                              defaultValue={
                                isEditEnabled
                                  ? PatientData?.FirstName + " " + PatientData?.LastName
                                  : appointmentFor
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  id="outlined-read-only-input"
                                  label="Status"
                                  style={{
                                    width: "200px",
                                  }}
                                  value={
                                    isEditEnabled
                                      ? !chStatus
                                        ? PatientData?.Status
                                        : chStatus
                                      : defaultStatus
                                  }
                                  onChange={onChange}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      )}
                      {chStatus === "Canceled" ? (
                        <Grid item md="3">
                          <div className="reg_input">
                            <TextField
                              onChange={(e) => setReason(e.target.value)}
                              value={reason ? reason : ""}
                              name="reason"
                              multiline
                              rows={2}
                              // maxRows={4}
                              label="Reason"
                              // id="regFirstName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              //   required={true}
                            />
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Appointmentfor"
                            control={control}
                            defaultValue={
                              isEditEnabled
                                ? PatientData?.FirstName + " " + PatientData?.LastName
                                : appointmentFor
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-read-only-input"
                                label="Appointment For"
                                // value={isSelfAppoinmentChecked == "true" ?(personData ? personData[0].personFullName : ''):(!appoinmentLabel ? (personData ? personData[0].personFullName : '') :
                                // (relatedPersonList ? relatedPersonList[0].personFullName : ''))}
                                // value={
                                //   PersonUserName
                                //     ? PersonUserName
                                //     : appoinmentfullName
                                //       ? appoinmentfullName
                                //       : personData
                                //         ? personData[0].personFullName
                                //         : ""
                                // }
                                value={
                                  isEditEnabled
                                    ? PatientData?.FirstName + " " + PatientData?.LastName
                                    : appointmentFor
                                }
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      {/* <Grid item md="4">
                        <div className="reg_input auto-complete"> */}
                      {/* <TextField
                              fullWidth
                              id="outlined-read-only-input"
                              label="Appointment For"
                              defaultValue="Selected Participant Id"
                              style={{
                                marginBottom: "10px",
                              }}
                              value={
                                isEditEnabled
                                  ? PatientData?.FirstName + " " + PatientData?.LastName
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                            /> */}
                      {/* {PersonUserId ? (
                              <Controller
                                name="Appointmentfor"
                                control={control}
                                defaultValue=""
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    label="Appointment For"
                                    variant="filled"
                                    fullWidth
                                    id="outlined-read-only-input"
                                    value={appointmentFor}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            ) : ( */}
                      {/* <Controller
                              name="Appointmentfor"
                              control={control}
                              defaultValue=""
                              InputProps={{
                                readOnly: true,
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <TextField
                                  label="Appointment For"
                                  variant="filled"
                                  fullWidth
                                  id="outlined-read-only-input"
                                  value={appointmentFor}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            /> */}
                      {/* )} */}
                      {/* </div> */}
                      {/* </Grid> */}
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AppointmentDate"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Date"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={onChange}
                                  minDate={new Date()}
                                  // minDate={new Date("02-01-2022")}
                                  // maxDate={new Date("09-05-2022")}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={errors.AppointmentDate}
                                      helperText={
                                        errors.AppointmentDate ? "Appointment Date Required." : ""
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </Grid>
                      {AppointmentType == CommonConfig.BookingType_RequestCallBack ? (
                        <Grid item md="4">
                          <div className="reg_input auto-complete">
                            <div className="reg_input auto-complete">
                              <Autocomplete
                                id="combo-box-demo"
                                options={TimeSlot ? TimeSlot : []}
                                value={selectedTimeLabel ? selectedTimeLabel : ""}
                                onChange={(e, value) => {
                                  handleCallBackTimeChange(value.label);
                                  setSelectedTimeLabel(value.label);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Time"
                                    name="time"
                                    defaultValue={isEditEnabled ? PatientData?.AppointmentTime : ""}
                                    // value="time"
                                    // error={values.errortime}
                                    // helperText={values.errortime}
                                    // onChange={(e) => handleChange(e, "time")}
                                  />
                                )}
                              />

                              {/*                                 

                              {(isTimeValid || PatientData?.AppointmentTime !='') ? (
                                  <></>
                                ) : (
                                  <span className="err">Please Enter Time</span>
                              )}*/}
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        <Grid item md="4">
                          <div className="reg_input">
                            <div className="reg_input auto-complete">
                              <Autocomplete
                                disablePortal
                                // id="AppointmentTime"
                                name="AppointmentTime"
                                defaultValue={isEditEnabled ? PatientData?.AppointmentTime : ""}
                                value={
                                  !selectedTimeLabel
                                    ? isEditEnabled
                                      ? PatientData?.AppointmentTime
                                      : ""
                                    : selectedTimeLabel
                                }
                                // options={TimeList ? TimeList : []}
                                options={TimeList ? TimeList : []}
                                onChange={(e, value) => {
                                  // console.log("EE", value);
                                  // console.log("EEEE", value);
                                  handleTimeChange(value.id);
                                  setSelectedTimeLabel(value.label);
                                  // setValues((prev) => ({ ...prev, time: value.id }));
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...register("AppointmentTime")}
                                    {...params}
                                    label="Time"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Grid>
                      )}
                    </Grid>

                    {isEditEnabled ? (
                      <Grid item md="8">
                        <div className="reg_input sec_level auto-complete">
                          <Autocomplete
                            multiple
                            id="Services"
                            name="Services"
                            options={serviceList ? serviceList : []}
                            value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                            defaultValue={
                              serviceAutocompleteValues ? serviceAutocompleteValues : []
                            }
                            onChange={onServiceChange}
                            filterSelectedOptions
                            // formControlProps={{
                            //   fullWidth: true,
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Services you have registered"
                                placeholder="Favorites"
                                // error={errorservices}
                                // helperText={errorservices}
                                id="Services"
                                name="Services"
                                // defaultValue={Params}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {AppointmentType === CommonConfig.BookingType_FaceToFace ? (
                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <div className="reg_input check_value mb-0">
                            <Controller
                              name="IsLocationSelector"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                  <InputLabel className="reg_lable mt-15">Location</InputLabel>
                                  <RadioGroup
                                    // aria-label="gender"
                                    name="IsLocationSelector"
                                    row="true"
                                    // defaultValue="value care office site"
                                  >
                                    <FormControlLabel
                                      value="value care office site"
                                      label="Value Care Office Site"
                                      onChange={onChange}
                                      control={
                                        <Radio
                                          checked={IsLocationSelector === "value care office site"}
                                        />
                                      }
                                      // label="Value care's office/site"
                                      name="location"
                                    />
                                    <FormControlLabel
                                      value="Australin Address"
                                      onChange={onChange}
                                      control={
                                        <Radio
                                          checked={IsLocationSelector === "Australin Address"}
                                        />
                                      }
                                      label="Provide an Australian address"
                                      name="location"
                                      // rules={{ required: "Please Select Location" }}
                                    />
                                  </RadioGroup>
                                </>
                              )}
                            />
                          </div>
                          {IsLocationSelector == "value care office site" && (
                            <>
                              <Grid
                                container
                                rowSpacing={0}
                                columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                              >
                                <Grid item md="4">
                                  <div className="reg_input auto-complete">
                                    <Controller
                                      control={control}
                                      name="OfficeSite"
                                      rules={{ required: true }}
                                      render={({ field: { onChange, value } }) => (
                                        <Autocomplete
                                          disablePortal
                                          id="OfficeSite"
                                          name="OfficeSite"
                                          value={value}
                                          options={officeSiteData ? officeSiteData : []}
                                          onChange={(e, v) => onChange(v)}
                                          // onBlur = {(e, v) => GetAddress(v)}
                                          onBlur={GetAddress}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="OfficeSite"
                                              error={errors.OfficeSite}
                                              helperText={
                                                errors.OfficeSite ? "Office Site required" : ""
                                              }
                                            />
                                          )}
                                        />
                                      )}
                                    />
                                  </div>
                                </Grid>

                                <Grid item md="8">
                                  <div className="reg_input">
                                    <span class="Addressset" id="setAddressData"></span>
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                          )}
                          {IsLocationSelector == "Australin Address" && (
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="12">
                                <InputLabel className="reg_lable mb-20 mt-15">Address</InputLabel>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="AddrLine1"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Unit/Street Address*"
                                        variant="filled"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>

                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="AddrLine2"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Street Name"
                                        variant="filled"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="Suburb"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Suburb*"
                                        variant="filled"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input auto-complete">
                                  <Controller
                                    control={control}
                                    name="state"
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                      <Autocomplete
                                        disablePortal
                                        id="state"
                                        name="state"
                                        value={value}
                                        options={stateData ? stateData : []}
                                        onChange={(e, v) => onChange(v)}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="State"
                                            error={errors.state}
                                            helperText={errors.state ? "state required" : ""}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>

                              <Grid item md="4">
                                <div className="reg_input">
                                  <Controller
                                    name="PostalCode"
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        label="Postal Code*"
                                        variant="filled"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                        helperText={error?.message}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ) : null}
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <TextField
                            type="number"
                            color="primary"
                            //name="phoneNumber"
                            value={phoneNumber ? phoneNumber : ""}
                            label="Phone Number"
                            variant="outlined"
                            // error={values["errorclientPhone"]}
                            id="clientPhone"
                            name="clientPhone"
                            defaultValue={isEditEnabled ? PatientData?.PhoneForConfirmation : ""}
                            // value={!phoneNumber ? PhoneForConfirmation}
                            // helperText={values["errorclientPhone"]}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="8">
                        <div className="reg_input">
                          <TextField
                            onChange={(e) => setAdditionalInformation(e.target.value)}
                            value={additionalInformation ? additionalInformation : ""}
                            name="additionalInformation"
                            multiline
                            rows={4}
                            // maxRows={4}
                            label="Additional Information"
                            id="regFirstName"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            //   required={true}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    {isEditEnabled ? (
                      <></>
                    ) : (
                      <Grid container spacing={3}>
                        <Grid item>
                          <div className="reg_input check_value">
                            <FormControlLabel
                              onChange={(e) => {
                                // console.log("Terms", e);
                                // setTerms(!terms);
                              }}
                              control={<Checkbox name="checkedB" color="primary" />}
                              // error={"!terms"}
                              // helperText={errors.terms}
                              label="By clicking Submit button, you agree to Value Care’s Terms &amp; Conditions"
                            />
                            <span id="errorTC" className="errorTC" style={{ display: "none" }}>
                              Please accept the terms and condition{" "}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    )}

                    <Grid container spacing={3}>
                      <Grid item>
                        <div className="reg_submit">
                          <MDButton
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate("/pages/book-appointment/list")}
                          >
                            Cancel
                          </MDButton>

                          <MDButton
                            type="submit"
                            style={{ color: "#fff" }}
                            variant="contained"
                            color="primary"
                          >
                            Update
                          </MDButton>
                          {/* <MDButton
                            variant="contained"
                            color="primary"
                          //   onClick={() => handleSubmit()}
                          >
                            Submit
                          </MDButton> */}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              )}
              {/*CallBack Screens  */}

              {/* BookAnOppointment Screens */}
              {form1 === 10 && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="reg_step" id="bookStep1">
                    <h2
                      className="h2-heading align-left"
                      style={{
                        backgroundColor: "#34A853",
                        width: "250px",
                        textAlign: "start",
                        paddingLeft: "10px",
                      }}
                    >
                      Audio/Video Call
                    </h2>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <TextField
                          fullWidth
                          id="outlined-read-only-input"
                          label="Appoinment For"
                          value={
                            isEditEnabled
                              ? PatientData?.FirstName + " " + PatientData?.LastName
                              : ""
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Autocomplete
                            disablePortal
                            id="ManagedBy"
                            name="ManagedBy"
                            options={managedData ? managedData : []}
                            onChange={onManagedByChanged}
                            //   value={
                            //     !stateLabel
                            //       ? stateList
                            //         ? userData?.PersonState
                            //           ? stateList[userData.PersonState - 1]
                            //           : ""
                            //         : ""
                            //       : stateLabel
                            //   }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...register("ManagedBy")}
                                {...params}
                                label="Managed By"
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <Grid container direction="row">
                          <h5
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            Status:
                          </h5>

                          <div
                            style={{
                              marginLeft: "30px",
                            }}
                          >
                            <h5>Pending</h5>
                            <h4 className="h5">
                              <u
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                Change Status
                              </u>
                            </h4>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item md="12">
                        <div className="reg_input check_value mb-0">
                          <InputLabel className="reg_lable mt-15">Mode of Communication</InputLabel>
                          <RadioGroup
                            aria-label="gender"
                            name="CallType"
                            row="true"
                            onChange={(e) => setSelectedMediumType(e.target.value)}
                          >
                            <FormControlLabel
                              value="Video Call"
                              // onChange={(e) => handleChange(e)}
                              control={
                                <Radio
                                  checked={selectedMediumType === "Video Call" ? true : false}
                                />
                              }
                              label="Video Call"
                              name="CallType"
                            />
                            <FormControlLabel
                              value="Audio Call"
                              // onChange={(e) => handleChange(e)}
                              control={
                                <Radio
                                  checked={selectedMediumType === "Audio Call" ? true : false}
                                />
                              }
                              label="Audio Call"
                              name="CallType"
                            />
                          </RadioGroup>
                        </div>
                      </Grid>

                      {/* <Grid item md="8">
                        <div className="reg_input sec_level">
                          <Autocomplete
                            multiple
                            id="Services"
                            name="Services"
                            options={serviceList ? serviceList : []}
                            // onChange={onServiceChange}
                            filterSelectedOptions
                            // formControlProps={{
                            //   fullWidth: true,
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Services you have registered"
                                placeholder="Favorites"
                                // error={errorservices}
                                // helperText={errorservices}
                                id="Services"
                                name="Services"
                                // defaultValue={Params}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid> */}

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="AppointmentDate"
                              defaultValue=""
                              control={control}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                  <DatePicker
                                    label="Appointment Date"
                                    value={value || null}
                                    disablePast={true}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={onChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        error={errors.AppointmentDate}
                                        helperText={
                                          errors.AppointmentDate ? "Appointment Date Required." : ""
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <div className="reg_input auto-complete">
                              <Autocomplete
                                disablePortal
                                // id="AppointmentTime"
                                name="AppointmentTime"
                                defaultValue={isEditEnabled ? PatientData?.AppointmentTime : ""}
                                value={
                                  !selectedTimeLabel
                                    ? isEditEnabled
                                      ? PatientData?.AppointmentTime
                                      : ""
                                    : selectedTimeLabel
                                }
                                // options={TimeList ? TimeList : []}
                                options={TimeList ? TimeList : []}
                                onChange={(e, value) => {
                                  // console.log("EE", value);
                                  // console.log("EEEE", value);
                                  handleTimeChange(value.id);
                                  setSelectedTimeLabel(value.label);
                                  // setValues((prev) => ({ ...prev, time: value.id }));
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...register("AppointmentTime")}
                                    {...params}
                                    label="Appointment Time"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        {values.CallType === "Video Call" && (
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                type="number"
                                color="primary"
                                //name="phoneNumber"
                                label="Phone Number"
                                variant="outlined"
                                // error={values["errorclientPhone"]}
                                id="clientPhone"
                                name="clientPhone"
                                // helperText={values["errorclientPhone"]}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber ? phoneNumber : ""}
                              />
                            </div>
                          </Grid>
                        )}
                        {values.CallType === "Audio Call" && (
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                type="number"
                                color="primary"
                                // name="phoneNumber"
                                label="Phone Number"
                                variant="outlined"
                                // error={values["errorclientPhone"]}
                                id="clientPhone"
                                name="clientPhone"
                                // helperText={values["errorclientPhone"]}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber ? phoneNumber : ""}
                              />
                            </div>
                          </Grid>
                        )}
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="8">
                          <div className="reg_input">
                            <TextField
                              onChange={(e) => setAdditionalInformation(e.target.value)}
                              value={additionalInformation ? additionalInformation : ""}
                              multiline
                              rows={3}
                              // maxRows={6}
                              name="additionalInformation"
                              label="Additional Information"
                              id="regFirstName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              required={true}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item>
                          <div className="reg_input check_value">
                            <FormControlLabel
                              // onChange={(e) => {
                              //   setTerms(!terms);
                              // }}
                              control={<Checkbox name="checkedB" color="primary" />}
                              // error={!terms}
                              // helperText={errors.terms}
                              label="By registering you agree to Value Care’s Terms &amp; Conditions"
                            />
                            <span id="errorTC" className="errorTC" style={{ display: "none" }}>
                              Please accept the terms and condition{" "}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item>
                          <div className="reg_submit">
                            <Button type="submit" variant="contained" color="primary">
                              Submit
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              )}

              {/* </div> */}
            </Grid>
          </Grid>
        </MDBox>
      </Card>

      {form1 > 2 && CommonConfig.isInternalStaff() === 1 ? (
        <Card sx={{ width: "100%", mt: 6 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                warning_amber
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Notes
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            {/* <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className="reg_input">
              <Autocomplete
                options={notesDropdownList ? notesDropdownList : []}
                defaultValue={selectedNotesType ? selectedNotesType : ""}
                onChange={(e, value) => {
                  setSselectedNotesType(value.label);
                }}
                renderInput={(params) => (
                  <TextFieldVideo Call
                    {...params}
                    variant="outlined"
                    label="Notes Type*"
                    name="NotesType"
                    // error={values.errorstate}
                    // helperText={values.errorstate}
                  />
                )}
              />
              <span class="errorTC" id="ServicesError">
                Please select Notes Type.
              </span>
            </div>
          </Grid>
          <Grid item xs={4}>
            <ReportGmailerrorredIcon
              color={inNoteImportant ? "primary" : "secondary"}
              fontSize="large"
              onClick={() => setinNoteImportant((prev) => !prev)}
            />
          </Grid>
        </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* <div className="reg_input">
              <TextField
                onChange={(e) => setChNotes(e.target.value)}
                value={chNotes ? chNotes : ""}
                name="notes"
                multiline
                rows={3}
                // maxRows={4}
                label="Notes Text*"
                id="regFirstName"
                fullWidth
              />
              <span class="errorTC" id="NotesError">
                Please Write Notes.
              </span>
            </div> */}
              </Grid>
            </Grid>

            <div className="notes-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Notes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td>
                    <td className="nt-comment">
                      <div className="reg_input mb-0">
                        <TextField
                          onChange={(e) => setChNotes(e.target.value)}
                          value={chNotes ? chNotes : ""}
                          name="notes"
                          multiline
                          rows={2}
                          // maxRows={4}
                          label="Notes Text*"
                          id="regFirstName"
                          fullWidth
                        />
                        <span class="errorTC" id="NotesError">
                          Please Write Notes.
                        </span>
                      </div>
                    </td>
                    <td className="nt-action">
                      <PButton
                        type="button"
                        icon="pi pi-plus"
                        className="p-button-primary AddNotes"
                        onClick={() => saveNotes()}
                        // style={{ marginRight: ".5em" }}
                        tooltip="Add Notes"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Grid item md="4"></Grid>
            {/* {complianceRegisterId ?( */}
            <NotesComponent
              entityType="Appointment"
              entityId={PatientData?.AppointmentID ? PatientData?.AppointmentID : ""}
              NotesData={notesData}
            />

            {/* ) : null} */}
          </MDBox>
        </Card>
      ) : (
        <></>
      )}

      {/* {form1 > 2 && CommonConfig.isInternalStaff() === 1 ? (
        <div className="custom-accordian mt-2">
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownward />}>Notes</AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={3}>
                  <h5>Notes Type: </h5>
                </Grid>
                <Grid item xs={4}>
                  <div className="reg_input">
                    <Autocomplete
                      options={notesDropdownList ? notesDropdownList : []}
                      defaultValue={selectedNotesType ? selectedNotesType : ""}
                      onChange={(e, value) => {
                        setSselectedNotesType(value.label);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Notes Type*"
                          name="NotesType"
                          error={values.errorstate}
                          helperText={values.errorstate}
                        />
                      )}
                    />
                    <span class="errorTC" id="ServicesError">
                      Please select Notes Type.
                    </span>
                    <ReportGmailerrorredIcon
                      color={inNoteImportant ? "primary" : "secondary"}
                      fontSize="large"
                      onClick={() => setinNoteImportant((prev) => !prev)}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={3}>
                  <h5>Notes Text: </h5>
                </Grid>
                <Grid item xs={4}>
                  <div className="reg_input">
                    <TextField
                      onChange={(e) => setChNotes(e.target.value)}
                      value={chNotes ? chNotes : ""}
                      name="notes"
                      multiline
                      rows={3}
                      style={{
                        marginBottom: "10px",
                      }}
                      // maxRows={4}
                      label="Notes*"
                      id="regFirstName"
                      fullWidth
                    />
                    <span class="errorTC" id="NotesError">
                      Please Write Notes.
                    </span>
                  </div>
                </Grid>
              </Grid>
              <Grid item md="4">
                <MDButton onClick={() => saveNotes()} variant="contained" color="primary">
                  Save Notes
                </MDButton>
              </Grid>
            </AccordionDetails>

            <NotesComponent
              entityType="Appointment"
              entityId={PatientData?.AppointmentID ? PatientData?.AppointmentID : ""}
              NotesData={notesData}
            />
          </Accordion>
        </div>
      ) : null} */}
    </DashboardLayout>
  );
}

export default UserBookAppointmentToken;
