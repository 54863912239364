import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";
import { autoLogin } from "../../commonAPI/commonAPI";
import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";

const schema = yup.object().shape({
  // Relation: yup
  //   .string()
  //   .required("Relation must be entered")
  //   .min(3, "Relation must be at least 3 characters"),

  FirstName: yup
    .string()
    .required("First Name Required.")
    .min(3, "First Name must be at least 3 characters."),
  LastName: yup
    .string()
    .required("Last Name Required.")
    .min(3, "Last Name must be at least 3 characters."),

  Email: yup.string().required("Email Required.").email("Incorrect email format."),
  PhoneNumber: yup
    .string()
    .required("Phone Required.")
    .matches("^[0-9]+$", "Phone should be number.")
    .min(10, "Invalid Phone Number.")
    .max(10, "Invalid Phone Number."),
  // DOB: yup.string().required("DOB required"),

  IsLiving: yup.boolean(),
  GroupHome: yup.string().when("IsLiving", {
    is: true,
    then: yup.string().required("Group Home Required."),
  }),

  AddrLine1: yup.string().required("Street Address Required."),
  // AddrLine2: yup.string().required("Street Name Required"),
  Suburb: yup.string().required("Suburb Required."),

  IsNdisDocument: yup.boolean(),
  Document: yup.string().when("IsNdisDocument", {
    is: true,
    then: yup.string().required("Please Select Document"),
  }),

  // PostalCode: yup
  //   .string()
  //   .nullable()
  //   .notRequired()
  //   .when("PostalCode", {
  //     is: (value) => value?.length,
  //     then: yup
  //       .string()
  //       .required("Postal Code Required")
  //       .matches("^[0-9]+$", "Postal Code should be number")
  //       .min(4, "Invalid Postal Code")
  //       .max(4, "Invalid Postal Code"),
  //   }),
  PostalCode: yup
    .string()
    .required("Postal Code Required.")
    .matches("^[0-9]+$", "Should be number.")
    .min(4, "Postal Code must be at least 4 digit.")
    .max(4, "Invalid Postal Code."),

  IsNdisRegistered: yup.boolean(),
  NDISNumber: yup.string().when("IsNdisRegistered", {
    is: true,
    then: yup
      .string()
      .required("NDIS Number Required.")
      .matches("^[0-9]+$", "Should be number.")
      .min(9, "NDIS Number must be at least 9 digit.")
      .max(9, "Invalid NDIS Number."),
  }),

  Disabilities: yup.string().required("Disabilities Required."),
  State: yup.object().required("State Required."),
});

const AddPatient = () => {
  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);

  const [values, setValues] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [relativeStateId, setRelativeStateId] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

  const [userData, setUserData] = useState(state?.SecurityUserData || null);

  const [stateList, setStateList] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);

  const onStateChange = (event, value) => {
    console.log("State::", value);
    setStateId(value.id);
    setStateLabel(value.label);
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (state && JSON.parse(state.SecurityUserData?.Services)) {
      setAutocompleteValues();
    }
  }, []);

  const setAutocompleteValues = () => {
    let dummyArray = [];
    let formattedData = [];
    let array = JSON.parse(state.SecurityUserData.Services);
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        dummyArray.push({
          id: array[i],
          label: array[i],
        });
      }
    }
    for (let i = 0; i < array.length; i++) {
      formattedData.push(array[i]);
    }
    debugger;
    setValues(formattedData);
    setServiceAutocompleteValues(dummyArray);
  };

  const onServiceChange = (event, value) => {
    console.log("VALUEService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }

    setValues(formattedData);
    setServiceAutocompleteValues(value);
    console.log("onService", values);
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getStateData();
    getServiceData();
  }, []);

  console.log("UserData = ", userData);
  const statedata = {
    id: userData?.State,
    label: userData?.State,
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      PersonType: "DIFFERENTLY ABLED",
      PersonCategory: "PATIENT",

      Relation: userData?.RelationshipCategory ? userData?.RelationshipCategory : "",
      FirstName: userData?.FirstName ? userData?.FirstName : "",
      MiddleName: userData?.MiddleName ? userData?.MiddleName : "",
      LastName: userData?.LastName ? userData?.LastName : "",
      Gender: userData?.Gender ? userData?.Gender : "Male",
      DOB: userData?.DOB ? userData?.DOB : "",
      PhoneNumber: userData?.PhoneNumber ? userData?.PhoneNumber : "",
      Email: userData?.Email ? userData?.Email : "",
      GroupHome: userData?.GroupHome ? userData?.GroupHome : "",
      AddrLine1: userData?.AddrLine1 ? userData?.AddrLine1 : "",
      AddrLine2: userData?.AddrLine2 ? userData?.AddrLine2 : "",
      Suburb: userData?.Suburb ? userData?.Suburb : "",
      State: userData?.State ? statedata : "",
      PostalCode: userData?.PostalCode ? userData?.PostalCode : "",
      IsLiving: userData?.IsLiving ? userData?.IsLiving : 0,
      IsNdisRegistered: userData?.IsNdisRegistered ? userData?.IsNdisRegistered : 0,
      IsNdisDocument: userData?.IsNdisDocument ? userData?.IsNdisDocument : 0,
      NDISNumber: userData?.NDISNumber ? userData?.NDISNumber : "",
      Document: "",
      Disabilities: userData?.Disabilities ? userData?.Disabilities : "",
      Appointment: userData?.Appointment ? 1 : 0,
      Services: userData?.Services ? userData?.Services : "",
    },
  });

  let IsLiving = watch("IsLiving", true);
  let IsNdisRegistered = watch("IsNdisRegistered", true);
  let IsNdisDocument = watch("IsNdisDocument", true);
  let Appointment = watch("Appointment", true);

  console.log("eeeeeee", errors);

  console.log("Serv", values);
  const onSubmit = (data) => {
    let FState = data.State?.id;
    debugger;
    console.log("data.Appointment = ", data.Appointment, "Values = ", values);
    if (data.Appointment == 1) {
      console.log("Welcome");
      if (values.length == 0) {
        document.getElementById("ServicesError").style.display = "block";
        return;
      } else {
        document.getElementById("ServicesError").style.display = "none";
      }
    }
    debugger;

    console.log("Services111", values, "data.Appointment", data.Appointment);

    let DobFormat = CommonConfig.formatDate(data.DOB);
    console.log("DobFormat", DobFormat);
    let formattedData = "";

    let sevicelist = [];
    sevicelist = values;
    formattedData = {
      ...data,
      CurrentUser: CommonConfig.getCurrentUserId(),
      DOB: DobFormat,
      IsLiving: IsLiving == true ? 1 : 0,
      IsNdisRegistered: IsNdisRegistered == true ? 1 : 0,
      IsNdisDocument: IsNdisDocument == true ? 1 : 0,
      Services: sevicelist,
      State: FState,
    };

    if (userData) {
      formattedData = {
        ...formattedData,
        PersonId: state?.PersonId,
        CurrentUser: CommonConfig.getCurrentUserId(),
      };
    }
    console.log("ISLIVINGS", formattedData);

    console.log("formattedData.....", formattedData);
    setLoader(!loader);
    let formattedDataT;

    formattedDataT = {
      formattedData,
    };

    if (userData) {
      formattedDataT = {
        formattedData,
        CurrentUser: CommonConfig.getCurrentUserId(),
      };
    }

    console.log("formattedDataTTT.....", formattedDataT);

    axios({
      url: CommonConfig.ApiUrl + "authentication/addUpdatePatient",

      method: "POST",
      headers: {},
      data: formattedDataT,
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data.success === true) {
          if (userData) {
            cogoToast.success("Participant Updated Successfully.");
            navigate("/dashboards/userprofile/Index");
          } else {
            cogoToast.success("Participant Added Successfully.");
            navigate("/pages/book-appointment/UserBookAppointmentToken");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log("AddUpdateErro", error);
      });
  };

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {userData ? (
        <DashboardLayout>
          <DashboardNavbar />

          <Card sx={{ width: "100%", mt: 3 }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="primary"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  add
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Add Participant.
              </MDTypography>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: "auto", mr: 2 }}>
                Person# : {userData.PersonIdNumber}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reg_step" id="regStep1">
                  <div className="reg_form">
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                      <Grid item md="12">
                        <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                          If related, what is your relationship with the person needing supports or
                          services?{" "}
                        </InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Relation"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Enter Relationship"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="FirstName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="First Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="MiddleName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Middle Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="LastName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Last Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="reg_input check_value">
                      <InputLabel>Gender</InputLabel>
                      <Controller
                        name="Gender"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            {" "}
                            <RadioGroup
                              aria-label="appointment"
                              row="true"
                              defaultValue={userData ? userData?.Gender : "Male"}
                            >
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Male"
                                control={<Radio />}
                                label="Male"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Female"
                                control={<Radio />}
                                label="Female"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Unknown"
                                control={<Radio />}
                                label="Unknown"
                              />
                            </RadioGroup>
                          </>
                        )}
                      />
                    </div>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="DOB"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Select DOB"
                                  // value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={onChange}
                                  // minDate={new Date("15-08-1947")}
                                  maxDate={new Date()}
                                  renderInput={(params) => (
                                    <TextField
                                      helperText={error?.message}
                                      error={error}
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="PhoneNumber"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone Number*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                disabled={true}
                                label="Email*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Are you Living in a Group Home? </InputLabel>
                          <Controller
                            name="IsLiving"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                defaultValue={userData ? userData?.IsLiving : 0}
                                aria-label="Living"
                                name="IsLiving"
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                            rules={{ required: "Email Id required" }}
                          />
                        </div>
                        {IsLiving == 1 ? (
                          <div className="reg_input sec_level">
                            <Controller
                              name="GroupHome"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Group Home*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <InputLabel className="reg_lable">Address</InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine1"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Address*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine2"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Suburb"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Suburb*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="State"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="State"
                                name="State"
                                value={value}
                                options={stateList ? stateList : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="State*"
                                    error={errors.State}
                                    helperText={errors.State ? "State Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="PostalCode"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postal Code*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 4,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Are you registered with NDIS? </InputLabel>
                          <Controller
                            name="IsNdisRegistered"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                aria-label="NDIS"
                                defaultValue={userData ? userData?.IsNdisRegistered : "0"}
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                          />
                        </div>

                        {IsNdisRegistered == 1 ? (
                          <div className="reg_input sec_level">
                            <Controller
                              name="NDISNumber"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Enter NDIS Number*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 9,
                                  }}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Do you have your NDIS plan document available?</InputLabel>
                          <Controller
                            name="IsNdisDocument"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                defaultValue={userData ? userData?.IsNdisDocument : "0"}
                                aria-label="NDIS Document"
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                          />
                        </div>
                        {IsNdisDocument == 1 ? (
                          <div className="reg_input sec_level">
                            <label classname="test-label">Select Document*</label>
                            <Controller
                              name="Document"
                              control={control}
                              defaultValue=""
                              label="NDIS File*"
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  type="file"
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                            {userData ? (
                              <div>
                                {userData.Document ? <label>{userData.Document} </label> : null}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                      <Grid item md="12">
                        <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                          What impairments or disabilities does the person has?{" "}
                        </InputLabel>
                      </Grid>
                      <Grid item md="8">
                        <div className="reg_input">
                          <Controller
                            name="Disabilities"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Disability Condition*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <div className="reg_input check_value">
                          <InputLabel>
                            Do you know which services you need or shall we arrange an Appointment
                            with one of our Client Manager?
                          </InputLabel>
                          <Controller
                            name="Appointment"
                            control={control}
                            defaultValue="0"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                aria-label="appointment"
                                defaultValue={userData?.Appointment ? 1 : 0}
                                row="true"
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="1"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="0"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                            rules={{ required: "Select any one" }}
                          />
                        </div>
                        <Grid item sm={6} md={6}>
                          {Appointment == 1 ? (
                            <div className="reg_input auto-complete">
                              {/* <Controller
                            name="Services"
                            control={control}
                            defaultValue={[]}
                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                              <Autocomplete
                                {...field}
                                disableClearable
                                disablePortal
                                filterSelectedOptions
                                multiple
                                // value={serviceAutocompleteValues}
                                value={undefined}
                                options={serviceList ? serviceList : ""}
                                id="Services"
                                onChange={(event, value) => {
                                  field.onChange(value);
                                  // onServiceChange(value);
                                }}
                                // options={daysOfWeekSuggestions}
                                renderInput={(params) => (
                                  <TextField
                                    // required
                                    error={!!error}
                                    helperText={error?.message}
                                    id="Services"
                                    label="Services"
                                    name="Services"
                                    type="search"
                                    inputRef={ref}
                                    {...params}
                                  />
                                )}
                              />
                            )}
                          /> */}

                              <Autocomplete
                                multiple
                                id="Services"
                                name="Services"
                                options={serviceList ? serviceList : []}
                                value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                                defaultValue={
                                  serviceAutocompleteValues ? serviceAutocompleteValues : []
                                }
                                onChange={onServiceChange}
                                filterSelectedOptions
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="What services are you registered for?*"
                                    placeholder="Favorites"
                                    // error={errorservices}
                                    // helperText={errorservices}
                                    id="Services"
                                    name="Services"
                                    // defaultValue={Params}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                )}
                              />
                              <span class="servicesError" id="ServicesError">
                                Please select any one service.
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                {userData ? (
                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/dashboards/userprofile/Index")}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      type="submit"
                      style={{ color: "white" }}
                      variant="contained"
                      color="primary"
                    >
                      Update
                    </MDButton>
                  </div>
                ) : (
                  <div className="reg_submit">
                    <MDButton type="submit" variant="contained" color="primary">
                      Register
                    </MDButton>
                  </div>
                )}

                <div className="copyright">
                  <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
                </div>
              </form>
            </MDBox>
          </Card>
        </DashboardLayout>
      ) : (
        <DashboardLayout>
          <DashboardNavbar />
          <Card sx={{ width: "100%", mt: 3 }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="primary"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  add
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Add Participant.
              </MDTypography>
            </MDBox>
            <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reg_step" id="regStep1">
                  <div className="reg_form">
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                      <Grid item md="12">
                        <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                          If related, what is your relationship with the person needing supports or
                          services?{" "}
                        </InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Relation"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Enter Relationship"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="FirstName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="First Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="MiddleName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Middle Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="LastName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Last Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="reg_input check_value">
                      <InputLabel>Gender</InputLabel>
                      <Controller
                        name="Gender"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            {" "}
                            <RadioGroup
                              aria-label="appointment"
                              row="true"
                              defaultValue={userData ? userData?.Gender : "Male"}
                            >
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Male"
                                control={<Radio />}
                                label="Male"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Female"
                                control={<Radio />}
                                label="Female"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Unknown"
                                control={<Radio />}
                                label="Unknown"
                              />
                            </RadioGroup>
                          </>
                        )}
                      />
                    </div>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="DOB"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Select DOB"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={onChange}
                                  // minDate={new Date("15-08-1947")}
                                  maxDate={new Date()}
                                  renderInput={(params) => (
                                    <TextField
                                      helperText={error?.message}
                                      error={error}
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="PhoneNumber"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone Number*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Email*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Are you Living in a Group Home? </InputLabel>
                          <Controller
                            name="IsLiving"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                defaultValue={userData ? userData?.IsLiving : 0}
                                aria-label="Living"
                                name="IsLiving"
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                            rules={{ required: "Email Id required" }}
                          />
                        </div>
                        {IsLiving == 1 ? (
                          <div className="reg_input sec_level">
                            <Controller
                              name="GroupHome"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Group Home*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <InputLabel className="reg_lable">Address</InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine1"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Address*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine2"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Suburb"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Suburb*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="State"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="State"
                                name="State"
                                value={value}
                                options={stateList ? stateList : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="State*"
                                    error={errors.State}
                                    helperText={errors.State ? "State Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="3">
                        <div className="reg_input">
                          <Controller
                            name="PostalCode"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postal Code*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 4,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Are you registered with NDIS? </InputLabel>
                          <Controller
                            name="IsNdisRegistered"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                aria-label="NDIS"
                                defaultValue={userData ? userData?.IsNdisRegistered : "0"}
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                          />
                        </div>

                        {IsNdisRegistered == 1 ? (
                          <div className="reg_input sec_level">
                            <Controller
                              name="NDISNumber"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Enter NDIS Number*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 9,
                                  }}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Do you have your NDIS plan document available?</InputLabel>
                          <Controller
                            name="IsNdisDocument"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                defaultValue={userData ? userData?.IsNdisDocument : "0"}
                                aria-label="NDIS Document"
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                          />
                        </div>
                        {IsNdisDocument == 1 ? (
                          <div className="reg_input sec_level">
                            <label classname="test-label">Select Document*</label>
                            <Controller
                              name="Document"
                              control={control}
                              label="NDIS File*"
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  type="file"
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                      <Grid item md="12">
                        <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                          What impairments or disabilities does the person has?{" "}
                        </InputLabel>
                      </Grid>
                      <Grid item md="8">
                        <div className="reg_input">
                          <Controller
                            name="Disabilities"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Disability Condition*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <div className="reg_input check_value">
                          <InputLabel>
                            Do you know which services you need or shall we arrange an Appointment
                            with one of our Client Manager?
                          </InputLabel>
                          <Controller
                            name="Appointment"
                            control={control}
                            // defaultValue="0"
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                aria-label="appointment"
                                defaultValue={userData ? userData?.Appointment : "0"}
                                row="true"
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="1"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="0"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                            rules={{ required: "Select any one" }}
                          />
                        </div>
                        <Grid item sm={6} md={6}>
                          {Appointment == 1 ? (
                            <div className="reg_input auto-complete">
                              {/* <Controller
                            name="Services"
                            control={control}
                            defaultValue={[]}
                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                              <Autocomplete
                                {...field}
                                disableClearable
                                disablePortal
                                filterSelectedOptions
                                multiple
                                // value={serviceAutocompleteValues}
                                value={undefined}
                                options={serviceList ? serviceList : ""}
                                id="Services"
                                onChange={(event, value) => {
                                  field.onChange(value);
                                  // onServiceChange(value);
                                }}
                                // options={daysOfWeekSuggestions}
                                renderInput={(params) => (
                                  <TextField
                                    // required
                                    error={!!error}
                                    helperText={error?.message}
                                    id="Services"
                                    label="Services"
                                    name="Services"
                                    type="search"
                                    inputRef={ref}
                                    {...params}
                                  />
                                )}
                              />
                            )}
                          /> */}

                              <Autocomplete
                                multiple
                                id="Services"
                                name="Services"
                                options={serviceList ? serviceList : []}
                                value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                                defaultValue={
                                  serviceAutocompleteValues ? serviceAutocompleteValues : []
                                }
                                onChange={onServiceChange}
                                filterSelectedOptions
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="What services are you registered for?*"
                                    placeholder="Favorites"
                                    // error={errorservices}
                                    // helperText={errorservices}
                                    id="Services"
                                    name="Services"
                                    // defaultValue={Params}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                )}
                              />
                              <span class="servicesError" id="ServicesError">
                                Please select any one service.
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                {userData ? (
                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/dashboards/userprofile/Index")}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      Update
                    </MDButton>
                  </div>
                ) : (
                  <div className="reg_submit">
                    <MDButton
                      onClick={() => navigate("/pages/book-appointment/UserBookAppointmentToken")}
                      variant="contained"
                      color="secondary"
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      Add Participant
                    </MDButton>
                  </div>
                )}

                <div className="copyright">
                  <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
                </div>
              </form>
            </MDBox>
          </Card>
        </DashboardLayout>
      )}
    </>
  );
};

AddPatient.defaultProps = {};

export default AddPatient;
