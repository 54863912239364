import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import ReactDropzone from "react-dropzone";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MDEditor from "components/MDEditor";
import CKEditor from "ckeditor4-react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
//import { CKEditor } from "ckeditor4-react";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";

import { isValidDate } from "@fullcalendar/react";
import {
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const schema = yup.object().shape({
  PropertyName: yup.string().required("Property Name Required."),
  TotalSizeSqft: yup.string().required("Property Total Size Required."),
  PropertyType: yup.object().required("Property Type Required."),
  BriefDescription: yup.string().required("Brief Description Required."),
  AddrLine1: yup.string().required("Street Address Required."),
  Suburb: yup.string().required("Suburb Required."),
  State: yup.object().required("State Required."),
  PostalCode: yup.string().required("Postal Code Required."),
  TotalBedroomCount: yup.object().required("Total BedRooms Required."),
  TotalAvailableBedroomCount: yup.object().required("Available Bedrooms Required."),
  MinBedroomAllocation: yup.object().required("Minimum Bedroom Allocation Required."),
  BathroomCount: yup.object().required("Bathrooms Required."),
  // BalconyCount: yup.object().required("Balcony Required."),
  BedroomDescription: yup.string().required("Bedroom Features Required."),
});

const AddProperty = () => {
  const MAX_COUNT = 15;

  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);

  const [values, setValues] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [relativeStateId, setRelativeStateId] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

  const [propertyData, setPropertyData] = useState(state || null);
  var [uploadedFiles, setUploadedFiles] = useState([]);
  let [uploadedFilesforbrochure, setUploadedFilesforbrochure] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [uploadfile, setuploadfile] = useState([]);
  const [uploadfileforBrochure, setuploadfileforBrochure] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  let [SelecetdRmoveDocument, setSelecetdRmoveDocument] = useState("");
  let [imagename, setimagename] = useState("");
  const [propertyTypeData, setPropertyTypeData] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [roomList, setRoomList] = useState(null);
  const [featureCheckboxlist, setFeatureCheckboxlist] = useState([]);
  const [amenityCheckboxlist, setAmenityCheckboxlist] = useState([]);
  const [accessibilityCheckboxlist, setAccessibilityCheckboxlist] = useState([]);
  const [sdaCheckboxlist, setSdaCheckboxlist] = useState([]);
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [propertyFeatureCheckboxlist, setPropertyFeatureCheckboxlist] = useState(null);
  const [documentdata, setdocumentdata] = useState([]);
  const [documentindexlength, setdocumentindexlength] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [file, setFile] = useState([]);
  const [documentname, setdocumentname] = useState([]);
  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState(
    propertyData?.AboutProperty ? propertyData?.AboutProperty : ""
  );
  const [fileData, setFileData] = useState(null);
  const [roomListValue, setRoomListValue] = useState(null);
  const [avilableBedValue, setAvilableBedValue] = useState(null);
  const [avilableBedMinBedValue, setAvilableBedMinBedValue] = useState(null);

  console.log("roomListValuenCheck", fileData);
  console.log("roomListnCheck", accessibilityCheckboxlist);

  useEffect(() => {
    if (roomListValue) {
      let num = roomListValue[0]?.id;

      let strToNum = parseFloat(num);

      var newFirstArray = roomList.filter(function (el) {
        return el.label <= strToNum;
      });

      var newSecondArray = roomList.filter(function (el) {
        return el.label == 1;
      });

      setAvilableBedMinBedValue(newSecondArray);
      setAvilableBedValue(newFirstArray);

      // return el.label <= roomListValue[0]?.id && el.id <= roomListValue[0]?.id;
      // console.log(newFirstArray);
      // const result = roomList.filter((index) => index < 2);
      // let mainDepartmentName = [];
      // for (let i = 0; i < roomList.roomListValue; i++) {
      //   let responseData = roomList[roomListValue].data[i];
      //   mainDepartmentName.push(responseData);
      //   console.log("mainDepartmentName", mainDepartmentName);
      // }
      // console.log("resultmmstrToNum", strToNum);
      // console.log("resultmmmm", newFirstArray);
    }
  }, [roomListValue]);

  const selecetedremovedocument = "";
  const PropertyFeaturedata = {
    Feature: propertyData?.PropertyFeature.split(","),
  };
  const PropertyAminitiesdata = {
    Feature: propertyData?.PropertyAminities.split(","),
  };
  const PropertyAccesibilitydata = {
    Feature: propertyData?.PropertyAccesibility.split(","),
  };
  const PropertySDAdata = {
    Feature: propertyData?.PropertySDA.split(","),
  };

  console.log("PropertyFeaturedata", PropertyAccesibilitydata);

  const [checkedValues, setCheckedValues] = useStateWithCallbackLazy(
    PropertyFeaturedata.Feature ? PropertyFeaturedata.Feature : []
  );
  const [amenitiesCheckedValues, setAmenitiesCheckedValues] = useStateWithCallbackLazy(
    PropertyAminitiesdata.Feature ? PropertyAminitiesdata.Feature : []
  );
  const [accessibilityCheckedValues, setAccessibilityCheckedValues] = useStateWithCallbackLazy(
    PropertyAccesibilitydata.Feature ? PropertyAccesibilitydata.Feature : []
  );
  const [sdaCheckedValues, setSdaCheckedValues] = useStateWithCallbackLazy(
    PropertySDAdata.Feature ? PropertySDAdata.Feature : []
  );
  var selectedimage = "";

  //let dir2 = "https://valuecare.cognisunsandbox.com/Backend/documents/Property/";
  let dir2 = CommonConfig.dir + "Property/";
  //let dir = "https://valuecare.cognisunsandbox.com/Backend/documents/Property/";

  const handleSelect = (checkedName) => {
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);

    return checkedValues;
  };
  console.log("Hello ", checkedValues);

  const AmenitiesHandleSelect = (checkedName) => {
    const AmenitiesNames = amenitiesCheckedValues?.includes(checkedName)
      ? amenitiesCheckedValues?.filter((name) => name !== checkedName)
      : [...(amenitiesCheckedValues ?? []), checkedName];
    console.log("AmenitiesNames", AmenitiesNames);
    setAmenitiesCheckedValues(AmenitiesNames);
    console.log("amenitiesCheckedValues=", amenitiesCheckedValues);
    return amenitiesCheckedValues;
  };

  const AccessibilityHandleSelect = (checkedName) => {
    const AccessibilityNames = accessibilityCheckedValues?.includes(checkedName)
      ? accessibilityCheckedValues?.filter((name) => name !== checkedName)
      : [...(accessibilityCheckedValues ?? []), checkedName];
    console.log("accessibilityNames", AccessibilityNames);
    setAccessibilityCheckedValues(AccessibilityNames);

    return accessibilityCheckedValues;
  };

  const SdaHandleSelect = (checkedName) => {
    const SdaNames = sdaCheckedValues?.includes(checkedName)
      ? sdaCheckedValues?.filter((name) => name !== checkedName)
      : [...(sdaCheckedValues ?? []), checkedName];
    console.log("accessibilityNames", SdaNames);
    setSdaCheckedValues(SdaNames);

    return sdaCheckedValues;
  };

  console.log("propertyData...", propertyData);
  console.log("checkedValues", checkedValues);

  const getPropertyTypeList = () => {
    const formattedData = {
      StringMapType: "PROPERTYTYPE",
      SortType: "",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setPropertyTypeData(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getRoomsList = () => {
    const formattedData = {
      StringMapType: "RoomCount",
      SortType: "",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setRoomList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getPropertyCheckboxlist = () => {
    axios({
      url: CommonConfig.ApiUrl + "Property/getPropertyCheckboxlist",
      method: "Get",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("PropertyCheckboxlist", res.data.data);
          var list1 = [];
          res.data.data[2].map((item) => {
            list1.push(item.Feature);
          });
          setFeatureCheckboxlist(list1);

          var list2 = [];
          res.data.data[1].map((item) => {
            list2.push(item.Amenity);
          });
          setAmenityCheckboxlist(list2);

          var list3 = [];
          res.data.data[0].map((item) => {
            list3.push(item.Accesibility);
          });
          setAccessibilityCheckboxlist(list3);

          var list4 = [];
          res.data.data[3].map((item) => {
            list4.push(item.SDA);
          });
          setSdaCheckboxlist(list4);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // register("input");
    getStateData();
    getRoomsList();
    if (propertyData) {
      getdocumentlist();
    }

    getPropertyCheckboxlist();
    getPropertyTypeList();
  }, []);

  console.log("featureCheckboxlist", featureCheckboxlist);

  console.log("propertyData = ", propertyData);
  const statedata = {
    id: propertyData?.State,
    label: propertyData?.State,
  };
  const propertytypedata = {
    label: propertyData?.PropertyType,
  };
  const TotalBedroomCountdata = {
    id: propertyData?.TotalBedroomCount,
    label: propertyData?.TotalBedroomCount,
  };
  const TotalAvailableBedroomCountdata = {
    id: propertyData?.TotalAvailableBedroomCount,
    label: propertyData?.TotalAvailableBedroomCount,
  };
  const MinBedroomAllocationdata = {
    id: propertyData?.MinBedroomAllocation,
    label: propertyData?.MinBedroomAllocation,
  };
  const BathroomCountdata = {
    id: propertyData?.BathroomCount,
    label: propertyData?.BathroomCount,
  };
  // const BalconyCountdata = {
  //   id: propertyData?.BalconyCount,
  //   label: propertyData?.BalconyCount,
  // };
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      PropertyName: propertyData?.PropertyName ? propertyData?.PropertyName : "",
      TotalSizeSqft: propertyData?.TotalSizeSqft ? propertyData?.TotalSizeSqft : "",
      PropertyType: propertyData?.PropertyType ? propertytypedata : "",
      AddrLine1: propertyData?.AddrLine1 ? propertyData?.AddrLine1 : "",
      AddrLine2: propertyData?.AddrLine2 ? propertyData?.AddrLine2 : "",
      BedroomDescription: propertyData?.BedroomDescription ? propertyData?.BedroomDescription : "",
      BriefDescription: propertyData?.BriefDescription ? propertyData?.BriefDescription : "",
      Suburb: propertyData?.City ? propertyData?.City : "",
      // value: propertyData?.City ? propertyData?.City : "",
      PostalCode: propertyData?.PostalCode ? propertyData?.PostalCode : "",
      State: propertyData?.State ? statedata : "",
      TotalBedroomCount: propertyData?.TotalBedroomCount ? TotalBedroomCountdata : "",
      TotalAvailableBedroomCount: propertyData?.TotalAvailableBedroomCount
        ? TotalAvailableBedroomCountdata
        : "",
      MinBedroomAllocation: propertyData?.MinBedroomAllocation ? MinBedroomAllocationdata : "",
      BathroomCount: propertyData?.BathroomCount ? BathroomCountdata : "",
      //BalconyCount: propertyData?.BalconyCount ? BalconyCountdata : "",
      // Features: defaultNames ? defaultNames : "",
    },
  });

  // const fileUpload = (event) => {
  //   debugger;
  //   const files = event.target.files[0];
  //   setuploadfile(files);
  // };
  console.log("propertyData", propertyData);
  const getdocumentlist = () => {
    setLoader(true);
    debugger;
    if (propertyData !== null) {
      let docReqData = {
        EntityType: "Property",
        EntityId: propertyData?.PropertyID ? propertyData?.PropertyID : "",
        CurrentUser: CommonConfig.getCurrentUserId(),
      };

      axios({
        url: CommonConfig.ApiUrl + "document/getDocumentListByTypeAndId",
        method: "POST",
        headers: {},
        data: docReqData,
      })
        .then((res) => {
          if (res.data?.success) {
            console.log("reeee", res.data.data[0]);
            debugger;
            res.data.data[0].map((item) => {
              if (item.DocumentType === "Brochure") {
                //setUploadedFilesforbrochure(item);
                uploadedFilesforbrochure.push(item);
                console.log("2..", uploadedFilesforbrochure);
              } else {
                documentdata.push(item);
                console.log("2..", documentdata);
                // setdocumentdata(item || []);
              }
            });
            console.log("first..", documentdata);
            console.log("2..", uploadedFilesforbrochure);

            setLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteBroucher = (event, file) => {
    debugger;
    //console.log("uploadfile", uploadfile);
    var selectedfile = file.name;
    event.preventDefault();

    let Data = { path: selectedfile };
    setLoader(true);
    axios({
      url: CommonConfig.ApiUrl + "Property/deleteAddimage",
      method: "POST",
      headers: {},

      data: Data,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("test222", res.data);
          if (res.data.success === true) {
            console.log("filenameeeee", uploadfileforBrochure);
            uploadedFilesforbrochure.pop();
            console.log("uploadfile...", uploadedFilesforbrochure);

            //  uploadfile.map((file) => {
            // var newarr = uploadfile.filter((item) => item !== selectedfile);
            // var updatedarr = uploadedFiles.filter((item) => item.name !== filename);

            // //});
            // setUploadedFiles(updatedarr);
            // setuploadfile(newarr);
            // console.log("uploadfile22222", uploadedFiles);
            setLoader(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const deleteImage = (file) => {
  //   setOpenDialog(true);
  //   if (selectedMediumType == "Yes") {
  //     deleteAddimage(file);
  //   }
  // };

  const deleteAddimage = (event, file) => {
    setOpenDialog(true);
    debugger;
    //console.log("uploadfile", uploadfile);
    var selectedfile = file;
    event.preventDefault();

    var filename = file.replace(
      // "https://valuecare.cognisunsandbox.com/Backend/documents/Property/",
      dir2,
      ""
    );

    let Data = { path: filename };
    setLoader(true);

    axios({
      url: CommonConfig.ApiUrl + "Property/deleteAddimage",
      method: "POST",
      headers: {},

      data: Data,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("test222", res.data);
          if (res.data.success === true) {
            console.log("filenameeeee", selectedfile);
            console.log("uploadfile", uploadfile);

            //  uploadfile.map((file) => {
            var newarr = uploadfile.filter((item) => item !== selectedfile);
            var updatedarr = uploadedFiles.filter((item) => item.name !== filename);

            //});
            setUploadedFiles(updatedarr);
            setuploadfile(newarr);
            console.log("uploadfile22222", uploadedFiles);
            setLoader(false);
            setOpenDialog(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const newuploadedFilesforbrochure = (inputfile) => {
    // uploadfileforBrochure;
    console.log("iiinnn", inputfile);
    var x = [];
    const Data = new FormData();
    for (let i = 0; i < inputfile.length; i++) {
      //setFile(URL.createObjectURL(inputfile));
      console.log("uploadedFiles[i].name.....", inputfile[i].name);
      Data.append("File", inputfile[i], inputfile[i].name);
      x.push(inputfile[i].name);
      uploadfileforBrochure.push(dir2 + inputfile[i].name);
    }
    console.log("ib.....", x);
    axios({
      url: CommonConfig.ApiUrl + "Property/uploadAddimage",
      method: "POST",
      headers: {},

      data: Data,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("test222", res.data);
          if (res.data.success === true) {
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const uploadAddimage = (FILE) => {
    //uploadedFiles
    // setimagename(e.target.files[0].name);
    console.log("uploadedFiles", uploadedFiles);
    console.log("fileeeeeee", FILE);
    debugger;
    var x = [];
    const Data = new FormData();
    for (let i = 0; i < FILE.length; i++) {
      //console.log("uploadedFiles[i].name.....", FILE[i].name);
      Data.append("File", FILE[i], FILE[i].name);
      x.push(FILE[i].name);
      uploadfile.push(dir2 + FILE[i].name);
    }
    console.log("i.....", x);
    // console.log("i.....Data", Data);
    // Data.append("File", e.target.files[0]);
    axios({
      url: CommonConfig.ApiUrl + "Property/uploadAddimage",
      method: "POST",
      headers: {},

      data: Data,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("test222", res);
          if (res.data.success === true) {
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const uploadDocument = (e) => {
    console.log("eeee", e);
    debugger;
    const Data = new FormData();
    Data.append("File", e.target.files[0]);

    imagename = dir2 + e.target.files[0].name;
    var input = {
      filename: imagename,
      PropertyId: propertyData.PropertyID,
    };
    console.log("imggg", propertyData.PropertyID);
    Data.append("PropertyId", propertyData.PropertyID);
    //
    console.log("iiii", Data);
    axios({
      url: CommonConfig.ApiUrl + "Property/uploaddoc",
      method: "POST",
      headers: {},

      data: Data,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("test222", res.data);
          if (res.data.success === true) {
            // cogoToast.success("Deleted Successfully.");
            //setOpenDialog(false);
            getdocumentlist();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //api.post(APIConstant.path.UploadDocument, Data).then((res) => {
    //const url = "https://krishnacrm.evalueadd.com:8080/" + res.filename;
    // setInputField((inputField) => ({
    //   ...inputField,
    //   DocumentURL: url,
    //   filename: res.filename,
    // }));
    //});
  };
  const removeimage = (event, file) => {
    event.preventDefault();
    let selectedimage = file.DocumentId;
    setSelecetdRmoveDocument(file.DocumentId);
    console.log("rrrrr", selectedimage);
    console.log("set data", SelecetdRmoveDocument);
    setOpenDialog(true);
  };
  const CancelDelete = () => {
    setSelectedMediumType("");
    selectedimage = "";
    setOpenDialog(false);
  };
  const deleteUser = () => {
    setSelectedMediumType(false);
    console.log("........,,,ddddd", SelecetdRmoveDocument);
    debugger;
    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      let inputData = {
        DocumentId: SelecetdRmoveDocument,
      };
      console.log("inputData...", inputData);
      axios({
        url: CommonConfig.ApiUrl + "Property/RemoveImage",
        method: "POST",
        headers: {},

        data: inputData,
      })
        .then((res) => {
          if (res.data?.success) {
            console.log("test222", res.data);

            if (res.data.success === true) {
              cogoToast.success("Deleted Successfully.");
              setOpenDialog(false);
              //  getData();
              getdocumentlist();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setFileData(URL.createObjectURL(e.target.files[0]));
    handleUploadFiles(chosenFiles);
  };
  const handleFileEventforbrochure = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFilesforbrochure(chosenFiles);
  };
  const handleUploadFilesforbrochure = (files) => {
    debugger;
    const uploaded = [...uploadedFilesforbrochure];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          cogoToast.error(`You can only add a maximum of ${MAX_COUNT} files.`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFilesforbrochure(uploaded);
    newuploadedFilesforbrochure(uploaded);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          cogoToast.error(`You can only add a maximum of ${MAX_COUNT} files.`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
    console.log("upload", uploaded);
    uploadAddimage(uploaded);
  };

  //onsole.log("valuemnmn", value);
  // console.log("valuemnmn33 ", value.editor.getData());

  /// console.log("checkedValuesQQQ", checkedValues);

  const onSubmit = (data) => {
    console.log("value.length", value.length);
    if (value.length < 1) {
      document.getElementById("ckError").style.display = "block";
      return;
    } else {
      document.getElementById("ckError").style.display = "none";
    }

    if (checkedValues.length === 0) {
      document.getElementById("checkedValuesError").style.display = "block";
      return;
    } else {
      document.getElementById("checkedValuesError").style.display = "none";
    }

    if (amenitiesCheckedValues.length === 0) {
      document.getElementById("amenitiesError").style.display = "block";
      return;
    } else {
      document.getElementById("amenitiesError").style.display = "none";
    }

    if (accessibilityCheckedValues.length === 0) {
      document.getElementById("accessibilityError").style.display = "block";
      return;
    } else {
      document.getElementById("accessibilityError").style.display = "none";
    }

    if (sdaCheckedValues.length === 0) {
      document.getElementById("sdaError").style.display = "block";
      return;
    } else {
      document.getElementById("sdaError").style.display = "none";
    }

    console.log("checkedValuesbhanusubmit", checkedValues);
    var documentlist = [];
    {
      uploadedFiles.map((file) => documentlist.push(file.name));
    }
    var documentlistforbrochure = [];
    {
      uploadedFilesforbrochure.map((file) => documentlistforbrochure.push(file.name));
    }
    console.log("doclist", documentlist);

    if (!propertyData) {
      if (documentlist.length == 0) {
        document.getElementById("DocumentError").style.display = "block";
        return;
      } else {
        document.getElementById("DocumentError").style.display = "none";
      }
      if (uploadedFilesforbrochure.length == 0) {
        document.getElementById("DocumentErrorforbrouchure").style.display = "block";
        return;
      } else {
        document.getElementById("DocumentErrorforbrouchure").style.display = "none";
      }
    }

    let formattedData = "";
    formattedData = {
      ...data,
      PropertyType: data.PropertyType ? data.PropertyType.label : "",
      PropertyFeature: checkedValues.toString(),
      PropertyAminities: amenitiesCheckedValues.toString(),
      PropertyAccesibility: accessibilityCheckedValues.toString(),
      PropertySDA: sdaCheckedValues.toString(),
      AboutProperty: value,
      TotalBedroomCount: data.TotalBedroomCount ? data.TotalBedroomCount.label : "",
      TotalAvailableBedroomCount: data.TotalAvailableBedroomCount
        ? data.TotalAvailableBedroomCount.label
        : "",
      MinBedroomAllocation: data.MinBedroomAllocation ? data.MinBedroomAllocation.label : "",
      BathroomCount: data.BathroomCount ? data.BathroomCount.label : "",
      //BalconyCount: data.BalconyCount ? data.BalconyCount.label : "",
      State: data.State ? data.State.label : "",
      brochure: uploadedFilesforbrochure[0].name,

      // document: "documents/" + uploadfile.name,
      // PropertyImages: documentlist,
      //  documentlist: documentlist,
    };
    console.log("formattedData", formattedData);
    debugger;
    if (propertyData) {
      formattedData = {
        ...formattedData,
        PropertyId: propertyData?.PropertyID,
        CurrentUser: CommonConfig.getCurrentUserId(),
      };
    }

    //     setLoader(!loader);
    let formattedDataT;
    formattedDataT = {
      inputData: formattedData,
      AboutProperty: value,
    };
    console.log("formattedDataT", formattedDataT);
    if (propertyData) {
      formattedDataT = {
        inputData: formattedData,
        AboutProperty: value,
      };
    }
    console.log("formattedDataTTT.....", formattedDataT);
    var formData = new FormData();
    formData.append("data", JSON.stringify(formattedDataT));

    for (let i = 0; i < uploadedFiles.length; i++) {
      console.log("uploadedFiles[i].name.....", uploadedFiles[i].name);
      formData.append("File", uploadedFiles[i], uploadedFiles[i].name);
    }
    // for (let i = 0; i < uploadedFilesforbrochure.length; i++) {
    //   // console.log("uploadedFiles[i].name.....", uploadedFiles[i].name);
    //   formData.append(
    //     "Fileforbrochure",
    //     uploadedFilesforbrochure[i],
    //     uploadedFilesforbrochure[i].name
    //   );
    // }
    console.log("formData", formData);

    axios({
      url: CommonConfig.ApiUrl + "Property/AddupdateProperty",
      method: "POST",
      headers: {},
      data: formData,
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data.success === true) {
          // cogoToast.success("Property Added Successfully.");
          // navigate("/layouts/Property/PropertyList");
          if (propertyData) {
            cogoToast.success("Property Updated Successfully.");
            navigate("/layouts/Property/PropertyList");
          } else {
            cogoToast.success("Property Added Successfully.");
            navigate("/layouts/Property/PropertyList");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }
        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log("AddUpdateErro", error);
      });
  };
  console.log("featureCheckboxlist", featureCheckboxlist);

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                add
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {propertyData ? "Edit Property" : "Add Property"}
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="PropertyName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Property Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="TotalSizeSqft"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              type="number"
                              label="Property Total Size in Square meter.*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="PropertyType"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="PropertyType"
                              name="PropertyType"
                              value={value}
                              options={propertyTypeData ? propertyTypeData : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Property Type*"
                                  error={errors.PropertyType}
                                  helperText={errors.PropertyType ? "Property Type Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div>
                        <CKEditor
                          data={value}
                          onChange={(event, editor) => {
                            setValue(event.editor.getData());
                          }}
                        />
                        <span class="errorTC" id="ckError">
                          About Property Required.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input">
                        <Controller
                          name="BriefDescription"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Brief Description*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="reg_input">
                    <h5>Property Location</h5>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="AddrLine1"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Street Address*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="AddrLine2"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Street Name"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Suburb"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Suburb*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="State"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="State"
                              name="State"
                              value={value}
                              options={stateList ? stateList : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="State*"
                                  error={errors.State}
                                  helperText={errors.State ? "State Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="3">
                      <div className="reg_input">
                        <Controller
                          name="PostalCode"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Postal Code*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              inputProps={{
                                maxLength: 4,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="reg_input">
                    <h5>Bedrooms</h5>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="TotalBedroomCount"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="TotalBedroomCount"
                              name="TotalBedroomCount"
                              value={value}
                              options={roomList ? roomList : ""}
                              // onChange={(e, v) => onChange(v)}
                              onChange={(e, v) => onChange(v, setRoomListValue([v]))}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Total Bedrooms*"
                                  error={errors.TotalBedroomCount}
                                  helperText={
                                    errors.TotalBedroomCount ? "Total Bed Rooms Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="TotalAvailableBedroomCount"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="TotalAvailableBedroomCount"
                              name="TotalAvailableBedroomCount"
                              value={value}
                              options={avilableBedValue ? avilableBedValue : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Available Bedrooms*"
                                  error={errors.TotalAvailableBedroomCount}
                                  helperText={
                                    errors.TotalAvailableBedroomCount
                                      ? "Available Bedrooms Required."
                                      : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="MinBedroomAllocation"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="MinBedroomAllocation"
                              name="MinBedroomAllocation"
                              value={value}
                              options={avilableBedMinBedValue ? avilableBedMinBedValue : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Minimum Bedroom Allocation*"
                                  error={errors.MinBedroomAllocation}
                                  helperText={
                                    errors.MinBedroomAllocation
                                      ? "Minimum Bedroom Allocation Required."
                                      : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="2">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="BathroomCount"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="BathroomCount"
                              name="BathroomCount"
                              value={value}
                              options={avilableBedValue ? avilableBedValue : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Bathrooms*"
                                  error={errors.BathroomCount}
                                  helperText={errors.BathroomCount ? "Bathrooms Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    {/* <Grid item md="2">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="BalconyCount"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="BalconyCount"
                              name="BalconyCount"
                              value={value}
                              options={roomList ? roomList : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Balcony*"
                                  error={errors.BalconyCount}
                                  helperText={errors.BalconyCount ? "Balcony Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid> */}
                    <Grid item md="3">
                      <div className="reg_input">
                        <Controller
                          name="BedroomDescription"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Bedroom features*"
                              variant="outlined"
                              value={value}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="12">
                      <div className="reg_input">
                        <div className="reg_input">
                          <h5>Upload Property Brochure *</h5>
                        </div>
                        <Controller
                          name="Property Brochure"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            console.log("first", onChange),
                            (
                              <div>
                                <input
                                  id="fileUpload"
                                  type="file"
                                  accept="application/pdf"
                                  onChange={handleFileEventforbrochure}
                                  disabled={fileLimit}
                                />
                                {/* //  <div className="uploaded-files-list"> */}
                                {/* //{uploadfileforBrochure.map((file) => ( */}
                                {uploadedFilesforbrochure.map((file) => (
                                  <div>
                                    <a
                                      href={propertyData ? dir2 + file.Filename : dir2 + file.name}
                                      target="_new"
                                    >
                                      {propertyData ? file.Filename : file.name}
                                    </a>
                                    <a
                                      // className="delete-img"
                                      href
                                      onClick={(event) => deleteBroucher(event, file)}
                                    >
                                      <DeleteIcon></DeleteIcon>
                                    </a>
                                  </div>
                                ))}
                              </div>
                            )
                            // </div>
                          )}
                        />
                        <span class="servicesError" id="DocumentErrorforbrouchure">
                          Please Select Document.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  {/* //<Grid container spacing={3}> */}

                  {/* </Grid> */}

                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <label>Features*</label>
                        <div className="inline-checkbox">
                          {featureCheckboxlist.map((Feature) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="PropertyFeature"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        checked={checkedValues.includes(Feature)}
                                        onChange={() => onCheckChange(handleSelect(Feature))}
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={Feature}
                              label={Feature}
                            />
                          ))}
                        </div>
                        <span class="errorTC" id="checkedValuesError">
                          Please select any one Feature.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <label>Amenities*</label>
                        <div className="inline-checkbox">
                          {amenityCheckboxlist.map((Amenities) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="PropertyAminities"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        checked={amenitiesCheckedValues.includes(Amenities)}
                                        onChange={() =>
                                          onCheckChange(AmenitiesHandleSelect(Amenities))
                                        }
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={Amenities}
                              label={Amenities}
                            />
                          ))}
                        </div>
                        <span class="errorTC" id="amenitiesError">
                          Please select any one Amenities.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input check_value">
                        <label>Accessibility*</label>
                        <div className="inline-checkbox">
                          {accessibilityCheckboxlist.map((Accessibility) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="PropertyAccesibility"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        checked={accessibilityCheckedValues.includes(Accessibility)}
                                        onChange={() =>
                                          onCheckChange(AccessibilityHandleSelect(Accessibility))
                                        }
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={Accessibility}
                              label={Accessibility}
                            />
                          ))}
                        </div>
                        <span class="errorTC" id="accessibilityError">
                          Please select any one Accessibility.
                        </span>
                      </div>
                    </Grid>

                    <Grid item md="7">
                      <div className="reg_input check_value">
                        <label>SDA*</label>
                        <div className="inline-checkbox">
                          {sdaCheckboxlist.map((SDA) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="PropertySDA"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        checked={sdaCheckedValues.includes(SDA)}
                                        onChange={() => onCheckChange(SdaHandleSelect(SDA))}
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={SDA}
                              label={SDA}
                            />
                          ))}
                        </div>
                        <span class="errorTC" id="sdaError">
                          Please select any one SDA.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <div className="reg_input">
                    <h5>{propertyData ? "Property Images" : "Property Images*"}</h5>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input">
                        <Controller
                          name="PropertyImages"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            console.log("first", onChange),
                            (
                              <div>
                                {propertyData ? (
                                  <div className="added-property-images">
                                    {documentdata
                                      ? documentdata.map((file) => (
                                          // <div>
                                          //   <a href={dir + file.Filename} target="_new">
                                          //     {file.Filename}
                                          //   </a>
                                          // </div>
                                          //<img src={dir + file.Filename} width="200" height="145"></img>
                                          <span>
                                            <img src={dir2 + file.Filename}></img>
                                            <a
                                              className="delete-img"
                                              href
                                              // href="javascript:void(0)"
                                              onClick={(event) => removeimage(event, file)}
                                            >
                                              <DeleteIcon></DeleteIcon>
                                              {/* <DeleteIcon onClick={removeimage(file)}></DeleteIcon> */}
                                            </a>
                                          </span>
                                          // { documentindexlength = file.index ?
                                          //   <a href={} target="_new">
                                          //      add image </a>
                                          //  : <></> }
                                        ))
                                      : null}
                                    <span>
                                      {" "}
                                      <input
                                        type="file"
                                        accept=" image/*"
                                        className="btn btn-default"
                                        style={{ marginRight: 0, width: "100%" }}
                                        name="file"
                                        id="file"
                                        multiple
                                        //  value={}
                                        onChange={(e) => {
                                          uploadDocument(e);
                                        }}
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <input
                                      id="fileUpload"
                                      type="file"
                                      multiple
                                      accept=" image/*"
                                      onChange={handleFileEvent}
                                      disabled={fileLimit}
                                    />

                                    {uploadfile
                                      ? uploadfile.map((file) => (
                                          <div className="added-property-images">
                                            <span>
                                              <img src={file}></img>
                                              <img src={fileData} />
                                              <a
                                                className="delete-img"
                                                href
                                                onClick={(event) => deleteAddimage(event, file)}
                                                // onClick={() => deleteImage()}
                                              >
                                                <DeleteIcon></DeleteIcon>
                                              </a>
                                            </span>
                                          </div>
                                        ))
                                      : null}
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        />
                        <span class="servicesError" id="DocumentError">
                          Please select image.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

              <div className="reg_submit">
                <MDButton
                  onClick={() => navigate("/layouts/Property/PropertyList")}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </MDButton>
                <MDButton type="submit" variant="contained" color="primary">
                  {propertyData ? "Update" : "Save"}
                </MDButton>
              </div>

              <div className="copyright">
                <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
              </div>
            </form>
          </MDBox>
        </Card>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
          <DialogTitle>Delete Image</DialogTitle>
          <DialogContent>
            <Grid item md="12">
              <div className="reg_input check_value mb-0">
                <InputLabel className="reg_lable mt-5 mb-2">
                  Are You Sure to delete an image?
                </InputLabel>
                <div>
                  <span class="servicesError" id="ServicesError">
                    Please select any one option.
                  </span>
                </div>
                <RadioGroup
                  aria-label="remove"
                  name="Remove"
                  row="true"
                  onChange={(e) => setSelectedMediumType(e.target.value)}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                    label="Yes"
                    name="Remove"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                    label="No"
                    name="Remove"
                  />
                </RadioGroup>
                {/* <span class="errorTC" id="ServicesError">
                Please select any one service
              </span> */}
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
              Back
            </MDButton>
            <MDButton
              variant="contained"
              color="primary"
              onClick={() => {
                deleteUser();
              }}
            >
              Ok
            </MDButton>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    </>
  );
};

AddProperty.defaultProps = {};

export default AddProperty;
